import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, Col, ListGroup, Row } from "react-bootstrap";
import firebase from "firebase/compat";

const ViewFS = () => {
  const [data, setData] = useState({});

  const { id } = useParams();

  useEffect(() => {
    firebase
      .firestore()
      .collection("samples")
      .doc("value", (snapshot) => {
        if (snapshot.val() !== null) {
          setData({ ...snapshot.val() });
          console.log(snapshot.val());
        } else {
          setData({});
        }
      });

    return () => {
      setData({});
    };
  }, [id]);

  console.log("brand", data.brand);

  return (
    <Col>
      <Card>
        <Card.Header>
          <Card.Title tag="h5" className="mb-0">
            Sample Detail
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md="6" lg="4">
              <Card.Text>
                <ListGroup.Item>
                  <strong>ID: </strong>
                  <span>{id}</span>
                  <br />
                  <strong>Index: </strong>
                  <span>{data.id}</span>
                  <br />
                  <strong>Country: </strong>
                  <span>{data.country}</span>
                  <br />
                  <strong>Brand: </strong>
                  <span>{data.brand}</span>
                  <br />
                  <strong>Model: </strong>
                  <span>{data.model}</span>
                  <br />
                  <strong>OS: </strong>
                  <span>{data.os}</span>
                  <br />
                  <strong>Location: </strong>
                  <span>{data.location}</span>
                  <br />
                  <strong>Sim Types: </strong>
                  <span>{data.devTypes}</span>
                  <br />
                  <strong>Tech Network: </strong>
                  <span>{data.tNetworks}</span>
                  <br />
                  <strong>Sim Types: </strong>
                  <span>{data.simTypes}</span>
                  <br />
                  <strong>Serial Number: </strong>
                  <span>{data.serialNumber}</span>
                  <br />
                  <strong>Imei 1 : </strong>
                  <span>{data.imei1}</span>
                  <br />
                  <strong>Imei 2 : </strong>
                  <span>{data.imei2}</span>
                  <br />
                  <strong>Customer Sample Manager: </strong>
                  <span>{data.custsamplmanag}</span>
                  <br />
                  <strong>Last Manger: </strong>
                  <span>{data.lastmanag}</span>
                  <br />
                  <strong>Receive Date: </strong>
                  <span>{data.receiveDate}</span>
                  <br />
                  <strong>simCard: </strong>
                  <span>{data.simCard}</span>
                  <br />
                  <strong>HW Type: </strong>
                  <span>{data.hwtype}</span>
                  <br />
                  <strong>Slots: </strong>
                  <span>{data.slots}</span>
                  <br />
                  <strong>Phone Number: </strong>
                  <span>{data.phoneNumber}</span>
                  <br />
                  <strong>Status: </strong>
                  <span>{data.status}</span>
                  <br />
                  <strong>Transfer Date: </strong>
                  <span>{data.inTransitDate}</span>
                  <br />
                  <strong>Comments: </strong>
                  <span>{data.comments}</span>
                </ListGroup.Item>
                <br />
              </Card.Text>
              <Button className="btn btn-edit" href="/private">
                Go Back
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default ViewFS;
