import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../AddEdit.css";
import { serverTimestamp } from "firebase/firestore";
import { db, auth } from "../../../../contexts/FirebaseAuthContext";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

const TransferFS = () => {
  const { register, handleSubmit, reset, control } = useForm();
  const [supervisors, setSupervisors] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    db.collection("users")
      .get()
      .then((querySnapshot) => {
        let supervisorArray = [];
        querySnapshot.forEach((doc) => {
          var user = doc.data();
          supervisorArray.push({ label: user.email, value: user.email });
        });
        setSupervisors(supervisorArray);
      });
  }, []);

  useEffect(() => {
    db.collection("samples")
      .doc(id)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          reset(snapshot.data());
        } else {
          reset({});
        }
      });

    return () => {
      reset({});
    };
  }, [id, reset]);

  const onFormSubmit = (data) => {
    setIsSubmitting(true);
    const uid = auth.currentUser.uid;
    const email = auth.currentUser.email;

    db.collection("users")
      .where("email", "==", data.inTransitTo.value)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          alert("No matching user found!");
          return;
        }

        const doc = querySnapshot.docs[0];
        const user = doc.data();

        if (user.supervisor === undefined || user.country === undefined ||
          user.city === undefined || user.userLat === undefined ||
          user.userLng === undefined) {
          alert("Error: Required user data is undefined for user " + data.inTransitTo.value);
          return;
        }

        const updateData = {
          ...data,
          inTransitDate: serverTimestamp(),
          inTransitToSuper: user.supervisor,
          inTransitTo: data.inTransitTo.value,
          status: "In Transit",
        };

        const sampleRef = db.collection("samples").doc(id);
        const historyRef = db.collection("history").doc();

        const transactionId = Date.now().toString();

         return db.runTransaction((transaction) => {
          return transaction.get(sampleRef).then((sampleDoc) => {
            if (!sampleDoc.exists) {
              throw "Sample does not exist!";
            }

            if (sampleDoc.data().lastTransactionId === transactionId) {
              throw "This transaction was already processed!";
            }

            // Add the unique transaction identifier to the update data
            updateData.lastTransactionId = transactionId;

            // Perform the update on the sample document
            transaction.update(sampleRef, updateData);

            // Add the new history document
            transaction.set(historyRef, {
              email,
              uid,
              actiondate: serverTimestamp(),
              action: "Transfer",
              ...updateData,
            });
          });
        });

      })
      .then(() => {
        alert("Sample In Transit Successfully");
        setTimeout(() => navigate("/dashboard/homefstransfer"), 500);
      })
      .catch((error) => {
        console.error("Transaction failed: ", error);
        alert("Sample Updated Error: " + error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div style={{ marginTop: "100px" }}>
      <form
        style={{
          margin: "auto",
          padding: "15px",
          maxWidth: "400px",
          alignContent: "center",
        }}
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <label>Transfer Sample to:</label>
        <Controller
          control={control}
          name="inTransitTo"
          render={({ field }) => (
            <Select
              {...field}
              options={supervisors}
            />
          )}
        />
        <input type="submit" value="Transfer" disabled={isSubmitting} />
      </form>
    </div>
  );
};

export default TransferFS;
