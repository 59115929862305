import React, { useEffect, useState } from "react";
import { useForm, Controller, useWatch, useController } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Form, Col, Button } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import { db, auth } from "../../../../contexts/FirebaseAuthContext";
import { serverTimestamp } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputMask from 'react-input-mask';
import WrappedPlaceAutoComplete from "../../../../components/Placeauto";


export async function duplicates(formValues) {           
  const { imei1, imei2, serialNumber, slots } = formValues;
  const handleUI = (message) => alert(message);
  try {
    if (imei1 && (await db.collection("samples").where("imeis", "array-contains", imei1).get()).empty == false) {
      handleUI("IMEI1 already exists");
    }

    if (slots !== "single" && imei2 && (await db.collection("samples").where("imeis", "array-contains", imei2).get()).empty == false) {
      handleUI("IMEI2 already exists");
    }

    if (serialNumber && (await db.collection("samples").where("serialNumber", "==", serialNumber).get()).empty == false) {
      handleUI("Serial Number already exists");
      throw new Error("Duplicates found"); // Reject the promise if there are duplicates
    }
  } catch (error) {
    throw error; // Throw the error to be caught in the onSubmit function
  }
}



export default function AddSampleForm() {
  const navigate = useNavigate();
  const { id } = useParams();
// Schema is moved out here and imei2 is not required by default
  const schema = yup.object().shape({
    brand:  yup.string().required(),
    customers: yup.string().required(),
    slots: yup.string().required(),
    serialNumber: yup.string().required(),
    imei1: yup.string().required().length(15, 'IMEI 1 must be exactly 15 digits'),
    imei2: yup.string().notRequired().when('slots', {
      is: 'dual',
      then: yup.string().length(15, 'IMEI 2 must be exactly 15 digits'),
    }),
    regfromlocation: yup.string().required(),
    // lat: yup.number().required(),
    // lng: yup.number().required(),
    model: yup.string().required(),
    receivedfrom: yup.string().required(),
    comments: yup.string().required(),
    phoneNumber: yup.string().required(),
    hwVersion: yup.string().required(),
    hwType: yup.string().required(),
  }).required();

  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues } = useForm({
    defaultValues: {
      brand: "",
      slots: "",
      customers: "",
      serialNumber: "",
      imei1: "",
      imei2: "",
      receiveddate: new Date(),
      regfromlocation: "",
      lat: null,
      lng: null,
      model: "",
      receivedfrom: "",
      comments: "",
      phoneNumber: "",
      hwVersion: "",
      hwType: "",
    },
    resolver: yupResolver(schema),
  });


  const slots = watch("slots");

  // Create state variables for the Select components
  const [brandValue, setBrandValue] = useState(null);
  const [slotValue, setSlotValue] = useState(null);
  const [customerValue, setCustomerValue] = useState(null);
  const [modelValue, setModelValue] = useState(null);
  const [hwVersionValue, setHwVersionValue] = useState(null);
  const [devTypesValue, setDevTypesValue] = useState(null);
  const [osValue, setOsValue] = useState(null);
  const [simCardValue, setSimCardValue ] = useState(null);
  const [networksValue, setNetworksValue] = useState(null);
  const [hwTypeValue, setHwTypeValue] = useState(null);
  const [data, setData] = useState(null);

  const [userData, setUserData] = useState(null);
  const [firebaseAuthReady, setFirebaseAuthReady] = useState(false);
  const [brandOptions, setBrandOptions] = useState([]);
  const [slotOptions, setSlotOptions] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const watchedSlots = useWatch({ control, name: "slots" });
  const [modelOptions, setModelOptions] = useState([]);
  const [hwVersionOptions, setHwVersionOptions] = useState([]);
  const [devTypesOptions, setDevTypesOptions] = useState([]);
  const [osOptions, setOsOptions] = useState([]);
  const [simCardOptions, setSimCardOptions] = useState([]);
  const [networksOptions, setNetworksOptions] = useState([]);
  const [hwTypeOptions, setHwTypeOptions] = useState([]);
  const [regLocationValue, setReglocationValue] = useState("");
  const [prevSerialNumber, setPrevSerialNumber] = useState("");


  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const docRef = db.collection("users").doc(user.uid);
        docRef.get().then((doc) => {
          if (doc.exists) {
            const userData = doc.data();
            setUserData({
              email: user.email,
              displayName: user.displayName,
              uid: user.uid,
              supervisor: userData.supervisor,
              country: userData.country,
              city: userData.city,
              userLat: userData && userData.userLat ? userData.userLat : null, // Add this line to fetch userLat from userData
              userLng: userData && userData.userLng ? userData.userLng : null, // Add this line to fetch userLng from userData
              userLocation: userData && userData.userLocation ? userData.userLocation : null, // Add this line to fetch userLocation from userData
            });
          } else {
            console.log("No such document!");
          }
        }).catch((error) => {
          console.log("Error getting document:", error);
        });
      }
      setFirebaseAuthReady(true);
    });

  axios.all([
      axios.get("https://us-central1-tt-sms.cloudfunctions.net/brands"),
      axios.get("https://us-central1-tt-sms.cloudfunctions.net/slots"),
      axios.get("https://us-central1-tt-sms.cloudfunctions.net/customers"),
      axios.get("https://us-central1-tt-sms.cloudfunctions.net/modelApp"),
      axios.get("https://us-central1-tt-sms.cloudfunctions.net/hwversion"),
      axios.get("https://us-central1-tt-sms.cloudfunctions.net/devtypes"),
      // axios.get("https://us-central1-smstadtelmax.cloudfunctions.net/devTypes"),
      axios.get("https://us-central1-tt-sms.cloudfunctions.net/osApp"),
      axios.get("https://us-central1-tt-sms.cloudfunctions.net/simCardApp"),
      axios.get("https://us-central1-tt-sms.cloudfunctions.net/networkApp"),
      axios.get("https://us-central1-tt-sms.cloudfunctions.net/hwtypesApp"),
    ])
      .then(axios.spread((brandResponse, slotResponse, customerResponse, modelResponse, hwVersionResponse, devTypesResponse, osResponse, simCardResponse, networksResponse, hwTypeResponse) => {
        setBrandOptions(brandResponse.data);
        setSlotOptions(slotResponse.data);
        setCustomerOptions(customerResponse.data);
        setModelOptions(modelResponse.data);
        setHwVersionOptions(hwVersionResponse.data);
        setDevTypesOptions(devTypesResponse.data);
        setOsOptions(osResponse.data);
        setSimCardOptions(simCardResponse.data);
        setNetworksOptions(networksResponse.data);
        setHwTypeOptions(hwTypeResponse.data);
      }))
      .catch((error) => {
        console.error("Error fetching options:", error);
      });

    if (id) {
      db.collection('samples').doc(id).get().then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          if (data) { // add this check

          // For other fields, you can keep using the direct value:
          setValue('serialNumber', data.serialNumber);
          setValue('imei1', data.imei1);
          setValue('imei2', data.imei2);
            setValue('receivedfrom', data.receivedfrom);
            setValue('phoneNumber', data.phoneNumber);
            setValue('comments', data.comments);
            setValue('regfromlocation', { value: data.regfromlocation, label: data.regfromlocation });


            //   if (data.receiveddate) {
          //     setValue('receiveddate', new Date(data.receiveddate));
          //   }
            setBrandValue({ value: data.brand, label: data.brand });
            setSlotValue({ value: data.slots, label: data.slots });
            setCustomerValue({ value: data.customers, label: data.customers });
            setModelValue({ value: data.model, label: data.model });
            setHwVersionValue({ value: data.hwVersion, label: data.hwVersion });
            setDevTypesValue({ value: data.devTypes, label: data.devTypes });
            setOsValue({ value: data.OS, label: data.OS });
            setSimCardValue({ value: data.simCard, label: data.simCard });
            setNetworksValue({ value: data.networks, label: data.networks });
            setHwTypeValue({ value: data.hwType, label: data.hwType });
            setReglocationValue({ value: data.regfromlocation, label: data.regfromlocation });
            setPrevSerialNumber(data.serialNumber);

            console.log(getValues("regfromlocation")); // Log value of regfromlocation field
          }
        } else {
          console.error('No document found for this ID!');
        }
      }).catch((error) => {
        console.error('Error getting document:', error);
      });
    }

    return () => unregisterAuthObserver();
  }, [id, setValue]);


  useEffect(() => {
    if (slots === 'single') {
      setValue('imei2', '');
    }
  }, [slots, setValue]);

  if (!firebaseAuthReady || brandOptions.length === 0 || slotOptions.length === 0 || customerOptions.length === 0) {
    return <div>Loading...</div>;
  }
  const onSubmit = async (data) => {
    console.log("onSubmit triggered");
    console.log("Form data:", data);

    // Check if imei1 and imei2 are the same
    if (data.imei1 === data.imei2) {
      alert("IMEI1 and IMEI2 cannot be the same. Please change it.");
      return;
    }

    // Check if all fields are filled
    if (!data.brand || !data.slots || !data.customers || !data.serialNumber || !data.imei1 || !data.regfromlocation || !data.lat || !data.lng || (data.slots === 'dual' && !data.imei2)) {
      alert("Please fill all the fields.");
      return;
    }

    try {
      if (!data.id || data.serialNumber !== prevSerialNumber) {
        // Only run duplicates check if the id is missing or the serial number has changed
        console.log("Checking for duplicates");
        await duplicates(data);
        console.log("Duplicates check complete");
        // Remember to update prevSerialNumber once the check is complete
        setPrevSerialNumber(data.serialNumber);
      }


      data.imei2 = data.imei2 || ""; // Set to empty string if undefined

      // Remove unnecessary geolocation fetching
      if (userData && data.slots && data.customers && data.brand) {
        data = {
          ...data,
          regfromlat: getValues("lat"),
          regfromlng: getValues("lng"),
          // regfromuid: getValues("uid"),
          userLat: userData.userLat,
          userLng: userData.userLng,
          userLocation: userData.userLocation,
          samplmanagname: userData.displayName,
          samplmanag: userData.email,
          supervisor: userData.supervisor,
          country: userData.country,
          city: userData.city,
          status: "Registered",
          registeredBy: userData.email,
          registereddate: serverTimestamp(),
        };

        // Include supervisor only if it exists
        if (userData.supervisor !== undefined) {
          data.supervisor = userData.supervisor;
        }
        if (id) {
          // If we're editing, update the document
          db.collection('samples').doc(id).update(data).then(() => {
            alert('Form updated successfully!');
            navigate('/dashboard/homefs');
          }).catch((error) => {
            console.error('Error updating document:', error);
          });
        } else {
        try {
          await db.collection("samples").add({
            imei1: data.imei1,
            imei2: data.imei2,
            imeis: [data.imei1, data.imei2].filter(Boolean),
            serialNumber: data.serialNumber,
            slots: data.slots,
            brand: data.brand,
            hwVersion: data.hwVersion,
            devTypes: data.devTypes,
            OS: data.OS,
            simCard: data.simCard,
            networks: data.networks,
            model: data.model,
            hwType: data.hwType,
            city: data.city,
            country: data.country,
            customers: data.customers,
            userLat: data.userLat,
            userLng: data.userLng,
            userLocation: data.userLocation,
            receivedfrom: data.receivedfrom,
            receiveddate: data.receiveddate,
            registeredBy: data.registeredBy,
            registereddate: data.registereddate,
            regfromlat: data.regfromlat,
            regfromlng: data.regfromlng,
            regfromlocation: data.regfromlocation,
            samplmanag: data.samplmanag,
            samplmanagname: data.samplmanagname,
            status: data.status,
            supervisor: data.supervisor,
            lastmanag: data.receivedfrom,
            comments: data.comments,
            phoneNumber: data.phoneNumber
          });
          const historyData = {
            email: userData.email,
            uid: userData.uid,
            actiondate: serverTimestamp(),
            action: "Register",
            lastmanag: data.receivedfrom,
            ...data,
            customers: data.customers,
            brand: data.brand,
            model: data.model,
            serialNumber: data.serialNumber,
            imei1: data.imei1,
            imei2: data.imei2,
          };

          console.log("historyData: ", historyData); // Log the data to be written to history

          await db.collection("history").doc().set(historyData);

          alert("Form submitted successfully!");
          navigate("/dashboard/homefs");
        } catch (error) {
          console.error("Error submitting form:", error);
        }
      }} else {
        alert("Please fulfill all fields or resolve duplicate entries");
      }
    } catch (error) {
      console.error("Error checking duplicates:", error);
      alert("An error occurred while checking for duplicates. Please try again.");
    }
  };
  const SelectController = ({ name, control, rules, options, placeholder, setValue }) => {
    const {
      field: { onChange, onBlur, value, ref },
      fieldState: { error },
    } = useController({
      name,
      control,
      rules,
      defaultValue: "",
    });

    useEffect(() => {
      setValue(name, value);
    }, [value, setValue, name]);

    return (
      <div>
        <Select
          options={options}
          isSearchable
          placeholder={placeholder}
          classNamePrefix="react-select"
          value={options.find(option => option.value === value)}
          onChange={option => {
            onChange(option.value);
          }}
          onBlur={onBlur}
          inputRef={ref}
        />
        {error && <p>{error.message}</p>}
      </div>
    );
  };


  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="container">
        <div className="row">
          <div className="col">
            <section className="mb-6">
              <label>Brand</label>
              <Controller
                name="brand"
                control={control}
                rules={{ required: false }}
                render={({ field: { onChange, value, ...restField } }) => (
                  <Select
                    {...restField}
                    options={brandOptions}
                    isSearchable
                    placeholder="Select Brand"
                    classNamePrefix="react-select"
                    value={brandValue}
                    // value={brandOptions.find(option => option.value === value)}
                    onChange={option => {
                      onChange(option.value);
                      setBrandValue(option);
                    }}
                  />
                )}
              />
            </section>
          </div>
          <div className="col">
            <section className="mb-6">
              <label>Customers</label>
              <Controller
                name="customers"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value, ...restField } }) => (
                  <Select
                    {...restField}
                    options={customerOptions}
                    isSearchable
                    placeholder="Select Customers"
                    classNamePrefix="react-select"
                    value={customerValue}
                    // value={customerOptions.find(option => option.value === value)}
                    onChange={option => {
                      onChange(option.value);
                      setCustomerValue(option);
                    }}
                  />
                )}
              />
            </section>
          </div>
          <div className="col">
            <section className="mb-6">
              <label>Slots</label>
              <Controller
                name="slots"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value, ...restField } }) => (
                  <Select
                    {...restField}
                    options={slotOptions}
                    isSearchable
                    placeholder="Select Slots"
                    classNamePrefix="react-select"
                    value={slotValue}
                    // value={slotOptions.find(option => option.value === value)}
                    onChange={option => {
                      onChange(option.value);
                      setSlotValue(option);
                    }}
                  />
                )}
              />
            </section>
          </div>
          <div className="col">
            <section className="mb-6">
              <label>Model</label>
              <Controller
                name="model"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value, ...restField } }) => (
                  <Select
                    {...restField}
                    options={modelOptions}
                    isSearchable
                    placeholder="Select Model"
                    classNamePrefix="react-select"
                    value={modelValue}
                    // value={modelOptions.find(option => option.value === value)}
                    onChange={option => {
                      onChange(option.value);
                      setModelValue(option);
                    }}
                  />
                )}
              />
            </section>
          </div>
          <div className="col">
            <section className="mb-6">
              <label>HW Version</label>
              <Controller
                name="hwVersion"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value, ...restField } }) => (
                  <Select
                    {...restField}
                    options={hwVersionOptions}
                    value={hwVersionValue}
                    // value={hwVersionOptions.find(option => option.value === value)}
                    onChange={option => {
                      onChange(option.value);
                      setHwVersionValue(option);
                    }}
                  />
                )}
              />
            </section>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <section className="mb-6">
              {/* devTypes Field */}
              <label>Device Types</label>
              <Controller
                name="devTypes"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value, ...restField } }) => (
                  <Select
                    {...restField}
                    options={devTypesOptions}
                    value={devTypesValue}
                    // value={devTypesOptions.find(option => option.value === value)}
                    onChange={option => {
                      onChange(option.value);
                      setDevTypesValue(option);
                    }}
                  />
                )}
              />
            </section>
          </div>

          <div className="col">
            {/* OS Field */}
            <section className="mb-6">
              <label>Operating System</label>
              <Controller
                name="OS"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value, ...restField } }) => (
                  <Select
                    {...restField}
                    options={osOptions}
                    value={osValue}
                    // value={osOptions.find(option => option.value === value)}
                    onChange={option => {
                      onChange(option.value);
                      setOsValue(option);
                    }}
                  />
                )}
              />
            </section>
          </div>

          <div className="col">
            {/* simCard Field */}
            <section className="mb-6">
              <label>SIM Card</label>
              <Controller
                name="simCard"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value, ...restField } }) => (
                  <Select
                    {...restField}
                    options={simCardOptions}
                    value={simCardValue}
                    // value={simCardOptions.find(option => option.value === value)}
                    onChange={option=> {
                      onChange(option.value);
                      setSimCardValue(option);
                    }}
                  />
                )}
              />
            </section>
          </div>

          <div className="col">
            {/* networks Field */}
            <section className="mb-6">
              <label>Networks</label>
              <Controller
                name="networks"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value, ...restField } }) => (
                  <Select
                    {...restField}
                    options={networksOptions}
                    value={networksValue}
                    // value={networksOptions.find(option => option.value === value)}
                    onChange={option => {
                      onChange(option.value);
                      setNetworksValue(option);
                    }}
                  />
                )}
              />
            </section>
          </div>

          <div className="col">
            {/* HWtype Field */}
            <section className="mb-6">
              <label>HW Type</label>
              <Controller
                name="hwType"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value, ...restField } }) => (
                  <Select
                    {...restField}
                    options={hwTypeOptions}
                    value={hwTypeValue}
                    // value={hwTypeOptions.find(option => option.value === value)}
                    onChange={option => {
                      onChange(option.value);
                      setHwTypeValue(option);
                    }}
                  />
                )}
              />
            </section>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <section className="mb-6">
              <label>SERIAL NUMBER</label>
              <Controller
                name="serialNumber"
                control={control}
                render={({ field }) => (
                  <Form.Control placeholder="Enter SERIAL NUMBER" {...field} />
                )}
              />
              {errors.serialNumber && <p>{errors.serialNumber.message}</p>}
            </section>
          </div>
          <div className="col">
            <section className="mb-6">
              <label>IMEI 1</label>
              <Controller name="imei1"
               control={control}
               render={({ field }) => (
                 <InputMask mask="999999999999999" {...field}>
                   {(inputProps) => <Form.Control {...inputProps} placeholder="Enter IMEI 1" />}
                 </InputMask>
               )}
              />
              {errors.imei1 && <p>{errors.imei1.message}</p>}
            </section>
          </div>

          <div className="col">
            {slots !== 'single' && (
              <section className="mb-6">
                <label>IMEI 2</label>
                <Controller
                  name="imei2"
                  control={control}
                  render={({ field }) => (
                    <InputMask mask="999999999999999" {...field}>
                      {(inputProps) => <Form.Control {...inputProps} placeholder="Enter IMEI 2" />}
                    </InputMask>
                  )}
                />
                {errors.imei2 && <p>{errors.imei2.message}</p>}
              </section>
            )}
          </div>

        </div>
        <div className="row">
          <div className="col">
            <section className="mb-6">
              <label>Received from</label>
              <Controller
                name="receivedfrom"
                control={control}
                render={({ field }) => (
                  <Form.Control placeholder="Enter Received From" {...field} />
                )}
              />
              {errors.receivedfrom && <p>{errors.receivedfrom.message}</p>}
            </section>
          </div>
          <div className="col">
            <section className="mb-6">
              <label>Phone Number</label>
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <InputMask mask="+99 99 99999 9999" {...field}>
                    {(inputProps) => <Form.Control {...inputProps} placeholder="Enter phone number" />}
                  </InputMask>
                )}
              />
              {errors.phoneNumber && <p>{errors.phoneNumber.message}</p>}
            </section>
          </div>

          <div className="col">
        <section className="mb-6">
            <label>Comments</label>
            <Controller
              name="comments"
              control={control}
              render={({ field }) => (
                <Form.Control placeholder="Enter comments" {...field} />
              )}
            />
            {errors.comments && <p>{errors.comments.message}</p>}
          </section>
      </div>
        </div>
      <div className="row">
        <div className="col">
            <section className="mb-6">
              <label>Received Date</label>
              <Controller
                name="receiveddate"
                control={control}
                render={({ field }) => (
                  <ReactDatePicker
                    className="form-control"
                    selected={field.value}
                    onChange={(date) => setValue("receiveddate", date)}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Select date"
                  />
                )}
              />
            </section>
        </div>
        <div className="col">
          <section className="mb-6">
            <label>Delivery from Location</label>
            <Controller
              name="regfromlocation"
              control={control}
              render={({ field }) => {
                // console.log(field.value); // Log value of regfromlocation field
                return (
                  <WrappedPlaceAutoComplete
                    value={regLocationValue.value} // Pass value property of object to WrappedPlaceAutoComplete component
                    name="regfromlocation"
                    isInvalid={!!errors.regfromlocation}
                    onChange={({ address, lat, lng }) => {
                      setValue("regfromlocation", address);
                      setValue("lat", lat);
                      setValue("lng", lng);
                      setReglocationValue(address); // Also update the state
                    }}
                  />
                );
              }}
            />



          </section>
        </div>
        </div>
        <div className="row justify-content-center">
          <div className="col">
            <Button type="submit" variant="primary" className="mt-3">
              {id ? 'Update' : 'Submit'}
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
}