import React, { useRef, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { auth, db } from "../../contexts/FirebaseAuthContext";

const libraries = ["places"];
const GOOGLE_MAPS_LIBRARIES = ["places"];

const initialValues = {
  firstName: "",
  lastName: "",
  country: "",
  city: "",
  email: "",
  passport: "",
  phoneNumber: "",
  password: "",
  passwordConfirm: "",
  userLocation: "",
};

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().max(255).required("First name is required"),
  lastName: Yup.string().max(255).required("Last name is required"),
  userLocation: Yup.string().max(255).required("Location is required"),
  passport: Yup.string().max(255).required("Passport is required"),
  country: Yup.string().required("Please select a country"),
  city: Yup.string().required("Please select a city"),
  phoneNumber: Yup.string()
    .required("This field is Required")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    ),
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .matches(/@tadtelmax.com$/,"Must be a @tadtelmax.com email")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Must be at least 8 characters")
    .max(255)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    )
    .required("Password is required"),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});


export default function Signup() {
  const navigate = useNavigate();
  const autocompleteRef = useRef(null);
  const [userLocation, setUserLocation] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [realLat, setRealLat] = useState("");
  const [realLng, setRealLng] = useState("");
  const [realLocation, setRealLocation] = useState("");

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(displayLocationInfo => {
        setRealLat(displayLocationInfo.coords.latitude);
        setRealLng(displayLocationInfo.coords.longitude);

        // Reverse Geocoding
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${displayLocationInfo.coords.latitude},${displayLocationInfo.coords.longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`)
          .then(response => response.json())
          .then(data => setRealLocation(data.results[0].formatted_address))
          .catch(error => console.log(error));
      });
    } else {
      alert("Please enable location");
    }
  }, []);




  async function displayLocationInfo(position) {
    setLat(position.coords.latitude);
    setLng(position.coords.longitude);
    const address = await getAddressFromLatLng(
      position.coords.latitude,
      position.coords.longitude
    );
    setRealLocation(address);
    console.log(address);
  }


  const getAddressFromLatLng = async (lat, lng) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
      );
      const results = response.data.results;
      if (results && results.length > 0) {
        return results[0].formatted_address;
      }
    } catch (error) {
      console.error("Error geocoding coordinates:", error);
    }
    return "";
  };

  const onPlaceChanged = async (setFieldValue) => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      const address = place.formatted_address;
      const components = place.address_components;
      setUserLocation(address);
      setFieldValue("userLocation", address);

      let country = "";
      let city = "";

      for (let i = 0; i < components.length; i++) {
        if (components[i].types[0] === "country") {
          country = components[i].long_name;
        }
        if (components[i].types[0] === "locality") {
          city = components[i].long_name;
        }
        if (!city && components[i].types[0] === "administrative_area_level_1") {
          city = components[i].long_name;
        }
        if (!city && components[i].types[0] === "administrative_area_level_2") {
          city = components[i].long_name;
        }
      }

      setFieldValue("country", country);
      setFieldValue("city", city);

      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address
          )}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
        );
        const results = response.data.results;
        if (results && results.length > 0) {
          const { lat, lng } = results[0].geometry.location;
          setLat(lat);
          setLng(lng);
        }
      } catch (error) {
        console.error("Error geocoding address:", error);
      }
    }
  };




  const handleSubmit = async (values) => {
    console.log(values);
    try {
      const registeredUser = await auth.createUserWithEmailAndPassword(
        values.email,
        values.password
      );
      console.log("registered", registeredUser.user?.uid);

      alert("Verification Email will be sent.");

      let enteredLocation = values.userLocation || '';
      let enteredLat = '';
      let enteredLng = '';

      // Geocode the entered location to get coordinates
      if (enteredLocation) {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            enteredLocation
          )}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
        );
        const results = response.data.results;
        if (results && results.length > 0) {
          enteredLat = results[0].geometry.location.lat;
          enteredLng = results[0].geometry.location.lng;
        }
      }

      await db.collection("users").doc(registeredUser.user?.uid).set({
        uid: registeredUser.user?.uid,
        displayName: `${values.firstName} ${values.lastName}`,
        email: values.email,
        passPort: values.passport,
        country: values.country,
        city: values.city,
        trInTransitDays: "3",
        trInTransitDaysRec: "1",
        trInReturnDays: "3",
        trInReturnDaysRec: "1",
        trOSRequestDays: "3",
        trOSRequestDaysRec: "1",
        trOwnerSamples: "10",
        trOwnerSamplesRec: "3",
        samplmax: "10",
        samplmaxr: "1",
        maxsamplstartTime: new Date().toLocaleString(),
        supervisor: "edson.shigaki@tadtelmax.com",
        role: "user",
        photoURL: "",
        userLocation: enteredLocation, // Entered by user
        userLat: lat,
        userLng: lng,
        realLocation: realLocation,  // realLocation is unchanged.
        realLat: realLat,  // realLat and realLng are the user's actual coordinates, not the input value.
        realLng: realLng,
        phoneNumber: values.phoneNumber,
      }).then(() => {
        const user = auth.currentUser;
        user.updateProfile({
          displayName: `${values.firstName} ${values.lastName}`,
        });
      }).catch((e) => {
        console.log(e.code, e.message);
      });

      window.location.href = "/auth/sign-in";

      await db.collection("mail").doc(registeredUser.user?.uid).set({
        uid: registeredUser.user?.uid,
        displayName: `${values.firstName} ${values.lastName}`,
        email: values.email,
      });

      // await db.collection("mail").add({
      //   to: values.email,
      //   message: {
      //     subject: "Hello from Firebase your email registered!",
      //     text: "This is the plaintext section of the email body.",
      //     html: "This is the <code>HTML</code> section of the email body.",
      //   },
      // });
      //
      // console.log("Queued email for delivery!");
    } catch (error) {
      console.error("Failed to create an account:", error);
      if (error.message.includes("network-request-failed")) {
        // window.location.href = "/500";
      }
      if (error.message.includes("email-already-in-use")) {
        alert("Email is already registered. Please use another one.");
      }
      if (error.message.includes("weak-password")) {
        alert("Password is too weak. Please enter a stronger password.");
      }
    }
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: GOOGLE_MAPS_LIBRARIES,
    language: 'en',
  });


  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading maps";

  return (
    <div className="container-fluid">
      <Formik
        initialValues={initialValues}
        validationSchema={SignupSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form className="row g-3 needs-validation" noValidate>
            <div className="col-lg-6 position-relative">
              <div className="form-floating">
                <Field style={{height: '40px'}} name="firstName" type="text" className="form-control" id="floatingFirstName" required />
                <label htmlFor="floatingFirstName">First Name</label>
                <ErrorMessage name="firstName" component="div" />
              </div>
            </div>

            <div className="col-lg-6 position-relative">
              <div className="form-floating">
                <Field style={{height: '40px'}} name="lastName" type="text" className="form-control" id="floatingLastName" required />
                <label htmlFor="floatingLastName">Last Name</label>
                <ErrorMessage name="lastName" component="div" />
              </div>
            </div>

            <div className="col-lg-6 position-relative">
              <div className="form-floating">
                <Field style={{height: '40px'}} name="passport" type="text" className="form-control" id="floatingPassport" required />
                <label htmlFor="floatingPassport">Passport</label>
                <ErrorMessage name="passport" component="div" />
              </div>
            </div>

            <div className="col-lg-6 position-relative">
              <div className="form-floating">
                <Field style={{height: '40px'}} name="phoneNumber" type="text" className="form-control" id="floatingPhoneNumber" required />
                <label htmlFor="floatingPhoneNumber">Phone Number</label>
                <ErrorMessage name="phoneNumber" component="div" />
              </div>
            </div>

            <div className="col-lg-12 position-relative">
              <div className="form-floating">
                <Field style={{height: '40px'}} name="email" type="email" className="form-control" id="floatingEmail" required />
                <label htmlFor="floatingEmail">Email</label>
                <ErrorMessage name="email" component="div" />
              </div>
            </div>

            <div className="col-lg-12 position-relative">
              {/*<label htmlFor="floatingLocation" className="form-label">Location</label>*/}
              <Autocomplete
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                onLoad={(ref) => (autocompleteRef.current = ref)}
                onPlaceChanged={() => onPlaceChanged(setFieldValue)}
              >
                <div className="form-floating">
                  <Field style={{height: '40px'}} name="userLocation" type="text" className="form-control" id="floatingLocation" required />
                  <label htmlFor="floatingLocation">Location</label>
                </div>
              </Autocomplete>
            </div>

            <div className="col-lg-6 position-relative">
              <div className="form-floating">
                <Field style={{height: '40px'}} name="country" type="text" className="form-control" id="floatingCountry" required disabled />
                <label htmlFor="floatingCountry">Country</label>
                <ErrorMessage name="country" component="div" />
              </div>
            </div>

            <div className="col-lg-6 position-relative">
              <div className="form-floating">
                <Field style={{height: '40px'}} name="city" type="text" className="form-control" id="floatingCity" required disabled/>
                <label htmlFor="floatingCity">City</label>
                <ErrorMessage name="city" component="div" />
              </div>
            </div>

            <div className="col-lg-6 position-relative">
              <div className="form-floating">
                <Field style={{height: '40px'}} name="password" type="password" className="form-control" id="floatingPassword" required />
                <label htmlFor="floatingPassword">Password</label>
                <ErrorMessage name="password" component="div" />
              </div>
            </div>

            <div className="col-lg-6 position-relative">
              <div className="form-floating">
                <Field style={{height: '40px'}} name="passwordConfirm" type="password" className="form-control" id="floatingPasswordConfirm" required />
                <label htmlFor="floatingPasswordConfirm">Confirm Password</label>
                <ErrorMessage name="passwordConfirm" component="div" />
              </div>
            </div>

            {/* Submit button */}
            <div className="col-12">
              <button className="btn btn-primary" type="submit" disabled={isSubmitting}>Sign Up</button>
            </div>
            {/* Already have an account */}
            <div className="col-12">
              <div className="w-100 text-center mt-2">
                Already have an account? <Link to="/auth/Sign-In">Log In</Link>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );


}
