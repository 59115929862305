import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";

function SignIn() {
  const { signIn } = useAuth();
  const navigate = useNavigate();

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .matches(/tadtelmax\.com$/, "Must be an tadtelmax.com email")
          .required("Email is required"),
        password: Yup.string()
          .min(6, "Must be at least 8 characters")
          .max(255)
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
          )
          .required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const result = await signIn(values.email, values.password);
          if (result === "success") {
            setStatus({ success: true });
            navigate("/dashboard/homefs");
          } else {
            setStatus({ success: false, errorMessage: result });
          }
        } catch (error) {
          setStatus({
            success: false,
            errorMessage: "An unknown error occurred. Please try again.",
          });
        }
        setSubmitting(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <Form onSubmit={handleSubmit}>
          {status && status.errorMessage && (
            <Alert variant="danger">{status.errorMessage}</Alert>
          )}
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              size="lg"
              type="email"
              name="email"
              placeholder="Enter your email"
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.email && (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              size="lg"
              type="password"
              name="password"
              placeholder="Enter your password"
              value={values.password}
              isInvalid={Boolean(touched.password && errors.password)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.password && (
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            )}
            <small>
              <Link to="/auth/reset-password">Forgot password?</Link>
            </small>
          </Form.Group>

          <div>
            <Form.Check
              type="checkbox"
              id="rememberMe"
              label="Remember me next time"
              defaultChecked
            />
          </div>

          <div className="text-center mt-3">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              Sign in
            </Button>
          </div>
          <div className="text-center mt-3">
            <small>
              <Link to="/auth/sign-up">Not a SMS member? Register </Link>
            </small>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SignIn;
