import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Docs from "./SMS/docs";

function ProtectedPage() {
  return (
    <React.Fragment>
      <Helmet title="Sample Management System" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Fully-featured Sample Inventory Control Admin & Dashboard</h1>
        <Docs />
      </Container>
    </React.Fragment>
  );
}

export default ProtectedPage;