import React, { useState, useEffect } from "react";
import { db } from "../../../../config";
import { Card, Row, Col } from "react-bootstrap";
import StatisticsSMSFS from "../../../dashboards/Home/StatisticsSMSFS";
import CountryPieChart from "../../../dashboards/Home/CountryPieChart";
import UserPieChart from "../../../dashboards/Home/UserPieChart";
import SupervisorPieChart from "../../../dashboards/Home/SupervisorPieChart";
import BrandPieChart from "../../../dashboards/Home/BrandPieChart";
import BarChart from "../../../dashboards/Home/BarChart";
import { collection, getDocs } from "firebase/firestore";
import UserStatistics from "../../../dashboards/Home/UserStatistics";

const HomeFS = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    const dataCollectionRef = collection(db, "samples");
    const getData = async () => {
      const data = await getDocs(dataCollectionRef);

      setData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    return () => {
      setData({});
    };
    getData();
  }, [data]);

  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5">Sample Management System</Card.Title>
        <h6 className="card-subtitle text-muted">Inventory Sample Control</h6>
      </Card.Header>
      <StatisticsSMSFS />
      {/* <Row>
        <Col lg="3" className="d-flex">
          <CountryPieChart />
        </Col>
        <Col lg="3" className="d-flex">
          <UserPieChart />
        </Col>
        <Col lg="3" className="d-flex">
          <SupervisorPieChart />
        </Col>
        <Col lg="3" className="d-flex">
          <BrandPieChart />
        </Col>
      </Row> */}
      <Row>
        <Col lg="12" className="d-flex">
          <BarChart />
        </Col>
      </Row>
      <Row>
        <Col lg="12" className="d-flex">
          <UserStatistics />
        </Col>
      </Row>
    </Card>
  );
};

export default HomeFS;
