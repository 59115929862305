import { Layout, Sliders, Settings, Clipboard } from "react-feather";

const pagesSection = [
  {
    href: "/dashboard",
    icon: Sliders,
    title: "Dashboards",
    badge: "",
    children: [
      {
        href: "/dashboard/homefs",
        title: "Analytics",
      },
      {
        href: "/dashboard/vectormap",
        title: "World Map Analytics",
      },
      {
        href: "/private/reportfs",
        title: "Dashboard Report",
      },
    ],
  },
  {
    href: "/samples",
    icon: Layout,
    title: "Samples",
    children: [
      {
        href: "/dashboard/addsample",
        title: "New Sample",
      },
      {
        href: "/dashboard/homefstransfer",
        title: "Transfer",
      },
      {
        href: "/dashboard/homefsreturn",
        title: "Return to Customer",
      },
      {
        href: "/dashboard/homefsos",
        title: "Lost",
      },
      {
        href: "/dashboard/homefsedit",
        title: "Delete Sample Registered",
      },
      {
        href: "/dashboard/history",
        title: "History",
      },
    ],
  },
  {
    href: "/settings",
    icon: Settings,
    title: "Settings",
    children: [
      {
        href: "/pages/employees",
        title: "Employees",
      },
      {
        href: "/pages/employeeprivate",
        title: "Employee Private",
      },
      // {
      //   href: "/pages/supervisorlist",
      //   title: "Supervisor Add",
      // },
      // {
      //   href: "/pages/editemployees/:id",
      //   title: "Edit Employees",
      // },
      // {
      //   href: "/pages/samplecount",
      //   title: "Sample Control by Employee",
      // },
      // {
      //   href: "/pages/clients",
      //   title: "Clients",
      // },
      // {
      //   href: "/pages/AddClients",
      //   title: "Add Clients Page",
      // },
      {
        href: "/pages/SettingsDropList",
        title: "Setting",
      },

      // {
      //   href: "/pages/blank",
      //   title: "Blank",
      // },
    ],
  },
  {
    href: "/report",
    icon: Clipboard,
    title: "Report",
    children: [
      {
        href: "/private/exportreportfs",
        title: "Sample Report",
      },
      // {
      //   href: "/private/reportsms",
      //   title: "SMS Report",
      // },
      // {
      //   href: "/private/page",
      //   title: "page",
      // },
      // {
      //   href: "/pages/blank",
      //   title: "Blank",
      // },
    ],
  },
];

const navItems = [
  {
    title: "Pages",
    pages: pagesSection,
  },
];

export default navItems;
