import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { auth, db } from "../../../../contexts/FirebaseAuthContext";
import { onAuthStateChanged } from "firebase/auth";
import { serverTimestamp } from "firebase/firestore";
import { useForm } from "react-hook-form";

const OutofServiceAck = () => {
  const { register, handleSubmit, setValue } = useForm();
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchSample = async () => {
      const docRef = db.collection("samples").doc(id);
      const doc = await docRef.get();

      if (doc.exists) {
        setData(doc.data());
      } else {
        setData({});
      }
    };

    onAuthStateChanged(auth, (user) => {
      if (user) {
        setValue("samplmanag", auth.currentUser.email);
      }
    });

    fetchSample();
  }, [id, setValue]);

  const getUserDataByEmail = async (email) => {
    const querySnapshot = await db.collection("users").where("email", "==", email).get();
    const userDoc = querySnapshot.docs[0];
    return userDoc ? userDoc.data() : null;
  };

  const onFormSubmit = async (data) => {
    const { samplmanag } = data;
    const uid = auth.currentUser.uid;
    const email = auth.currentUser.email;

    try {
      const docRef = db.collection("samples").doc(id);
      const doc = await docRef.get();
      const currentSample = doc.data();

      const userData = await getUserDataByEmail(samplmanag);
      if (userData && userData.supervisor) {
        const updatedData = {
          ...data,
          samplmanag: samplmanag,
          samplmanagname: userData.displayName,
          osDate: serverTimestamp(),
          lastmanag: currentSample.samplmanag,
          inOSapprby: "",
          status: "Lost",
          supervisor: userData.supervisor,
          city: userData.city,
          country: userData.country,
          userLat: userData.userLat,
          userLng: userData.userLng,
          userLocation: userData.userLocation,
        };

        try {
          await db.collection("samples").doc(id).update(updatedData);
          alert("Sample Returned Successfully");

          const historyData = {
            email,
            uid,
            actiondate: serverTimestamp(),
            action: "Lost Acknowledge",
            ...data,
            customers: currentSample.customers,
            brand: currentSample.brand,
            model: currentSample.model,
            serialNumber: currentSample.serialNumber,
            imei1: currentSample.imei1,
            imei2: currentSample.imei2,
            ...updatedData,
          };

          await db.collection("history").doc().set(historyData);
          setTimeout(() => navigate("/dashboard/homefsos"), 500);
        } catch (error) {
          console.error(error.code);
          console.error(error.message);
          alert("Sample Return Error: " + error);
        }
      }
    } catch (error) {
      console.error(error);
      // handle the error in a user-friendly way
    }
  };


  return (
    <div style={{ marginTop: "100px" }}>
      <form
        style={{
          margin: "auto",
          padding: "15px",
          maxWidth: "400px",
          alignContent: "center",
        }}
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <label>Sample Manager:</label>
        <input
          {...register("samplmanag")}
          type="text"
          id="samplmanag"
          name="samplmanag"
          placeholder="Sample Manager..."
          readOnly
        />
        <input type="submit" value={data.id ? "Ack Lost" : "Save"} />
      </form>
    </div>
  );
};

export default OutofServiceAck;
