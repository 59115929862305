import React, { useState, useEffect } from "react";
import { Bar, HorizontalBar } from "react-chartjs-2";
import firebase from "firebase/compat";
import { Card, Dropdown } from "react-bootstrap";

import { MoreHorizontal } from "react-feather";
import {
  collection,
  doc,
  collectionGroup,
  getDocs,
  query,
  where,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../../config";

import usePalette from "../../../hooks/usePalette";

const BarChart = () => {
  const palette = usePalette();
  const [chartValue, setChartLabel] = useState([]);
  const [registeredRData, setregisteredData] = useState([]);
  const [transitRData, settransitData] = useState([]);
  const [useRData, setuseData] = useState([]);
  const [returnRData, setreturnData] = useState([]);
  const [returnedRData, setreturnedData] = useState([]);
  const [requestedRData, setrequestedData] = useState([]);
  const [approvedRData, setapprovedData] = useState([]);
  var registeredData = [],
    transitData = [],
    useData = [],
    returnData = [],
    returnedData = [],
    requestedData = [],
    approvedData = [];
  //setState({ ...state, [name]: value });
  useEffect(() => {
    myFunction();
    return () => {
      setChartLabel([]);
      setregisteredData([]);
      settransitData([]);
      setuseData([]);
      setreturnData([]);
      setreturnedData([]);
      setrequestedData([]);
      setapprovedData([]);
    };
  }, []);
  const myFunction = () => {
    firebase.auth().onAuthStateChanged(async (user) => {
      const dataUsers = query(collection(db, "users"), where("uid", "==", user.uid));
      const resultUsers = await getDocs(dataUsers);
      const loggedUser = resultUsers.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      let userUIDSet = new Set(); // To ensure unique users
      let userarray = [];

      if (loggedUser[0].role === "admin") {
        const dataCollectionRef = query(collection(db, "users"));
        const result = await getDocs(dataCollectionRef);
        result.docs.forEach(querySnap => {
          if (!userUIDSet.has(querySnap.data().uid)) {
            userUIDSet.add(querySnap.data().uid);
            userarray.push(querySnap.data());
          }
        });
      } else if (loggedUser[0].role === "supervisor") {
        const dataCollectionRef = query(
          collection(db, "users"),
          where("supervisor", "==", user.email)
        );
        const result = await getDocs(dataCollectionRef);
        result.docs.forEach(querySnap => {
          if (!userUIDSet.has(querySnap.data().uid)) {
            userUIDSet.add(querySnap.data().uid);
            userarray.push(querySnap.data());
          }
        });
        const dataCollectionReftemp = query(
          collection(db, "users"),
          where("uid", "==", user.uid)
        );
        const resulttemp = await getDocs(dataCollectionReftemp);
        resulttemp.docs.forEach(querySnap => {
          if (!userUIDSet.has(querySnap.data().uid)) {
            userUIDSet.add(querySnap.data().uid);
            userarray.push(querySnap.data());
          }
        });
      } else if (loggedUser[0].role === "user") {
        userarray.push(loggedUser[0]);
      }

      let updatedValue = [];

      for (const snap of userarray) {
        updatedValue.push(snap.displayName);
        const sampleSnaps = await firebase.firestore()
          .collection("samples")
          .where("samplmanag", "==", snap.email)
          .get();

        let updatedData = {};
        sampleSnaps.forEach((sampleSnap) => {
          let sample = sampleSnap.data();
          if (typeof updatedData[sample.status] === "undefined") {
            updatedData[sample.status] = 1;
          } else {
            updatedData[sample.status]++;
          }
        });

        // Logic to set the state for each status, simplified to avoid repetition:
        const statusMapping = {
          "Registered": setregisteredData,
          "In Transit": settransitData,
          "In Use": setuseData,
          "In Return": setreturnData,
          "Returned": setreturnedData,
          "Request Lost": setrequestedData,
          "Lost": setapprovedData,
        };

        for (const [status, setStatus] of Object.entries(statusMapping)) {
          let dataArr = (typeof updatedData[status] === "undefined") ? [0] : [updatedData[status]];
          setStatus(prevData => [...prevData, ...dataArr]);
        }
      }
      setChartLabel(updatedValue);
    });
  };


  const data = {
    labels: chartValue,
    datasets: [
      {
        label: "Registered",
        backgroundColor: palette.primary,
        borderColor: palette.primary,
        hoverBackgroundColor: palette.primary,
        hoverBorderColor: palette.primary,
        data: registeredRData,
        barPercentage: 0.325,
        categoryPercentage: 1,
      },
      {
        label: "In Transit",
        backgroundColor: palette.success,
        borderColor: palette.success,
        hoverBackgroundColor: palette.success,
        hoverBorderColor: palette.success,
        data: transitRData,
        barPercentage: 0.325,
        categoryPercentage: 1,
      },
      {
        label: "In Use",
        backgroundColor: palette.warning,
        borderColor: palette.warning,
        hoverBackgroundColor: palette.warning,
        hoverBorderColor: palette.warning,
        data: useRData,
        barPercentage: 0.325,
        categoryPercentage: 1,
      },
      {
        label: "In Return",
        backgroundColor: palette.danger,
        borderColor: palette.danger,
        hoverBackgroundColor: palette.danger,
        hoverBorderColor: palette.danger,
        data: returnRData,
        barPercentage: 0.325,
        categoryPercentage: 1,
      },
      {
        label: "Returned",
        backgroundColor: "purple",
        borderColor: "purple",
        hoverBackgroundColor: "purple",
        hoverBorderColor: "purple",
        data: returnedRData,
        barPercentage: 0.325,
        categoryPercentage: 1,
      },
      {
        label: "Request Lost",
        backgroundColor: "yellow",
        borderColor: "yellow",
        hoverBackgroundColor: "yellow",
        hoverBorderColor: "yellow",
        data: requestedRData,
        barPercentage: 0.325,
        categoryPercentage: 1,
      },
      {
        label: "Lost",
        backgroundColor: "pink",
        borderColor: "pink",
        hoverBackgroundColor: "pink",
        hoverBorderColor: "pink",
        data: approvedRData,
        barPercentage: 0.325,
        categoryPercentage: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cornerRadius: 15,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            stepSize: 20,
          },
          stacked: true,
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: "transparent",
          },
          stacked: true,
        },
      ],
    },
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <div className="card-actions float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>Action</Dropdown.Item>
              <Dropdown.Item>Another Action</Dropdown.Item>
              <Dropdown.Item>Something else here</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Card.Title tag="h5" className="mb-0">
          Analytics
        </Card.Title>
      </Card.Header>
      <Card.Body className="d-flex">
        <div className="align-self-center w-100">
          <div className="chart chart-lg">
            <HorizontalBar data={data} options={options} />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default BarChart;
