import { db } from "../../../../contexts/FirebaseAuthContext";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy
} from "firebase/firestore";

const disallowedStatuses = [
  "In Transit",
  "Returned",
  "In Return",
  "Lost"
];

const sampleRef = collection(db, "samples");
const q = query(
  sampleRef,
  where("status", "in", ["Request Lost"]),
  orderBy("reqOSDate")
);

getDocs(q).then((querySnapshot) => {
  // handle data
}).catch((error) => {
  // console.error("Error fetching documents:", error);
});



const fetchDataLost = async (role, currentUser) => {
  const sortResults = (results) => {
    // console.log("Before sort:", JSON.stringify(results, null, 2));
    const sorted = results.sort((a, b) => {
      const dateA = new Date(a.reqOSDate);
      const dateB = new Date(b.reqOSDate);
      return dateA - dateB; // sort in descending order for newest dates first
    });
    // console.log("After sort:", JSON.stringify(sorted, null, 2));
    return sorted;
  };


  try {
    // console.log(`Fetching data for role: ${role}`);
    switch (role) {
      case "admin":
        const adminQuery = query(
          collection(db, "samples"),
          where("status", "not-in", disallowedStatuses)
        );
        const adminSnapshot = await getDocs(adminQuery);
        return sortResults(
          adminSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );

      case "supervisor":
        const supervisedUsersQuery = query(
          collection(db, "users"),
          where("supervisor", "==", currentUser)
        );
        const supervisedUsersSnapshot = await getDocs(supervisedUsersQuery);
        const supervisedUserEmails = supervisedUsersSnapshot.docs.map(
          (doc) => doc.data().email
        );

        const allSamplesForSupervisor = [];

        for (const email of supervisedUserEmails) {
          const samplesQuery = query(
            collection(db, "samples"),
            where("samplmanag", "==", email),
            where("status", "not-in", disallowedStatuses)
          );
          const samplesSnapshot = await getDocs(samplesQuery);
          const userSamples = samplesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          }));
          allSamplesForSupervisor.push(...userSamples);
        }

        const ownSamplesQuery = query(
          collection(db, "samples"),
          where("samplmanag", "==", currentUser),
          where("status", "not-in", disallowedStatuses)
        );
        const ownSamplesSnapshot = await getDocs(ownSamplesQuery);
        const ownSamples = ownSamplesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        allSamplesForSupervisor.push(...ownSamples);

        const inOSapprbyQuery = query(
          collection(db, "samples"),
          where("inOSapprby", "==", currentUser),
          where("status", "not-in", disallowedStatuses)
        );
        const inOSapprbySnapshot = await getDocs(inOSapprbyQuery);
        const inOSapprbySamples = inOSapprbySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        allSamplesForSupervisor.push(...inOSapprbySamples);

        const uniqueSupervisorSamples = Array.from(
          new Set(allSamplesForSupervisor.map((a) => a.id))
        ).map((id) => allSamplesForSupervisor.find((a) => a.id === id));

        return sortResults(uniqueSupervisorSamples);

      case "user":
        const allSamplesForUser = [];

        const userQueries = [
          query(
            collection(db, "samples"),
            where("samplmanag", "==", currentUser),
            where("status", "not-in", disallowedStatuses)
          ),
          query(
            collection(db, "samples"),
            where("inOSapprby", "==", currentUser),
            where("status", "not-in", disallowedStatuses)
          )
        ];

        for (const userQuery of userQueries) {
          const userSnapshot = await getDocs(userQuery);
          const userSamples = userSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          }));
          allSamplesForUser.push(...userSamples);
        }

        const uniqueUserSamples = Array.from(
          new Set(allSamplesForUser.map((a) => a.id))
        ).map((id) => allSamplesForUser.find((a) => a.id === id));

        return sortResults(uniqueUserSamples);

      default:
        return [];
    }
  } catch (error) {
    // console.error("Error encountered:", error);
    console.error(error);
    return [];
  }
};

export default fetchDataLost;

