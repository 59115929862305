import React, { useState } from "react";
import Select from "react-select";
import Modals from "./Modal";
import { Col, Form, Button } from "react-bootstrap";
import DropSelector from "./useDropbox";
function DeviceSelector(props) {
  return (
    <Form.Group
      as={Col}
      md={props.isedit ? "3" : ""}
      controlId="validationFormik04"
    >
      <Form.Label>Device Model</Form.Label>
      <DropSelector
        placeholder="Device Model"
        name="tmodel"
        id="model"
        title="Device Model"
        DBModel="deviceModal"
        isedit={props.isedit}
        value={props.value}
        valueChange={props.valueChange}
      />
    </Form.Group>
  );
}
export default DeviceSelector;
