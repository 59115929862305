import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../../config";
import useAdminUsers from "./useAdminUsers";

const AdminModelComponent = () => {
  const {
    users,
    email,
    setEmail,
    selectedUser, // This should be used only for "Define Admin" section
    setSelectedUser,
    handleAddUser,
  } = useAdminUsers();

  const [adminModel, setAdminModel] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState(null); // This should be used only for "Select a Admin" section
  const [selectModel, setSelectModel] = useState(null);

  const fetchAdminModels = async () => {
    const q = query(collection(db, "adminModel"), orderBy("email"));

    const unsub = onSnapshot(q, (querySnapshot) => {
      let adminModelArray = [];
      querySnapshot.forEach((doc) => {
        adminModelArray.push({
          id: doc.id,
          email: doc.data().email,
        });
      });
      setAdminModel(adminModelArray);
    });

    // Cleanup function
    return () => {
      unsub();
    };
  };

  useEffect(() => {
    fetchAdminModels();
  }, [selectModel]);

  const handleSelectChange = (event) => {
    setSelectModel(event.target.value || null);
  };

  const deviceDelete = async () => {
    if (selectedAdmin) {
      try {
        const adminRef = collection(db, "adminModel");
        const q = query(adminRef, where("email", "==", selectedAdmin.email));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const docToDelete = querySnapshot.docs[0];
          await deleteDoc(doc(db, "adminModel", docToDelete.id));
          alert("This Admin email was deleted.");
        } else {
          alert("Admin email not found.");
        }
      } catch (error) {
        // console.error(error);
      }
    } else {
      alert("Please select one e-mail first.");
    }
  };

  return (
    <Row>
      <Col md={6}>
        <Card>
          <Card.Body>
            <Card.Title>Define Admin</Card.Title>
            <Form.Select
              value={selectedUser?.email || ""}
              onChange={(e) => {
                const selected = users.find(
                  (item) => item.email === e.target.value
                );
                setSelectedUser(selected || null);
              }}
            >
              <option value="">Select a Admin</option>
              {users.map((item) => (
                <option key={item.id} value={item.email}>
                  {item.email}
                </option>
              ))}
            </Form.Select>
            <br />
            <button
              variant="primary"
              onClick={(e) => {
                e.preventDefault();
                handleAddUser();
              }}
              className="btn btn-primary"
            >
              Add Admin
            </button>
            <br />
            <table>
              <thead>
                <tr>
                  <th>Users</th>
                </tr>
              </thead>
              <tbody>
                {users.map((item) => (
                  <tr key={item.id}>
                    <td>{item.email}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card.Body>
        </Card>
      </Col>
      <Col md={6}>
        <Card>
          <Card.Body>
            <Card.Title>Select a Admin</Card.Title>
            <Form.Select
              value={selectedAdmin?.email || ""}
              onChange={(e) => {
                const selected = adminModel.find(
                  (item) => item.email === e.target.value
                );
                setSelectedAdmin(selected || null);
              }}
              className="form-select mb-3"
            >
              <option value="">Select a Admin</option>
              {adminModel.map((item) => (
                <option key={item.id} value={item.email}>
                  {item.email}
                </option>
              ))}
            </Form.Select>
            <br />
            <Button
              variant="danger"
              onClick={(e) => {
                e.preventDefault();
                deviceDelete();
              }}
            >
              Delete Admin
            </Button>
            <br />
            <table>
              <thead>
                <tr>
                  <th>Admin</th>
                </tr>
              </thead>
              <tbody>
                {adminModel.map((item) => (
                  <tr key={item.id}>
                    <td>{item.email}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default AdminModelComponent;
