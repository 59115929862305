// BrandSelector.js
import React from "react";
import { Col, Form } from "react-bootstrap";
import DropSelector from "./useDropbox";

function BrandSelector(props) {
  const { isedit, valueChange } = props;

  return (
    <Form.Group as={Col} md={isedit ? "3" : ""} controlId="validationFormik04">
      <Form.Label>Brand</Form.Label>
      <DropSelector
        placeholder="Brand"
        name="brand"
        id="brand"
        title="Brand Model"
        DBModel="brandModel"
        isedit={isedit}
        valueChange={valueChange}
      />
    </Form.Group>
  );
}

export default BrandSelector;
