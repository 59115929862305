import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useTable, useFilters, useGlobalFilter, useSortBy } from "react-table";
import { collection, getDocs, query, where } from "firebase/firestore";
import { Card, Container, Table, Form, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { db, auth } from "../../../../contexts/FirebaseAuthContext";
import firebase from "firebase/compat";
import { Helmet } from "react-helmet-async";
import fetchDataEdit from "./fetchdataEdit"; // Update this import path

// Utility function to format date
const formatDate = (date) => {
  if (!date || isNaN(date)) return "";
  const options = { year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric", hour12: false };
  return new Date(date.seconds * 1000).toLocaleString("en-US", options);
};

// Custom filter method for date column
const dateFilterMethod = (rows, id, filterValue) => {
  return rows.filter(row => {
    const rowValue = new Date(row.values[id].seconds * 1000);
    let startDate = new Date(filterValue[0]);
    startDate.setHours(0, 0, 0, 0);
    let endDate = filterValue[1] ? new Date(filterValue[1]) : new Date(filterValue[0]);
    endDate.setHours(23, 59, 59, 999);
    return rowValue >= startDate && rowValue <= endDate;
  });
};

// Default Column Filter
function DefaultColumnFilter({
                               column: { filterValue, setFilter },
                             }) {
  return (
    <Form.Control
      value={filterValue || ""}
      onChange={e => setFilter(e.target.value || undefined)}
      placeholder="Search..."
    />
  );
}

// Delete Button Component
const DeleteButton = ({ row, onDelete }) => {
  const id = row.original.id;
  if (auth.currentUser.email === row.original.registeredby && "Registered" === row.original.status) {
    return <button className="btn btn-delete" onClick={() => onDelete(id)}>Delete</button>;
  }
  return null;
};


// Edit Button Component
const EditButton = ({ row }) => {
  if (auth.currentUser.email === row.original.registeredby && "Registered" === row.original.status) {
    return <Link to={`/private/editsample/${row.original.id}`}><button className="btn btn-transfer">Edit</button></Link>;
  }
  return null;
};

const HomeFSEdit = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  // Define the onDelete function inside the component
  const onDelete = useCallback((id) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      db.collection("samples").doc(id).delete().then(() => {
        alert("Sample deleted successfully!");
        window.location.reload(); // Refresh the page
      }).catch((error) => {
        console.error("Error deleting sample:", error);
      });
    }
  }, [navigate]);

  useEffect(() => {
    let isMounted = true;
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user && isMounted) {
        try {
          const dataUsers = query(collection(db, "users"), where("uid", "==", user.uid));
          const resultUsers = await getDocs(dataUsers);
          const loggedUser = resultUsers.docs.map(doc => ({ ...doc.data(), id: doc.id }));
          if (loggedUser.length > 0) {
            const { role, email: currentUser } = loggedUser[0];
            const fetchedData = await fetchDataEdit(role, currentUser);
            setData(fetchedData);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    });
    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, []);


  const sortTypes = useMemo(() => ({
    registereddate: (row1, row2) => {
      const date1 = new Date(row1.values.registereddate.seconds * 1000);
      const date2 = new Date(row2.values.registereddate.seconds * 1000);
      return date1.getTime() - date2.getTime();
    },
    receivedDate: (row1, row2) => {
      const date1 = new Date(row1.values.receiveddate.seconds * 1000);
      const date2 = new Date(row2.values.receiveddate.seconds * 1000);
      return date1.getTime() - date2.getTime();
    },
  }), []);



  function DateColumnFilter({
                              column: { filterValue = [], setFilter },
                            }) {
    return (
      <div className="d-flex flex-column mt-2">
        <Form.Control
          value={filterValue[0] || ""}
          type="date"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [
              val ? val : undefined,
              old[1],
            ]);
          }}
          placeholder="Min"
          style={{
            width: "160px",
          }}
        />
        <Form.Control
          value={filterValue[1] || ""}
          type="date"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [
              old[0],
              val ? val : undefined,
            ]);
          }}
          placeholder="Max"
          style={{
            width: "160px",
            marginTop: "10px",
          }}
        />
      </div>
    );
  }





  // This is a custom filter UI for selecting
  // a unique option from a list
  function SelectColumnFilter({
                                column: { filterValue, setFilter, preFilteredRows, id },
                              }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <Form.Select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </Form.Select>
    );
  }

  // function DefaultColumnFilter({
  //                                column: { filterValue, preFilteredRows, setFilter },
  //                              }) {
  //   const count = preFilteredRows.length;
  //
  //   return (
  //     <Form.Control
  //       value={filterValue || ""}
  //       onChange={(e) => {
  //         setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
  //       }}
  //       placeholder={`Search ${count} records...`}
  //       className="mt-2"
  //     />
  //   );
  // }

  // Custom filter method for date column
  const dateFilterMethod = (rows, id, filterValue) => {
    return rows.filter(row => {
      const rowValue = new Date(row.values[id].seconds * 1000);

      let startDate = new Date(filterValue[0]);
      startDate.setHours(0, 0, 0, 0);  // Set start time to the start of the day

      let endDate;
      if (filterValue[1]) {
        endDate = new Date(filterValue[1]);
        endDate.setHours(23, 59, 59, 999);  // Set end time to the end of the day
      } else {
        endDate = new Date(filterValue[0]);
        endDate.setHours(23, 59, 59, 999);  // If no end date is provided, consider the whole day
      }

      return rowValue >= startDate && rowValue <= endDate;
    });
  };


  const ColumnFilteringTable = ({ columns, data }) => {
    const filterTypes = React.useMemo(
      () => ({
        // Or, override the default text filter to use
        // "startWith"
        text: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
        // Add date filter function
        date: dateFilterMethod,
      }),
      []
    );


    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      setGlobalFilter,
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        filterTypes,
        sortTypes // add this line
      },
      useFilters,
      useGlobalFilter,
      useSortBy // add this line
    );


    const { globalFilter } = state; // Get the global filter value from the state

    const EditButton = ({ row }) => {
      if (
        auth.currentUser.email === row.original.registeredby &&
        "Registered" === row.original.status
      ) {
        return (
          <Link to={`/private/editsample/${row.original.id}`}>
            <button className="btn btn-transfer">Edit</button>
          </Link>
        );
      }
      return null;
    };


    return (
      <Card>
        <Card.Header>
          <Card.Title>Search</Card.Title>
          <div className="mt-2">
            <Form.Group as={Col} md="2"> {/* Adjust the column size as needed */}
              <Form.Control
                className="form-control"
                value={globalFilter || ""}
                onChange={(e) => setGlobalFilter(e.target.value || undefined)}
                placeholder="Search all columns..."
              />
            </Form.Group>
          </div>
        </Card.Header>
        <Card.Body>
          <Table striped bordered {...getTableProps()}>
            <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
            {column.isSorted
              ? column.isSortedDesc
                ? ' 🔽'
                : ' 🔼'
              : ''}
          </span>
                    {/* Render the columns filter UI */}
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    );
  };

const tableColumns = [
  {
    Header: "Country",
    accessor: "country",
    Filter: SelectColumnFilter,
    filter: "includes",
  },
  {
    Header: "Brand",
    accessor: "brand",
    Filter: SelectColumnFilter,
    filter: "includes",
  },
  {
    Header: "Model",
    accessor: "model",
    Filter: DefaultColumnFilter,
    filter: "includes",
  },
  {
    Header: "Hardware Version",
    accessor: "hwVersion",
    Filter: SelectColumnFilter,
    filter: "includes",
  },
  {
    Header: "Serial Number",
    accessor: "serialNumber",
    Filter: DefaultColumnFilter,
    filter: "includes",
  },
  {
    Header: "Imei 1",
    accessor: "imei1",
    Filter: DefaultColumnFilter,
    filter: "includes",
  },
  {
    Header: "Imei 2",
    accessor: "imei2",
    Filter: DefaultColumnFilter,
    filter: "includes",
  },
  {
    Header: "Status",
    accessor: "status",
    Filter: SelectColumnFilter,
    filter: "includes",
  },
  {
    Header: "Actions",
    accessor: "actions",
    disableFilters: true,
    Cell: ({ row }) => {
      if (  auth.currentUser.email == row.original.registeredBy &&
        "Registered" == row.original.status
      ) {
        return (
          <React.Fragment>
            {/*<Link to={`/private/editsample/${row.original.id}`}>*/}
            {/*  <button className="btn btn-transfer">Edit</button>*/}
            {/*</Link>*/}
            <button
              className="btn btn-delete"
              onClick={() => onDelete(row.original.id)}
            >
              Delete
            </button>
          </React.Fragment>
        );
      }
      return null;
    },
  },
];

  return (
    <>
      <Helmet title="Sample Management System" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Sample Management System - Delete Sample</h1>
        <ColumnFilteringTable columns={tableColumns} data={data} />
      </Container>
    </>
  );
};

export default HomeFSEdit;