// components/SettingPage.js
import React from "react";
import { Formik } from "formik";
import { Form, Row, Card, Container } from "react-bootstrap";
import * as Yup from "yup";
import BrandSelector from "../../../hooks/SMSHooks/useBrand";
import SimTypeSelector from "../../../hooks/SMSHooks/useSimType";
import SlotSelector from "../../../hooks/SMSHooks/useSlots";
import DevTypeSelector from "../../../hooks/SMSHooks/useTypeDevice";
import HWTypeSelector from "../../../hooks/SMSHooks/useHWType";
import OSSelector from "../../../hooks/SMSHooks/useOS";
import DeviceSelector from "../../../hooks/SMSHooks/useDevice.js";
import NetworkSelector from "../../../hooks/SMSHooks/useTechNetwork";
import Hardware from "../../../hooks/SMSHooks/useHardware";
import CustomerSelector from "../../../hooks/SMSHooks/useCustomer";
import { Helmet } from "react-helmet-async";

const schema = Yup.object().shape({
  // Add your validation rules here
  brand: Yup.string().required(),
  model: Yup.string().required(),
  kinDevice: Yup.string().required(),
  typeDevice: Yup.string().required(),
  simCard: Yup.string().required(),
  hwtype: Yup.string().required(),
  hardware: Yup.string().required(),
  network: Yup.string().required(),
  os: Yup.string().required(),
  statusDevice: Yup.string().required(),
  customers: Yup.string().required(),
});

const FormRow = ({ children }) => <Row className="mb-3">{children}</Row>;

const AddSampleForm = ({ handleSubmit }) => (
  <Form noValidate onSubmit={handleSubmit}>
    <FormRow>
      <CustomerSelector isedit={"true"} />
      <BrandSelector isedit={"true"} />
      <DevTypeSelector isedit={"true"} />
      <SimTypeSelector isedit={"true"} />
    </FormRow>
    <FormRow>
      <NetworkSelector isedit={"true"} />
      <SlotSelector isedit={"true"} />
      <OSSelector isedit={"true"} />
      <HWTypeSelector isedit={"true"} />
    </FormRow>
    <FormRow>
      <Hardware isedit={"true"} />
      <DeviceSelector isedit={"true"} />
    </FormRow>
  </Form>
);

const AddSample = () => (
  <Card>
    <Card.Header>
      <Card.Title tag="h5">Add New Sample Form</Card.Title>
      <h6 className="card-subtitle text-muted">Please add new sample</h6>
    </Card.Header>
    <Card.Body>
      <Formik
        validationSchema={schema}
        onSubmit={console.log}
        initialValues={{
          brand: "",
          model: "",
          kinDevice: "",
          typeDevice: "",
          simCard: "",
          hwtype: "",
          hardware: "",
          network: "",
          os: "",
          slots: "",
          terms: false,
          customers: "",
        }}
      >
        {({ handleSubmit }) => <AddSampleForm handleSubmit={handleSubmit} />}
      </Formik>
    </Card.Body>
  </Card>
);

const AddSamplePage = () => (
  <React.Fragment>
    <Helmet title="Add New Settings" />
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">SMS New Settings</h1>
      <AddSample />
    </Container>
  </React.Fragment>
);

export default AddSamplePage;
