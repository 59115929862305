import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Form,
} from "react-bootstrap";
import avatar6 from "../../../../assets/img/avatars/avatar-6.png";
import { db } from "../../../../contexts/FirebaseAuthContext";
import ImageUpload from "./ImageUpload";

const initialState = {
  displayName: "",
  phoneNumber: "",
  passPort: "",
  userLocation: "",
  photoURL: avatar6, // Set the default photoURL to avatar6
  userLat: "",
  userLng: "",
  city: "",
  country: "",
};

const libraries = ["places"];

function EditEmployees() {
  const [state, setState] = useState(initialState);
  const navigate = useNavigate();
  const { id } = useParams();
  const autocompleteRef = useRef(null);
  // Destructure the state object directly within the component's render portion
  const { displayName, phoneNumber, passPort, userLocation, photoURL } = state;

  useEffect(() => {
    if (id) {
      const unsubscribe = db
        .collection("users")
        .doc(id)
        .onSnapshot((snapshot) => {
          const userData = snapshot.data();
          if (userData) {
            setState(prevState => ({ ...prevState, ...userData }));
          }
        });

      return () => unsubscribe();
    }
  }, [id]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const getLocationDetails = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
      );
      const data = await response.json();
      if (data.results.length > 0) {
        const addressComponents = data.results[0].address_components;

        let city = '';
        let country = '';

        for (const component of addressComponents) {
          if (component.types.includes('locality') || component.types.includes('administrative_area_level_2')) {
            city = component.long_name;
          }
          if (component.types.includes('country')) {
            country = component.long_name;
          }
        }

        // Verifica se a cidade foi encontrada, senão busca em níveis administrativos mais altos
        if (!city) {
          for (const component of addressComponents) {
            if (component.types.includes('administrative_area_level_1')) {
              city = component.long_name;
              break;
            }
          }
        }

        setState((prevState) => ({
          ...prevState,
          city: city,
          country: country
        }));
      }
    } catch (error) {
      console.error('Error fetching location details:', error);
    }
  };


  const getRealLocation = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
      );
      const data = await response.json();
      if (data.results.length > 0) {
        // Escolhe o primeiro resultado e retorna o endereço formatado.
        return data.results[0].formatted_address; // Endereço completo e legível.
      } else {
        console.error('No address found for the given coordinates.');
        return 'Address not found';
      }
    } catch (error) {
      console.error('Error fetching the real location:', error);
      return 'Error fetching address';
    }
  };



  useEffect(() => {
    const fetchRealLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const realLat = position.coords.latitude.toString();
          const realLng = position.coords.longitude.toString();

          // Você pode chamar a função getRealLocation aqui se quiser obter o endereço real.
          // Supondo que você tenha uma função para converter lat/lng em um endereço.
          const realLocationAddress = await getRealLocation(realLat, realLng);

          setState(prevState => ({
            ...prevState,
            realLat: realLat,
            realLng: realLng,
            realLocation: realLocationAddress
          }));
        }, (error) => {
          console.error(error.message);
        });
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    };

    fetchRealLocation();
  }, [userLocation]); // Dependência em userLocation, para atualizar a posição real quando a localização do usuário muda.


  const onPlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (!place || !place.geometry) {
        console.log("No details available for this location");
        return;
      }
      const locationValue = place.formatted_address;
      const lat = place.geometry.location.lat().toString();
      const lng = place.geometry.location.lng().toString();

      setState(prevState => ({
        ...prevState,
        userLocation: locationValue,
        userLat: lat,
        userLng: lng
      }));

      // Aqui você pode chamar getLocationDetails para atualizar a cidade e o país
      getLocationDetails(lat, lng);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await db.collection('users').doc(id).update(state);
      alert("Employee Updated Successfully");
      setTimeout(() => navigate("/pages/employeeprivate"), 500);
    } catch (error) {
      console.error(error.code);
      console.error(error.message);
      alert("Employee Update Error: " + error);
    }
  };

  const GOOGLE_MAPS_LIBRARIES = ["places"];

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: GOOGLE_MAPS_LIBRARIES,
    language: 'en',
  });


  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  return (
    <Row>
      <Col md="8" xl="9">
        <Form onSubmit={handleSubmit}>
          <Card>
            <Card.Header>
              <Card.Title className="mb-0">Personal info</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md="6">
                  <Form.Group className="mb-6">
                    <Form.Label htmlFor="displayName">Display Name</Form.Label>
                    <Form.Control
                      type="text"
                      id="displayName"
                      name="displayName"
                      value={displayName}
                      placeholder="Display Name"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="mb-6">
                    <Form.Label htmlFor="userLocation">Address</Form.Label>
                    <Autocomplete
                      apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                      onLoad={(ref) => (autocompleteRef.current = ref)}
                      onPlaceChanged={onPlaceChanged}
                    >
                      <div>
                        <Form.Control
                          type="text"
                          id="userLocation"
                          name="userLocation"
                          value={userLocation}
                          placeholder="Address"
                          onChange={handleChange}
                        />
                      </div>
                    </Autocomplete>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="mb-6">
                    <Form.Label htmlFor="phoneNumber">Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      id="phoneNumber"
                      name="phoneNumber"
                      value={phoneNumber}
                      placeholder="Phone Number"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="mb-6">
                    <Form.Label htmlFor="passPort">Passport</Form.Label>
                    <Form.Control
                      type="text"
                      id="passPort"
                      name="passPort"
                      value={passPort}
                      placeholder="Passport"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Button variant="primary" type="submit">
                Save changes
              </Button>
            </Card.Body>
          </Card>
        </Form>
      </Col>
      <Col md="4" xl="3">
        <Card>
          <Card.Header>
            <Card.Title className="mb-0">Profile Picture</Card.Title>
          </Card.Header>
          <Card.Body>
            <div className="text-center">
              <ImageUpload id={id} photoURL={photoURL || ""} />
              <small>
                For best results, use an image at least 128px by 128px in .jpg
                format
              </small>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
const EditEmployeesPage = () => (
  <React.Fragment>
    <Helmet title="Profile" />
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Profile</h1>
      <Row>
        <Col md="8" xl="9">
          <EditEmployees />
        </Col>
      </Row>
    </Container>
  </React.Fragment>
);

export default EditEmployeesPage;

