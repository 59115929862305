import { useState, useEffect } from "react";
import firebase from "firebase/compat";
import { db } from "../../contexts/FirebaseAuthContext";
import { collection, query, getDocs, where } from "firebase/firestore";

export const useRole = () => {
  const [role, setRole] = useState("");

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const dataUsers = query(
          collection(db, "users"),
          where("email", "==", user.email)
        );
        const resultUsers = await getDocs(dataUsers);
        var loggedUser = resultUsers.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setRole(loggedUser[0].role);
      }
    });
  }, []);

  return role;
};
