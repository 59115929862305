import React, { useState } from "react";
import Select from "react-select";
import Modals from "./Modal";
import { Col, Form, Button } from "react-bootstrap";
import DropSelector from "./useDropbox";
function HWTypeSelector(props) {
  const hwTypes = [
    { value: "Secure", label: "Secure" },
    { value: "Non-Secure", label: "Non-Secure" },
  ];
  const [show, setShow] = useState(false);
  return (
    <Form.Group
      as={Col}
      md={props.isedit ? "3" : ""}
      controlId="validationFormik04"
    >
      <Form.Label>HW Type</Form.Label>
      <DropSelector
        placeholder="HW Types"
        name="hwtypes"
        id="hwtypes"
        title="Hw Types Model"
        DBModel="hwtypesModel"
        isedit={props.isedit}
        value={props.value}
        valueChange={props.valueChange}
      />
    </Form.Group>
  );
}
export default HWTypeSelector;
