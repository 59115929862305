import React from "react";
import { Button } from "react-bootstrap";
import { uploadFile } from "../../../../contexts/FirebaseAuthContext";
import avatar6 from "../../../../assets/img/avatars/avatar-6.png";

function ImageUpload({ id, photoURL }) {
  const handleUpload = (e) => {
    uploadFile(id, e);
  };

  return (
    <div>
      {photoURL ? (
        <img
          src={photoURL}
          alt="Profile"
          style={{ width: "128px", height: "128px" }}
        />
      ) : (
        <img
          src={avatar6}
          alt="Default Profile"
          style={{ width: "128px", height: "128px" }}
        />
      )}
      <input
        type="file"
        id="upload"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleUpload}
      />
      <div style={{ marginTop: "16px" }}>
        <label htmlFor="upload">
          <Button variant="primary" as="span">
            Upload
          </Button>
        </label>
      </div>
    </div>
  );
}

export default ImageUpload;
