import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  doc,
  updateDoc,
  setDoc,
  deleteDoc,
  where,
  getDocs,
  addDoc,
} from "firebase/firestore";
import { db } from "../../../../config";

const useSupervisorModel = () => {
  const [supervisorModel, setSupervisorModel] = useState([]);
  const [model, setModel] = useState("");
  const [selectModel, setSelectModel] = useState("");
  const [selectedUser, setSelectedUser] = useState("");

  // console.table(supervisorModel);
  // console.log(model);
  // console.log(selectModel);
  // console.log(selectedUser);

  useEffect(() => {
    const fetchSupervisorModels = async () => {
      const q = query(collection(db, "supervisorModel"), orderBy("email"));

      const unsub = onSnapshot(q, (querySnapshot) => {
        let supervisorModelArray = [];
        querySnapshot.forEach((doc) => {
          supervisorModelArray.push({
            id: doc.id,
            email: doc.data().email,
          });
        });
        setSupervisorModel(supervisorModelArray);
      });

      // Cleanup function
      return () => {
        unsub();
      };
    };

    fetchSupervisorModels();
  }, [selectModel]);

  const handleSelectChange = (event) => {
    setSelectModel(event.target.value);
  };

  const deviceDelete = async () => {
    if (selectModel.email?.trim() !== "") {
      try {
        const supervisorRef = collection(db, "supervisorModel");
        const q = query(supervisorRef, where("email", "==", selectModel.email));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const docToDelete = querySnapshot.docs[0];
          await deleteDoc(doc(db, "supervisorModel", docToDelete.id));
          alert("This Supervisor email was deleted.");
        } else {
          alert("Please select one e-mail first.");
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  // const handleAddUser = async () => {
  //   if (selectedUser.email?.trim() !== "") {
  //     try {
  //       const supervisorRef = collection(db, "supervisorModel");
  //       const supervisorSnapshot = await getDocs(
  //         query(supervisorRef, where("email", "==", selectedUser.email))
  //       );
  //
  //       if (supervisorSnapshot.empty) {
  //         await addDoc(supervisorRef, {
  //           email: selectedUser.email,
  //         });
  //         setSelectedUser("");
  //       } else {
  //         alert("This email already exists in the supervisorModel collection.");
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  // };

  return {
    supervisorModel,
    setSupervisorModel,
    model,
    setModel,
    selectModel,
    setSelectModel,
    selectedUser,
    setSelectedUser,
    deviceDelete,
    handleSelectChange,
  };
};

export default useSupervisorModel;
