import React, { useEffect, useState } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db, auth } from "../../../contexts/FirebaseAuthContext";

const UserStatistics = () => {
  const [userSamples, setUserSamples] = useState({});
  const [totalSamples, setTotalSamples] = useState(0);  // State for total count
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const dataUsers = query(
          collection(db, "users"),
          where("uid", "==", user.uid)
        );
        const resultUsers = await getDocs(dataUsers);
        const loggedUser = resultUsers.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setCurrentUser(loggedUser[0]);
      } else {
        setCurrentUser(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (currentUser) {
      countUserSamples(currentUser.email);
    }
  }, [currentUser]);

  const countUserSamples = async (userEmail) => {
    const samplesQuery = query(
      collection(db, "samples"),
      where("samplmanag", "==", userEmail)
    );
    const sampleDocs = await getDocs(samplesQuery);
    const samples = sampleDocs.docs.map((doc) => doc.data());

    let total = 0;  // Initialize total count
    const sampleCount = samples.reduce((acc, sample) => {
      const status = sample.status;
      if (status !== "Returned" && status !== "Lost") {
        acc[status] = (acc[status] || 0) + 1;
        total++;  // Increment total count
      }
      return acc;
    }, {});

    setUserSamples(sampleCount);
    setTotalSamples(total);  // Set total count
  };

  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">User Sample Statistics</h1>
        <h2>Total Samples in Your Responsibility: {totalSamples}</h2>  {/* Display total count */}
        <Row>
          {Object.keys(userSamples).map((status) => (
            <Col key={status} md="4">
              <Card>
                <Card.Body>
                  <Card.Title>{status}</Card.Title>
                  <Card.Text>
                    Number of Samples: {userSamples[status]}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default UserStatistics;