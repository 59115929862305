import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { Button, Col, Row, Container, Dropdown, Form } from "react-bootstrap";
import firebase from "firebase/compat";
import { db } from "../../../../config";
import { Helmet } from "react-helmet-async";
import Modal from "react-bootstrap/Modal";
import {
  getFirestore,
  doc,
  query,
  getDocs,
  updateDoc,
  setDoc,
  onSnapshot,
  querySnapshot,
  orderBy,
  deleteDoc,
  where,
  collection,
} from "firebase/firestore";
import withAdminRole from "../../../../components/withAdminRole";

const initialState = {
  trInTransitDays: "3",
  trInTransitDaysRec: "1",
  trInReturnDays: "3",
  trInReturnDaysRec: "1",
  trOSRequestDays: "3",
  trOSRequestDaysRec: "1",
  trOwnerSamples: "10",
  trOwnerSamplesRec: "3",
  disabled: "false",
  supervisor: "john.snow@test.com",
  role: "user",
};

function EditEmployees() {
  const [state, setState] = useState(initialState);
  const [data, setData] = useState({});
  const status = [
    { value: false, label: "false" },
    { value: true, label: "true" },
  ];

  const [show, setShow] = useState(false);
  const [supervisorModel, setSupervisorModel] = useState([]);
  const [model, setModel] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [selectModel, setSelectModel] = useState("");

  const {
    trInTransitDays,
    trInTransitDaysRec,
    trInReturnDays,
    trInReturnDaysRec,
    trOSRequestDays,
    trOSRequestDaysRec,
    trOwnerSamples,
    trOwnerSamplesRec,
    disabled,
    samplmax,
    samplmaxr,
    supervisor,
    role,
  } = state;
  const navigate = useNavigate();

  const { id } = useParams();
  useEffect(() => {
    firebase
      .firestore()
      .collection("users")
      .doc(id)
      .onSnapshot((snapshot) => {
        if (snapshot.id !== null) {
          setData({ ...snapshot.data() });
        } else {
          setData({});
        }
      });

    return () => {
      setData({});
    };
  }, [id]);

  useEffect(() => {
    if (id) {
      setState({ ...data });
    } else {
      setState({ ...initialState });
    }
    return () => {
      setState({ ...initialState });
    };
  }, [id, data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!id) {
      firebase
        .firestore()
        .collection("users")
        .doc(id)
        .set(state, (err) => {
          if (err) {
            alert(err);
          } else {
            alert("Sample Added Successfully");
          }
        });
    } else {
      firebase
        .firestore()
        .collection(`users`)
        .doc(`${id}`)
        .update(state)
        .then(function () {
          alert("Employee Updated Successfully");
        })
        .catch(function (error) {
          console.error(error.code);
          console.error(error.message);
          alert("Employee Updated Error: " + error);
        });
    }
    setTimeout(() => navigate("/pages/employees"), 500);
  };

  useEffect(() => {
    const q = query(collection(db, "supervisorModel"), orderBy("email", "asc"));
    const unsub = onSnapshot(q, (querySnapshot) => {
      let modelArray = [];
      querySnapshot.forEach((doc) => {
        modelArray.push({ label: doc.data().email, value: doc.data().email, id: doc.id });
      });
      setSupervisorModel(modelArray);
    }, []);
  }, [model]);



  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const addDevice = async () => {
    let exist = supervisorModel.some(
      (item) => item.label == model.toLowerCase()
    );
    if (!exist && model != "") {
      const newDesignRef = doc(collection(db, "supervisorModel"));
      const q = await setDoc(newDesignRef, { label: model.toLowerCase() });
      alert("Added " + "email" + " Successfully");
      setModel("");
      setTimeout(() => setSuccessMsg(""), 3000);
    } else if (exist && model !== "") {
      alert("already exist");
    } else {
      alert("" + "email" + "Filed Required");
    }
  };
  const deviceEdit = async () => {
    if (selectModel != "" && model != "") {
      const todo = supervisorModel.find(
        (item) => item.label == selectModel.label.toLowerCase()
      );
      await updateDoc(doc(db, "supervisorModel", todo.id), {
        label: model.toLowerCase(),
      });
      alert("Updated " + "email" + " Successfully");
      setModel("");
      setSelectModel("");
      setTimeout(() => setSuccessMsg(""), 3000);
    } else {
      alert("Select Your Model");
    }
  };
  const deviceDelete = async () => {
    if (selectModel != "" && model != "") {
      const todo = supervisorModel.find(
        (item) => item.label == selectModel.label.toLowerCase()
      );
      await deleteDoc(doc(db, "supervisorModel", todo.id));
      alert("Deleted " + "email" + " Successfully");
      setTimeout(() => setSuccessMsg(""), 3000);
      setModel("");
      setSelectModel("");
    } else {
      alert("Select Your Model");
    }
  };

  return (
    <form className="row g-3" onSubmit={handleSubmit}>
      <div className="row row-cols-2">
        <div className="col">
          <Form.Group>
            <Form.Label>trInTransitDays:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Your model..."
              id="trInTransitDays"
              name="trInTransitDays"
              value={trInTransitDays || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
        </div>
        <div className="col">
          <Form.Group>
            <Form.Label>trInTransitDaysRec:</Form.Label>
            <Form.Control
              type="text"
              placeholder="trInTransitDaysRec..."
              id="trInTransitDaysRec"
              name="trInTransitDaysRec"
              value={trInTransitDaysRec || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
        </div>
        <div className="col">
          <Form.Group>
            <Form.Label>trInReturnDays:</Form.Label>
            <Form.Control
              type="text"
              placeholder="trInReturnDays..."
              id="trInReturnDays"
              name="trInReturnDays"
              value={trInReturnDays || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
        </div>
        <div className="col">
          <Form.Group>
            <Form.Label>trInReturnDaysRec:</Form.Label>
            <Form.Control
              type="text"
              placeholder="trInReturnDaysRec..."
              id="trInReturnDaysRec"
              name="trInReturnDaysRec"
              value={trInReturnDaysRec || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
        </div>
        <div className="col">
          <Form.Group>
            <Form.Label>trOwnerSamples:</Form.Label>
            <Form.Control
              type="number"
              placeholder="trOwnerSamples ..."
              id="trOwnerSamples"
              name="trOwnerSamples"
              value={trOwnerSamples || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
        </div>
        <div className="col">
          <Form.Group>
            <Form.Label>trOwnerSamplesRec:</Form.Label>
            <Form.Control
              type="text"
              placeholder="trOwnerSamplesRec..."
              id="trOwnerSamplesRec"
              name="trOwnerSamplesRec"
              value={trOwnerSamplesRec || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
        </div>
        <div className="col">
          <Form.Group>
            <Form.Label>SampleMax:</Form.Label>
            <Form.Control
              type="text"
              placeholder="samplmax..."
              id="samplmax"
              name="samplmax"
              value={samplmax || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
        </div>
        <div className="col">
          <Form.Group>
            <Form.Label>Sample Maxr:</Form.Label>
            <Form.Control
              type="text"
              placeholder="samplmaxr..."
              id="samplmaxr"
              name="samplmaxr"
              value={samplmaxr || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
        </div>
        <div className="col">
          <label htmlFor="role" className="form-label">
            Role:
          </label>
          <input
            type="text"
            id="role"
            name="role"
            placeholder="Role..."
            value={role || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="col">
          <label htmlFor="disabled" className="form-label">
            User Status:
          </label>
          <input
            type="text"
            id="disabled"
            name="disabled"
            placeholder="User Status..."
            value={disabled || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="col">
          <Form.Group controlId="formSupervisor">
            <Form.Label>Supervisor:</Form.Label>
            <Select
              options={supervisorModel}
              value={supervisorModel.find((obj) => obj.value === supervisor)}
              onChange={(selectedOption) =>
                setState({ ...state, supervisor: selectedOption.value })
              }
            />
          </Form.Group>
        </div>


      </div>
      <div className="col-md-3">
        <div className="col">
          <Button type="submit" variant="primary" size="lg">
            {id ? "Update" : "Save"}
          </Button>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Supervisor</Modal.Title>
        </Modal.Header>
        <Modal.Body as={Col} md="12">
          <Row>
            <Form.Group as={Col} md="12">
              <Select
                onChange={(email) => {
                  setModel(email.label);
                  setSelectModel(email);
                }}
                className="react-select-container form-control p-0 border-0"
                classNamePrefix="react-select"
                type="text"
                value={selectModel}
                options={supervisorModel}
                placeholder={"email"}
                name={"supervisor"}
                id={"supervisor-modal"}
              />
              <Form.Control
                type="text"
                placeholder={"Email"}
                name="model"
                value={model}
                onChange={(e) => {
                  setModel(e.target.value);
                }}
              />

              <Button
                className="enabled"
                style={{ margin: "2px" }}
                onClick={() => {
                  addDevice(model);
                }}
                // className="disabled"
                name="addDevice"
              >
                ADD
              </Button>
              <Button
                className="enabled"
                style={{ margin: "2px" }}
                onClick={deviceEdit}
                // className="disabled"
                name="updateDevice"
              >
                UPDATE
              </Button>
              <Button
                className="enabled"
                style={{ margin: "2px" }}
                onClick={deviceDelete}
                // className="disabled"
                name="deleteDevice"
              >
                DELETE
              </Button>
              <p>{successMsg}</p>
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </form>
  );
}

const EditEmployeesPage = () => (
  <React.Fragment>
    <Helmet title="Edit Employee" />
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">SMS Setting</h1>
      <EditEmployees />
    </Container>
  </React.Fragment>
);

// export default withAdminRole(EditEmployeesPage);
export default EditEmployeesPage;
