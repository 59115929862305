import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../AddEdit.css";
import { serverTimestamp } from "firebase/firestore";
import { auth, db } from "../../../../contexts/FirebaseAuthContext";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

const initialState = {
  reqOSDate: "",
  inOSapprby: "",
  samplmanag: "",
  status: "",
};

const OutofService = () => {
  const { register, handleSubmit, reset, control } = useForm();
  const [supervisors, setSupervisors] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    db.collection("samples")
      .doc(id)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          reset(snapshot.data());
        } else {
          reset({});
        }
      });

    return () => {
      reset({});
    };
  }, [id, reset]);

  useEffect(() => {
    db.collection("adminModel")
      .get()
      .then((querySnapshot) => {
        let adminArray = [];
        querySnapshot.forEach((doc) => {
          var admin = doc.data();
          adminArray.push({ label: admin.email, value: admin.email });
        });
        setSupervisors(adminArray);
      });
  }, []);

  const onFormSubmit = (data) => {
    const uid = auth.currentUser.uid;
    const email = auth.currentUser.email;

    db.collection("users")
      .where("email", "==", data.inOSapprby.value)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          querySnapshot.forEach((doc) => {
            const user = doc.data();

            // Check if required data is available
            if (user.supervisor === undefined || user.country === undefined ||
              user.city === undefined || user.userLat === undefined ||
              user.userLng === undefined) {
              alert("Error: Required user data is undefined for user " + data.inOSapprby.value);
              throw new Error("Required user data is undefined");
            }

            const updateData = {
              ...data,
              osrequsuper: user.supervisor,
              inOSapprby: data.inOSapprby.value,
              reqOSDate: serverTimestamp(),
              status: "Request Lost",
              // country: user.country, // from user data
              // userLat: user.userLat, // from user data
              // userLng: user.userLng, // from user data
              // userLocation: user.userLocation, // assuming it's from user data
            };

            return db.collection(`samples`)
              .doc(id)
              .update(updateData);
          });
        } else {
          alert("This user does not exist");
          throw new Error("User does not exist");
        }
      })
      .then(() => {
        alert("Sample Request Lost Successfully");
        return db.collection("history")
          .doc()
          .set({
            email,
            uid,
            actiondate: serverTimestamp(),
            action: "Request Lost",
            ...data, // data might need to be updated with user's information
          });
      })
      .then(() => {
        setTimeout(() => navigate("/dashboard/homefsos"), 500);
      })
      .catch((error) => {
        console.error(error.code);
        console.error(error.message);
        alert("Sample Updated Error: " + error);
      });
  };



  return (
    <div style={{ marginTop: "100px" }}>
      <form
        style={{
          margin: "auto",
          padding: "15px",
          maxWidth: "400px",
          alignContent: "center",
        }}
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <label>Lost request to:</label>
        <Controller
          control={control}
          name="inOSapprby"
          render={({ field }) => (
            <Select
              {...field}
              options={supervisors}
              onChange={(option) => field.onChange(option)}
              isClearable
            />
          )}
        />
        <input
          type="submit"
          value={id ? "Request" : "Save"}
        />
      </form>
    </div>
  );
};

export default OutofService;

