import React, { useEffect, useState, useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { GoogleMap, Marker, MarkerClusterer, useLoadScript } from '@react-google-maps/api';
import fetchData from '../../SMS/HomeSample/fetchData';
import { db, auth } from '../../../../contexts/FirebaseAuthContext';
import { collection, getDocs, query, where } from 'firebase/firestore';

const containerStyle = {
  width: '100%',
  height: '900px'
};

const DEFAULT_COORDINATES = {
  lat: -23.607623998395173,
  lng: -46.69523196294114
};

const fetchUserData = async (user) => {
  const dataUsersQuery = query(collection(db, 'users'), where('uid', '==', user.uid));
  const resultUsers = await getDocs(dataUsersQuery);
  return resultUsers.docs.map(doc => ({ ...doc.data(), id: doc.id }))[0];
};

const GOOGLE_MAPS_LIBRARIES = ["places"];

const Markers = () => {
  const [data, setData] = useState([]);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: GOOGLE_MAPS_LIBRARIES,
    language: 'en',
  });


  const loadMarkers = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const loggedUser = await fetchUserData(user);
        const { role, email: currentUser } = loggedUser || {};
        if (role && currentUser) {
          const fetchedData = await fetchData(role, currentUser);
          setData(fetchedData);
        }
      }
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };

  useEffect(() => {
    loadMarkers();
    const unsubscribe = auth.onAuthStateChanged(loadMarkers);
    return () => unsubscribe();
  }, []);

  const validMarkers = useMemo(() => data.filter(sample =>
    !isNaN(parseFloat(sample.userLat)) &&
    !isNaN(parseFloat(sample.userLng))
  ), [data]);

  const getCenterCoordinates = useMemo(() => validMarkers.length > 0
      ? {
        lat: parseFloat(validMarkers[0].userLat),
        lng: parseFloat(validMarkers[0].userLng)
      }
      : DEFAULT_COORDINATES,
    [validMarkers]);

  if (loadError) {
    return <div>Error when load map.</div>;
  }

  if (!isLoaded || validMarkers.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <Card>
      <Card.Body>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={getCenterCoordinates}
          zoom={3}
        >
          <MarkerClusterer>
            {(clusterer) => validMarkers.map((sample, index) => (
              <Marker
                key={index}
                position={{ lat: parseFloat(sample.userLat), lng: parseFloat(sample.userLng) }}
                clusterer={clusterer}
                title={sample.serialNumber}
              />
            ))}
          </MarkerClusterer>
        </GoogleMap>
      </Card.Body>
    </Card>
  );
};

export default Markers;







