import firebase from "firebase/compat";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Badge, Card, Col, Container, Row, Table, Form } from "react-bootstrap";
import {
  collection,
  getDocs,
  query,
  where,
  serverTimestamp,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import { auth, db } from "../../../../contexts/FirebaseAuthContext";

function CenteredTableCell({children}) {
  return <td style={{ textAlign: "center" }}>{children}</td>
}

async function fetchData(query) {
  const data = await getDocs(query);
  return data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
}

function Employees() {
  const [users, setUsers] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  const fetchCurrentUser = async () => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const dataUsers = await fetchData(query(collection(db, "users"), where("uid", "==", user.uid)));
          const loggedUser = dataUsers[0];
          setCurrentUser(loggedUser);

          switch (loggedUser.role) {
            case "admin":
              const allUsers = await fetchData(collection(db, "users"));
              setUsers(allUsers);
              break;
            case "supervisor":
              const supervisedUsers = await fetchData(
                query(
                  collection(db, "users"),
                  where("uid", "!=", user.uid),
                  where("supervisor", "==", user.email)
                )
              );
              setUsers([...dataUsers, ...supervisedUsers]);
              break;
            case "user":
              setUsers(dataUsers);
              break;
          }
        } catch (error) {
          console.error("An error occurred while fetching data:", error);
        }
      }
    });
  };

  const formatDate = (date) => {
    if (!date) return "";
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    };
    return date.toLocaleString("en-US", options);
  };

  const renderUserRow = (user, index) => (
    <tbody key={user.uid}>
    <tr>
      <CenteredTableCell>
        <img
          src={user.photoURL}
          width="48"
          height="48"
          className="rounded-circle me-2"
          alt="Avatar"
        />
      </CenteredTableCell>
      <CenteredTableCell>{user.displayName}</CenteredTableCell>
      <CenteredTableCell>{user.email}</CenteredTableCell>
      <CenteredTableCell>{user.passPort}</CenteredTableCell>
      <CenteredTableCell>{user.role}</CenteredTableCell>
      <CenteredTableCell>{user.supervisor}</CenteredTableCell>
      <CenteredTableCell>{user.phoneNumber}</CenteredTableCell>
      <CenteredTableCell>{user.userLocation}</CenteredTableCell>
      <CenteredTableCell>{formatDate(user.creationTime)}</CenteredTableCell>
      <CenteredTableCell>{formatDate(user.lastSignInTime)}</CenteredTableCell>
      <CenteredTableCell>
        {currentUser.role === "admin" && (
          <Link to={`/pages/editemployees/${user.uid}`}>
            <button className="btn btn-edit">Edit</button>
          </Link>
        )}
      </CenteredTableCell>
    </tr>
    </tbody>
  );

  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Employees
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table className="mb-0">
          <thead>
          <tr>
            <CenteredTableCell scope="col">Avatar</CenteredTableCell>
            <CenteredTableCell scope="col">Name</CenteredTableCell>
            <CenteredTableCell scope="col">e-mail</CenteredTableCell>
            <CenteredTableCell scope="col">Passport Number</CenteredTableCell>
            <CenteredTableCell scope="col">role</CenteredTableCell>
            <CenteredTableCell scope="col">Supervisor</CenteredTableCell>
            <CenteredTableCell scope="col">Phone Number</CenteredTableCell>
            <CenteredTableCell scope="col">Address</CenteredTableCell>
            <CenteredTableCell scope="col">Creation Date</CenteredTableCell>
            <CenteredTableCell scope="col">Last Sign In Date</CenteredTableCell>
            <CenteredTableCell scope="col">Actions</CenteredTableCell>
          </tr>
          </thead>
          {users.map(renderUserRow)}
        </Table>
      </Card.Body>
    </Card>
  );
}

export default Employees;


