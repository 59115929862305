import React, { useState } from "react";
import Select from "react-select";
import Modals from "./Modal";
import { Col, Form, Button } from "react-bootstrap";
import DropSelector from "./useDropbox";
function CustomerSelector(props) {
  const [show, setShow] = useState(false);
  return (
    <Form.Group
      as={Col}
      md={props.isedit ? "3" : ""}
      controlId="validationFormik04"
    >
      <Form.Label>Customers</Form.Label>
      <DropSelector
        placeholder="customers"
        name="customers"
        id="customers"
        title="Customers Model"
        DBModel="customersModel"
        isedit={props.isedit}
        value={props.value}
        valueChange={props.valueChange}
      />
    </Form.Group>
  );
}

export default CustomerSelector;
