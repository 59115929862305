import { db } from "../../../../contexts/FirebaseAuthContext";
import { collection, getDocs, query, where } from "firebase/firestore";

const fetchDataEdit = async (role, currentUser) => {
  switch (role) {
    case "admin":
      const adminQuery = query(
        collection(db, "samples"),
        where("status", "==", "Registered")
      );
      const adminSnapshot = await getDocs(adminQuery);
      return adminSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

    case "supervisor":
      const supervisedUsersResult = await getDocs(
        query(
          collection(db, "users"),
          where("supervisor", "==", currentUser)
        )
      );
      const supervisedUserEmails = supervisedUsersResult.docs.map(
        (doc) => doc.data().email
      );

      let supervisorSamples = [];
      for (const email of supervisedUserEmails) {
        const userSamplesSnapshot = await getDocs(
          query(
            collection(db, "samples"),
            where("samplmanag", "==", email),
            where("status", "==", "Registered")
          )
        );
        const userSamples = userSamplesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        supervisorSamples.push(...userSamples);
      }

      const ownSamplesSnapshot = await getDocs(
        query(
          collection(db, "samples"),
          where("samplmanag", "==", currentUser),
          where("status", "==", "Registered")
        )
      );
      const ownSamples = ownSamplesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      supervisorSamples.push(...ownSamples);

      const inTransitToSnapshot = await getDocs(
        query(
          collection(db, "samples"),
          where("inTransitTo", "==", currentUser),
          where("status", "==", "Registered")
        )
      );
      const inTransitToSamples = inTransitToSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      supervisorSamples.push(...inTransitToSamples);

      const combinedSupervisorSamples = [...supervisorSamples];
      const uniqueSupervisorSamples = Array.from(new Set(combinedSupervisorSamples.map(a => a.id)))
        .map(id => combinedSupervisorSamples.find(a => a.id === id));

      return uniqueSupervisorSamples;

    case "user":
      const samplmanagQuery = query(
        collection(db, "samples"),
        where("samplmanag", "==", currentUser),
        where("status", "==", "Registered")
      );
      const samplmanagSnapshot = await getDocs(samplmanagQuery);

      const userInTransitToQuery = query(
        collection(db, "samples"),
        where("inTransitTo", "==", currentUser),
        where("status", "==", "Registered")
      );
      const userInTransitToSnapshot = await getDocs(userInTransitToQuery);

      const results = [
        ...samplmanagSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
        ...userInTransitToSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
      ];

      const uniqueResults = Array.from(new Set(results.map(a => a.id)))
        .map(id => results.find(a => a.id === id));

      return uniqueResults;

    default:
      return [];
  }
};

export default fetchDataEdit;