import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import firebase from "firebase/compat";
import fireDb from "../../../config";

const initialState = {
  country: "",
  brand: "",
  devTypes: "",
  simCard: "",
  tNetworks: "",
  slots: "",
  os: "",
  hwtype: "",
  model: "",
  serialNumber: "",
  imei1: "",
  imei2: "",
  custsamplmanag: "",
  lastmanag: "",
  samplmanag: "",
  registerby: "",
  OSapprby: "",
  phoneNumber: "",
  location: "",
  receiveDate: "",
  comments: "",
  status: "Registered",
  registeredDate: new Date().toLocaleString(),
  inTransitDate: "",
  inTransitTo: "",
  inUseDate: "",
  inReturnDate: "",
  ReturnedDate: "",
  reqOSDate: "",
  OSDate: "",
  uid: "",
};

function AddEditFS() {
  const [state, setState] = useState(initialState);
  const [data, setData] = useState({});

  const {
    country,
    brand,
    devTypes,
    simCard,
    tNetworks,
    slots,
    os,
    hwtype,
    model,
    serialNumber,
    imei1,
    imei2,
    custsamplmanag,
    phoneNumber,
    location,
    receiveDate,
    lastmanag,
    samplmanag,
    comments,
  } = state;

  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    firebase
      .firestore()
      .collection("samples")
      .doc("value", (snapshot) => {
        if (snapshot.val() !== null) {
          setData({ ...snapshot.val() });
        } else {
          setData({});
        }
      });

    return () => {
      setData({});
    };
  }, [id]);

  useEffect(() => {
    if (id) {
      setState({ ...data[id] });
    } else {
      setState({ ...initialState });
    }

    return () => {
      setState({ ...initialState });
    };
  }, [id, data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !country ||
      !brand ||
      !devTypes ||
      !simCard ||
      !tNetworks ||
      !slots ||
      !os ||
      !hwtype ||
      !model ||
      !serialNumber ||
      !imei1 ||
      !imei2 ||
      !custsamplmanag ||
      !phoneNumber ||
      !location ||
      !receiveDate ||
      !lastmanag ||
      !samplmanag ||
      // !status ||
      !comments
    ) {
      alert("Please provide value in each input field");
    } else {
      if (!id) {
        firebase
          .firestore()
          .collection("samples")
          .doc()
          .set(state, (err) => {
            if (err) {
              alert(err);
            } else {
              alert("Sample Added Successfully");
            }
          });
      } else {
        fireDb.child(`samples/${id}`).set(state, (err) => {
          if (err) {
            alert(err);
          } else {
            alert("Sample Updated Successfully");
          }
        });
      }

      setTimeout(() => navigate("/dashboard/homefs"), 500);
    }
  };

  return (
    <form className="row g-3" onSubmit={handleSubmit}>
      <div className="row row-cols-4">
        <div className="col">
          <label htmlFor="country" className="form-label">
            Country
          </label>
          <select id="country" name="country" onChange={handleInputChange}>
            <option>Please Select</option>
            <option value="Andorra">Andorra</option>
            <option value="Afghanistan">Afghanistan</option>
            <option value="Anguilla">Anguilla</option>
            <option value="Albania">Albania</option>
            <option value="Armenia">Armenia</option>
            <option value="Angola">Angola</option>
            <option value="Antarctica">Antarctica</option>
            <option value="Argentina">Argentina</option>
            <option value="American Samoa">American Samoa</option>
            <option value="Austria">Austria</option>
            <option value="Aruba">Aruba</option>
            <option value="Alland Islands">Alland Islands</option>
            <option value="Azerbaijan">Azerbaijan</option>
            <option value="Barbados">Barbados</option>
            <option value="Bangladesh">Bangladesh</option>
            <option value="Belgium">Belgium</option>
            <option value="Burkina Faso">Burkina Faso</option>
            <option value="Bulgaria">Bulgaria</option>
            <option value="Bahrain">Bahrain</option>
            <option value="Burundi">Burundi</option>
            <option value="Benin">Benin</option>
            <option value="Saint Barthelemy">Saint Barthelemy</option>
            <option value="Bermuda">Bermuda</option>
            <option value="Brunei Darussalam">Brunei Darussalam</option>
            <option value="Bolivia">Bolivia</option>
            <option value="Brazil">Brazil</option>
            <option value="Bahamas">Bahamas</option>
            <option value="Bhutan">Bhutan</option>
            <option value="Bouvet Island">Bouvet Island</option>
            <option value="Botswana">Botswana</option>
            <option value="Belarus">Belarus</option>
            <option value="Belize">Belize</option>
            <option value="Switzerland">Switzerland</option>
            <option value="Cote d'Ivoire">Cote d'Ivoire</option>
            <option value="Cook Islands">Cook Islands</option>
            <option value="Chile">Chile</option>
            <option value="Cameroon">Cameroon</option>
            <option value="China">China</option>
            <option value="Colombia">Colombia</option>
            <option value="Costa Rica">Costa Rica</option>
            <option value="Cuba">Cuba</option>
            <option value="Cape Verde">Cape Verde</option>
            <option value="Curacao">Curacao</option>
            <option value="Christmas Island">Christmas Island</option>
            <option value="Cyprus">Cyprus</option>
            <option value="Czech Republic">Czech Republic</option>
            <option value="Djibouti">Djibouti</option>
            <option value="Denmark">Denmark</option>
            <option value="Dominica">Dominica</option>
            <option value="Algeria">Algeria</option>
            <option value="Ecuador">Ecuador</option>
            <option value="Estonia">Estonia</option>
            <option value="Egypt">Egypt</option>
            <option value="Western Sahara">Western Sahara</option>
            <option value="Eritrea">Eritrea</option>
            <option value="Spain">Spain</option>
            <option value="Ethiopia">Ethiopia</option>
            <option value="Finland">Finland</option>
            <option value="Fiji">Fiji</option>
            <option value="Faroe Islands">Faroe Islands</option>
            <option value="Gabon">Gabon</option>
            <option value="United Kingdom">United Kingdom</option>
            <option value="Grenada">Grenada</option>
            <option value="Georgia">Georgia</option>
            <option value="French Guiana">French Guiana</option>
            <option value="Guernsey">Guernsey</option>
            <option value="Ghana">Ghana</option>
            <option value="Gibraltar">Gibraltar</option>
            <option value="Greenland">Greenland</option>
            <option value="Gambia">Gambia</option>
            <option value="Guinea">Guinea</option>
            <option value="Guadeloupe">Guadeloupe</option>
            <option value="Equatorial Guinea">Equatorial Guinea</option>
            <option value="Greece">Greece</option>
            <option value="Guatemala">Guatemala</option>
            <option value="Guam">Guam</option>
            <option value="Guinea-Bissau">Guinea-Bissau</option>
            <option value="Guyana">Guyana</option>
            <option value="Hong Kong">Hong Kong</option>
            <option value="Honduras">Honduras</option>
            <option value="Croatia">Croatia</option>
            <option value="Haiti">Haiti</option>
            <option value="Hungary">Hungary</option>
            <option value="Indonesia">Indonesia</option>
            <option value="Ireland">Ireland</option>
            <option value="Israel">Israel</option>
            <option value="Isle of Man">Isle of Man</option>
            <option value="India">India</option>
            <option value="Iraq">Iraq</option>
            <option value="Iceland">Iceland</option>
            <option value="Italy">Italy</option>
            <option value="Jersey">Jersey</option>
            <option value="Jamaica">Jamaica</option>
            <option value="Jordan">Jordan</option>
            <option value="Kenya">Kenya</option>
            <option value="Kyrgyzstan">Kyrgyzstan</option>
            <option value="Cambodia">Cambodia</option>
            <option value="Kiribati">Kiribati</option>
            <option value="Comoros">Comoros</option>
            <option value="Korea, Republic of">Korea, Republic of</option>
            <option value="Kuwait">Kuwait</option>
            <option value="Cayman Islands">Cayman Islands</option>
            <option value="Kazakhstan">Kazakhstan</option>
            <option value="Lebanon">Lebanon</option>
            <option value="Saint Lucia">Saint Lucia</option>
            <option value="Liechtenstein">Liechtenstein</option>
            <option value="Sri Lanka">Sri Lanka</option>
            <option value="Liberia">Liberia</option>
            <option value="Lesotho">Lesotho</option>
            <option value="Lithuania">Lithuania</option>
            <option value="Luxembourg">Luxembourg</option>
            <option value="Latvia">Latvia</option>
            <option value="Libya">Libya</option>
            <option value="Morocco">Morocco</option>
            <option value="Monaco">Monaco</option>
            <option value="Montenegro">Montenegro</option>
            <option value="Madagascar">Madagascar</option>
            <option value="Marshall Islands">Marshall Islands</option>
            <option value="Mali">Mali</option>
            <option value="Myanmar">Myanmar</option>
            <option value="Mongolia">Mongolia</option>
            <option value="Macao">Macao</option>
            <option value="Martinique">Martinique</option>
            <option value="Mauritania">Mauritania</option>
            <option value="Montserrat">Montserrat</option>
            <option value="Malta">Malta</option>
            <option value="Mauritius">Mauritius</option>
            <option value="Maldives">Maldives</option>
            <option value="Malawi">Malawi</option>
            <option value="Mexico">Mexico</option>
            <option value="Malaysia">Malaysia</option>
            <option value="Mozambique">Mozambique</option>
            <option value="Namibia">Namibia</option>
            <option value="New Caledonia">New Caledonia</option>
            <option value="Niger">Niger</option>
            <option value="Norfolk Island">Norfolk Island</option>
            <option value="Nigeria">Nigeria</option>
            <option value="Nicaragua">Nicaragua</option>
            <option value="Netherlands">Netherlands</option>
            <option value="Norway">Norway</option>
            <option value="Nepal">Nepal</option>
            <option value="Nauru">Nauru</option>
            <option value="Niue">Niue</option>
            <option value="New Zealand">New Zealand</option>
            <option value="Oman">Oman</option>
            <option value="Panama">Panama</option>
            <option value="Peru">Peru</option>
            <option value="French Polynesia">French Polynesia</option>
            <option value="Papua New Guinea">Papua New Guinea</option>
            <option value="Philippines">Philippines</option>
            <option value="Pakistan">Pakistan</option>
            <option value="Poland">Poland</option>
            <option value="Pitcairn">Pitcairn</option>
            <option value="Puerto Rico">Puerto Rico</option>
            <option value="Portugal">Portugal</option>
            <option value="Palau">Palau</option>
            <option value="Paraguay">Paraguay</option>
            <option value="Qatar">Qatar</option>
            <option value="Reunion">Reunion</option>
            <option value="Romania">Romania</option>
            <option value="Serbia">Serbia</option>
            <option value="Russian Federation">Russian Federation</option>
            <option value="Rwanda">Rwanda</option>
            <option value="Saudi Arabia">Saudi Arabia</option>
            <option value="Solomon Islands">Solomon Islands</option>
            <option value="Seychelles">Seychelles</option>
            <option value="Sudan">Sudan</option>
            <option value="Sweden">Sweden</option>
            <option value="Singapore">Singapore</option>
            <option value="Saint Helena">Saint Helena</option>
            <option value="Slovenia">Slovenia</option>
            <option value="Slovakia">Slovakia</option>
            <option value="Sierra Leone">Sierra Leone</option>
            <option value="San Marino">San Marino</option>
            <option value="Senegal">Senegal</option>
            <option value="Somalia">Somalia</option>
            <option value="Suriname">Suriname</option>
            <option value="South Sudan">South Sudan</option>
            <option value="El Salvador">El Salvador</option>
            <option value="Swaziland">Swaziland</option>
            <option value="Chad">Chad</option>
            <option value="Togo">Togo</option>
            <option value="Thailand">Thailand</option>
            <option value="Tajikistan">Tajikistan</option>
            <option value="Tokelau">Tokelau</option>
            <option value="Timor-Leste">Timor-Leste</option>
            <option value="Turkmenistan">Turkmenistan</option>
            <option value="Tunisia">Tunisia</option>
            <option value="Tonga">Tonga</option>
            <option value="Turkey">Turkey</option>
            <option value="Tuvalu">Tuvalu</option>
            <option value="Ukraine">Ukraine</option>
            <option value="Uganda">Uganda</option>
            <option value="Uruguay">Uruguay</option>
            <option value="Uzbekistan">Uzbekistan</option>
            <option value="Venezuela">Venezuela</option>
            <option value="Vietnam">Vietnam</option>
            <option value="Vanuatu">Vanuatu</option>
            <option value="Wallis and Futuna">Wallis and Futuna</option>
            <option value="Samoa">Samoa</option>
            <option value="Kosovo">Kosovo</option>
            <option value="Yemen">Yemen</option>
            <option value="Mayotte">Mayotte</option>
            <option value="South Africa">South Africa</option>
            <option value="Zambia">Zambia</option>
            <option value="Zimbabwe">Zimbabwe</option>
          </select>
        </div>
        <div className="col">
          <label htmlFor="brand" className="form-label">
            Brand:
          </label>
          <select id="brand" name="brand" onChange={handleInputChange}>
            <option>Please Select</option>
            <option value="Acer">Acer</option>
            <option value="Alcatel">Alcatel</option>
            <option value="Allview">Allview</option>
            <option value="Amazon">Amazon</option>
            <option value="Amoi">Amoi</option>
            <option value="Apple">Apple</option>
            <option value="Archos">Archos</option>
            <option value="Asus">Asus</option>
            <option value="At&T">At&T</option>
            <option value="Benefon">Benefon</option>
            <option value="Benq">Benq</option>
            <option value="Benq-Siemens">Benq-Siemens</option>
            <option value="Bird">Bird</option>
            <option value="Blackberry">Blackberry</option>
            <option value="Blackview">Blackview</option>
            <option value="Blu">Blu</option>
            <option value="Bosch">Bosch</option>
            <option value="Bq">Bq</option>
            <option value="Casio">Casio</option>
            <option value="Cat">Cat</option>
            <option value="Celkon">Celkon</option>
            <option value="Chea">Chea</option>
            <option value="Coolpad">Coolpad</option>
            <option value="Dell">Dell</option>
            <option value="Emporia">Emporia</option>
            <option value="Energizer">Energizer</option>
            <option value="Ericsson">Ericsson</option>
            <option value="Eten">Eten</option>
            <option value="Fairphone">Fairphone</option>
            <option value="Fujitsu Siemens">Fujitsu Siemens</option>
            <option value="Garmin-Asus">Garmin-Asus</option>
            <option value="Gigabyte">Gigabyte</option>
            <option value="Gionee">Gionee</option>
            <option value="Google">Google</option>
            <option value="Haier">Haier</option>
            <option value="Honor">Honor</option>
            <option value="Hp">Hp</option>
            <option value="Htc">Htc</option>
            <option value="Huawei">Huawei</option>
            <option value="Icemobile">Icemobile</option>
            <option value="I-Mate">I-Mate</option>
            <option value="I-Mobile">I-Mobile</option>
            <option value="Infinix">Infinix</option>
            <option value="Innostream">Innostream</option>
            <option value="Inq">Inq</option>
            <option value="Intex">Intex</option>
            <option value="Jolla">Jolla</option>
            <option value="Karbonn">Karbonn</option>
            <option value="Kyocera">Kyocera</option>
            <option value="Lava">Lava</option>
            <option value="Leeco">Leeco</option>
            <option value="Lenovo">Lenovo</option>
            <option value="Lg">Lg</option>
            <option value="Maxon">Maxon</option>
            <option value="Maxwest">Maxwest</option>
            <option value="Mediatek">Mediatek</option>
            <option value="Meizu">Meizu</option>
            <option value="Micromax">Micromax</option>
            <option value="Microsoft">Microsoft</option>
            <option value="Mitac">Mitac</option>
            <option value="Mitsubishi">Mitsubishi</option>
            <option value="Modu">Modu</option>
            <option value="Morpho">Morpho</option>
            <option value="Motorola">Motorola</option>
            <option value="Mwg">Mwg</option>
            <option value="Nec">Nec</option>
            <option value="Neonode">Neonode</option>
            <option value="Niu">Niu</option>
            <option value="Nokia">Nokia</option>
            <option value="Nvidia">Nvidia</option>
            <option value="O2">O2</option>
            <option value="Oneplus">Oneplus</option>
            <option value="Oppo">Oppo</option>
            <option value="Orange">Orange</option>
            <option value="Palm">Palm</option>
            <option value="Panasonic">Panasonic</option>
            <option value="Pantech">Pantech</option>
            <option value="Parla">Parla</option>
            <option value="Philips">Philips</option>
            <option value="Plum">Plum</option>
            <option value="Posh">Posh</option>
            <option value="Positivo">Positivo</option>
            <option value="Prestigio">Prestigio</option>
            <option value="Qmobile">Qmobile</option>
            <option value="Qtek">Qtek</option>
            <option value="Qualcomm">Qualcomm</option>
            <option value="Razer">Razer</option>
            <option value="Realme">Realme</option>
            <option value="Sagem">Sagem</option>
            <option value="Samsung">Samsung</option>
            <option value="Sendo">Sendo</option>
            <option value="Sewon">Sewon</option>
            <option value="Sharp">Sharp</option>
            <option value="Siemens">Siemens</option>
            <option value="Sonim">Sonim</option>
            <option value="Sony">Sony</option>
            <option value="Sony Ericsson">Sony Ericsson</option>
            <option value="Spice">Spice</option>
            <option value="Tcl">Tcl</option>
            <option value="Tecno">Tecno</option>
            <option value="Tel.Me.">Tel.Me.</option>
            <option value="Telit">Telit</option>
            <option value="Thuraya">Thuraya</option>
            <option value="T-Mobile">T-Mobile</option>
            <option value="Toshiba">Toshiba</option>
            <option value="Ulefone">Ulefone</option>
            <option value="Unnecto">Unnecto</option>
            <option value="Vertu">Vertu</option>
            <option value="Verykool">Verykool</option>
            <option value="Vivo">Vivo</option>
            <option value="Vk Mobile">Vk Mobile</option>
            <option value="Vodafone">Vodafone</option>
            <option value="Wiko">Wiko</option>
            <option value="Wnd">Wnd</option>
            <option value="Xcute">Xcute</option>
            <option value="Xiaomi">Xiaomi</option>
            <option value="Xolo">Xolo</option>
            <option value="Yezz">Yezz</option>
            <option value="Yota">Yota</option>
            <option value="Yu">Yu</option>
            <option value="Zte">Zte</option>
          </select>
        </div>
        <div className="col">
          <label htmlFor="devTypes" className="form-label">
            Type of Device:
          </label>
          <select
            className="dropdown"
            id="hwtype"
            name="hwtype"
            onChange={handleInputChange}
          >
            <option>Please Select</option>
            <option value="Mobile">Mobile</option>
            <option value="Tablet">Tablet</option>
            <option value="Smartwatch">Smartwatch</option>
            <option value="Modem">Modem</option>
            <option value="Router">Router</option>
          </select>
        </div>

        <div className="col">
          <label htmlFor="simCard" className="form-label">
            SIM Card:
          </label>
          <select
            className="dropdown"
            id="simCard"
            name="simCard"
            onChange={handleInputChange}
          >
            <option>Please Select</option>
            <option value="Mini SIM">Mini SIM</option>
            <option value="Micro SIM">Micro SIM</option>
            <option value="Nano SIM">Nano SIM</option>
            <option value="e SIM">e SIM"</option>
          </select>
        </div>
        <div className="col">
          <label htmlFor="tNetworks" className="form-label">
            Networks:
          </label>
          <select
            className="dropdown"
            id="tNetworks"
            name="tNetworks"
            onChange={handleInputChange}
          >
            <option>Please Select</option>
            <option value="2G">2G</option>
            <option value="3G">3G</option>
            <option value="4G">4G</option>
            <option value="5G">5G</option>
            <option value="6G">6G</option>
          </select>
        </div>
        <div className="col">
          <label htmlFor="slots" className="form-label">
            Slots:
          </label>
          <select
            className="dropdown"
            id="slots"
            name="slots"
            onChange={handleInputChange}
          >
            <option>Please Select</option>
            <option value="Single SIM">Single SIM</option>
            <option value="Dual SIM">Dual SIM</option>
            <option value="Triple SIM">Triple SIM</option>
            <option value="Quad SIM">Quad SIM</option>
          </select>
        </div>
        <div className="col">
          <label htmlFor="os" className="form-label">
            OS:
          </label>
          <select
            className="dropdown"
            id="os"
            name="os"
            onChange={handleInputChange}
          >
            <option>Please Select</option>
            <option value="Android">Android</option>
            <option value="iOS">iOS</option>
            <option value="Windows Phone">Windows Phone</option>
            <option value="Symbian">Symbian</option>
            <option value="RIM">RIM</option>
            <option value="Bada">Bada</option>
            <option value="Firefox">Firefox</option>
          </select>
        </div>
        <div className="col">
          <label htmlFor="hwtype" className="form-label">
            HW type:
          </label>
          <select
            className="dropdown"
            id="devTypes"
            name="devTypes"
            onChange={handleInputChange}
          >
            <option>Please Select</option>
            <option value="Unsigned">Unsigned</option>
            <option value="Signed">Signed</option>
          </select>
        </div>
        <div className="col">
          <label htmlFor="model" className="form-label">
            Model:
          </label>
          <input
            className="form-control"
            type="text"
            id="model"
            name="model"
            placeholder="Your model..."
            value={model || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="col">
          <label htmlFor="serialNumber" className="form-label">
            Serial Number:
          </label>
          <input
            className="form-control"
            type="text"
            id="serialNumber"
            name="serialNumber"
            placeholder="Your serialNumber..."
            value={serialNumber || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="col">
          <label htmlFor="imei1" className="form-label">
            IMEI 1:
          </label>
          <input
            type="text"
            id="imei1"
            name="imei1"
            placeholder="Your imei1..."
            value={imei1 || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="col">
          <label htmlFor="imei2" className="form-label">
            IMEI 2:
          </label>
          <input
            type="text"
            id="imei2"
            name="imei2"
            placeholder="Your imei2..."
            value={imei2 || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="col">
          <label htmlFor="hwtype" className="form-label">
            Customer Sample Manager:
          </label>
          <select
            className="dropdown"
            id="custsamplmanag"
            name="custsamplmanag"
            onChange={handleInputChange}
          >
            <option>Please Select</option>
            <option value="john.snow@acme.com">john.snow@acme.com</option>
          </select>
        </div>
        <div className="col">
          <label htmlFor="phoneNumber" className="form-label">
            Phone Number:
          </label>
          <input
            type="number"
            id="phoneNumber"
            name="phoneNumber"
            placeholder="Your phoneNumber ..."
            value={phoneNumber || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-md-3">
          <label htmlFor="location" className="form-label">
            Location:
          </label>
          <input
            className="form-control"
            type="text"
            id="location"
            name="location"
            placeholder="Your location..."
            value={location || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="col">
          <label htmlFor="receiveDate" className="form-label">
            Receive Date:
          </label>
          <input
            className="form-control"
            type="datetime-local"
            id="receiveDate"
            name="receiveDate"
            // value={receiveDate || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="col">
          <label htmlFor="lastmanag" className="form-label">
            Last Sample Manager::
          </label>
          <select
            className="dropdown"
            id="lastmanag"
            name="lastmanag"
            onChange={handleInputChange}
          >
            <option>Please Select</option>
            <option value="jack.sparrow@xpto.com">jack.sparrow@xpto.com</option>
          </select>
        </div>
        <div className="col">
          <label htmlFor="lastmanag" className="form-label">
            Sample Manager:
          </label>
          <select
            className="dropdown"
            id="samplmanag"
            name="samplmanag"
            onChange={handleInputChange}
          >
            <option>Please Select</option>
            <option value="jack.sparrow@xpto.com">jack.sparrow@xpto.com</option>
          </select>
        </div>
        <div className="col-md-6">
          <label htmlFor="comments" className="form-label">
            Comments:
          </label>
          <input
            type="text"
            id="comments"
            name="comments"
            placeholder="Your Contact No. ..."
            value={comments || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="col">
          <input type="submit" value={id ? "Update" : "Save"} />
        </div>
      </div>
    </form>
  );
}

export default AddEditFS;
