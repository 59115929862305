import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Container, Table, Form, Col } from "react-bootstrap";
import { collection, doc, getDocs, query, where } from "firebase/firestore";
import { db, auth } from "../../../../contexts/FirebaseAuthContext";
import fetchDataLost from "../HomeSample/fetchDataLost";
import { Link } from "react-router-dom";
import { useFilters, useGlobalFilter, useSortBy, useTable } from "react-table"; // import fetchData

const HomeFSOS = () => {
  const [data, setData] = useState([]);
  const [loggedUser, setLoggedUser] = useState(null);
  const [loading, setLoading] = useState(true); // <- added loading state

  useEffect(() => {
    const getData = async () => {
      const unsubscribe = auth.onAuthStateChanged(async (user) => {
        const dataUsers = query(
          collection(db, "users"),
          where("uid", "==", user.uid)
        );
        const resultUsers = await getDocs(dataUsers);
        const loggedUser = resultUsers.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        const role = loggedUser[0]?.role;
        const currentUser = loggedUser[0]?.email;

        if (role && currentUser) {
          const fetchedData = await fetchDataLost(role, currentUser);
          setData(fetchedData);
          setLoggedUser(loggedUser[0]); // <- set the loggedUser state
          setLoading(false); // <- set loading to false after user is loaded
        }
      });

      return () => {
        unsubscribe();
      };
    };
    getData();

  }, []);




  const formatDate = (date) => {
    if (!date || isNaN(date)) return ""; // Check if date is undefined, null, or invalid

    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    };

    const formattedDate = new Date(date?.seconds * 1000).toLocaleString("en-US", options);
    return formattedDate;
  };

  const sortTypes = React.useMemo(() => ({
    reqOSDate: (row1, row2) => {
      const date1 = new Date(row1.values.reqOSDate?.seconds * 1000);
      const date2 = new Date(row2.values.reqOSDate?.seconds * 1000);
      return date1.getTime() - date2.getTime();
    },
    osDate: (row1, row2) => {
      const date1 = new Date(row1.values.osDate?.seconds * 1000);
      const date2 = new Date(row2.values.osDate?.seconds * 1000);
      return date1.getTime() - date2.getTime();
    },
  }), []);



  function DateColumnFilter({
                              column: { filterValue = [], setFilter },
                            }) {
    return (
      <div className="d-flex flex-column mt-2">
        <Form.Control
          value={filterValue[0] || ""}
          type="date"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [
              val ? new Date(new Date(val).setUTCHours(0, 0, 0, 0)).toISOString().slice(0, 10) : undefined,
              old[1],
            ]);
          }}
          onClick={(e) => e.stopPropagation()} // Prevents accidental sorting
          placeholder="Min"
          style={{ width: "160px" }}
        />
        <Form.Control
          value={filterValue[1] || ""}
          type="date"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [
              old[0],
              val ? new Date(new Date(val).setUTCHours(23, 59, 59, 999)).toISOString().slice(0, 10) : undefined,
            ]);
          }}
          onClick={(e) => e.stopPropagation()} // Prevents accidental sorting
          placeholder="Max"
          style={{
            width: "160px",
            marginTop: "10px",
          }}
        />
      </div>
    );
  }

  function DefaultColumnFilter({
                                 column: { filterValue, preFilteredRows, setFilter },
                               }) {
    const count = preFilteredRows.length;
    return (
      <Form.Control
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={`Search ${count} records...`}
        className="mt-2"
        onClick={e => e.stopPropagation()}
      />
    );
  }

  function SelectColumnFilter({
                                column: { filterValue, setFilter, preFilteredRows, id },
                              }) {
    const options = React.useMemo(() => {
      const optionsSet = new Set();
      preFilteredRows.forEach(row => {
        optionsSet.add(row.values[id]);
      });
      return [...optionsSet.values()];
    }, [id, preFilteredRows]);

    return (
      <Form.Select
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined);
        }}
        onClick={e => e.stopPropagation()}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </Form.Select>
    );
  }


  const dateFilterMethod = (rows, id, filterValue) => {
    return rows.filter(row => {
      const dateSeconds = row.values[id]?.seconds;
      if (dateSeconds === undefined || dateSeconds === null) {
        // if row value is null or undefined, filter out this row.
        return false;
      }

      const rowValue = new Date(dateSeconds * 1000);

      let startDate = filterValue[0] ? new Date(filterValue[0]) : null;
      let endDate = filterValue[1] ? new Date(filterValue[1]) : null;

      if (startDate) startDate.setUTCHours(0, 0, 0, 0);  // Set start time to the start of the day
      if (endDate) endDate.setUTCHours(23, 59, 59, 999);  // Set end time to the end of the day

      // Compare UTC dates
      return (!startDate || rowValue >= startDate) && (!endDate || rowValue <= endDate);
    });
  };




  const ColumnFilteringTable = ({ columns, data }) => {
    const filterTypes = React.useMemo(
      () => ({
        // Or, override the default text filter to use
        // "startWith"
        text: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
        includes: (rows, id, filterValue) => {
          return rows.filter(row => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase())
              : true;
          });
        },
        // Add date filter function
        date: dateFilterMethod,
      }),
      []
    );


    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      setGlobalFilter,
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        filterTypes,
        sortTypes // add this line
      },
      useFilters,
      useGlobalFilter,
      useSortBy // add this line
    );


    const { globalFilter } = state; // Get the global filter value from the state

    return (
      <Card>
        <Card.Header>
          <Card.Title>Search</Card.Title>
          <div className="mt-2">
            <Form.Group as={Col} md="2"> {/* Adjust the column size as needed */}
              <Form.Control
                className="form-control"
                value={globalFilter || ""}
                onChange={(e) => setGlobalFilter(e.target.value || undefined)}
                placeholder="Search all columns..."
              />
            </Form.Group>
          </div>
        </Card.Header>
        <Card.Body>
          <Table striped bordered {...getTableProps()}>
            <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
            {column.isSorted
              ? column.isSortedDesc
                ? ' 🔽'
                : ' 🔼'
              : ''}
          </span>
                    {/* Render the columns filter UI */}
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    );
  };

  const { role, currentUser } = loggedUser || {}; // Destructure role and currentUser from loggedUser

  // console.log(role, currentUser, loggedUser);

  if (loading) {
    return <div>Loading...</div>; // Render some loading text or spinner
  }


  const tableColumns = [
  {
    Header: "Country",
    accessor: "country",
    Filter: SelectColumnFilter,
    filter: "includes",
  },
  {
    Header: "User",
    accessor: "samplmanag",
    Filter: SelectColumnFilter,
    filter: "includes",
  },
  // {
  //   Header: "User Name",
  //   accessor: "samplmanagname",
  //   Filter: SelectColumnFilter,
  //   filter: "includes",
  // },
  {
    Header: "Customers",
    accessor: "customers",
    Filter: SelectColumnFilter,
    filter: "includes",
  },
  {
    Header: "Brand",
    accessor: "brand",
    Filter: SelectColumnFilter,
    filter: "includes",
  },
  {
    Header: "Model",
    accessor: "model",
    Filter: DefaultColumnFilter,
    filter: "includes",
  },
  {
    Header: "Serial Number",
    accessor: "serialNumber",
    Filter: DefaultColumnFilter,
    filter: "includes",
  },
  {
    Header: "Imei 1",
    accessor: "imei1",
    Filter: DefaultColumnFilter,
    filter: "includes",
  },
  {
    Header: "Imei 2",
    accessor: "imei2",
    Filter: DefaultColumnFilter,
    filter: "includes",
  },
  {
    Header: "Status",
    accessor: "status",
    Filter: SelectColumnFilter,
    filter: "includes",
  },
  {
    Header: "Request Lost To",
    accessor: "inOSapprby",
    Filter: SelectColumnFilter,
    filter: "includes",
  },
  {
    Header: "Request Lost Date",
    accessor: "reqOSDate",
    Cell: ({ value }) => formatDate(value),
    Filter: DateColumnFilter,
    filter: "date",
    sortType: 'reqOSDate',
  },
  // {
  //   Header: "Lost",
  //   accessor: "osDate",
  //   Cell: ({ value }) => formatDate(value),
  //   Filter: DateColumnFilter,
  //   filter: "date",
  //   sortType: 'osDate',
  // },
  {
    Header: "Actions",
    accessor: "actions",
    disableFilters: true,
    Cell: ({ row }) => {
      if ((role === "admin" ||
        auth.currentUser.email  === row.original.samplmanag) &&
        "Returned" !== row.original.status &&
        "In Return" !== row.original.status &&
        "Request Lost" !== row.original.status &&
        "Lost" !== row.original.status
      ) {
        return (
          <Link to={`/private/outofservicefs/${row.original.id}`}>
            <button className="btn btn-transfer">Request Lost</button>
          </Link>
        );
      }
      if ((role === "admin" ||
        auth.currentUser.email === row.original.inOSapprby) &&
        row.original.status === "Request Lost"
      ) {
        return (
          <Link to={`/private/outofservicefs/osacknowledgefs/${row.original.id}`}>
            <button className="btn btn-acknowledge">
              Lost Ack
            </button>
          </Link>
        );
      }
      return null;
    },
  },
];

  return (
    <React.Fragment>
        <Helmet title="Sample Management System" />
        <Container fluid className="p-0">
          <h1 className="h3 mb-3">Sample Management System - Lost Sample</h1>
      <ColumnFilteringTable columns={tableColumns} data={data} />
        </Container>
    </React.Fragment>
  );
};

export default HomeFSOS;
