import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  Accordion,
  Badge,
  Button,
  Card,
  Col,
  Container,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";

import {
  SIDEBAR_POSITION,
  SIDEBAR_BEHAVIOR,
  LAYOUT,
  THEME,
} from "../../constants";

import useTheme from "../../hooks/useTheme";
import useSidebar from "../../hooks/useSidebar";
import useLayout from "../../hooks/useLayout";

import { ReactComponent as Logo } from "../../assets/img/logo.svg";

import screenshotWorld from "../../assets/img/screenshots/WoldMap-TT-50countriesREV-DEZ20.png";

const Navigation = () => (
  <Navbar expand="md" className="landing-navbar">
    <Container>
      <Navbar.Brand className="landing-brand" href="/">
        <Logo /> Sample Management System{" "}
      </Navbar.Brand>
      <Nav className="ms-auto" navbar>
        <Nav.Item className="d-none d-md-inline-block">
          <Nav.Link href="/auth/Sign-In" active className="text-lg px-lg-3">
            Sign-In
          </Nav.Link>
        </Nav.Item>
      </Nav>
      {/*<Button*/}
      {/*  href="/auth/Sign-Up"*/}
      {/*  target="_blank"*/}
      {/*  rel="noopener noreferrer"*/}
      {/*  variant="success"*/}
      {/*  className="ms-2"*/}
      {/*  size="lg"*/}
      {/*>*/}
      {/*  Sign-Up*/}
      {/*</Button>*/}
    </Container>
  </Navbar>
);

const Intro = () => (
  <section className="landing-intro landing-bg pt-5 pt-lg-6 pb-5 pb-lg-7">
    <Container className="landing-intro-content">
      <Row className="align-items-center">
        <Col lg="5" className="mx-auto">
          <h1 className="my-4">
            TADTELMAX SMS Fully-featured Sample Inventory Control Admin & Dashboard
          </h1>

          <p className="text-lg">
            The objective besides effective control is that we can better
            discuss with our customer the deadlines of activities, so it must be
            updated.
          </p>

          <div className="my-4">
            <div className="d-inline-block me-3">
              <h2 className="text-dark">+50 countries</h2>
            </div>
            <div className="d-inline-block me-3">
              <h2 className="text-dark">+20 Years of Background</h2>
            </div>
          </div>
          <div className="my-4">
            <a
              href="https://tadtelmax.com/"
              target="_blank"
              rel="noreferrer"
              className="btn btn-outline-danger btn-lg me-1"
            >
              TADTELMAX
            </a>
          </div>
        </Col>
        <Col lg="7" className="d-none d-lg-flex mx-auto text-center">
          <div className="landing-intro-screenshot pb-3">
            <img
              src={screenshotWorld}
              alt="Dark/Light World Map"
              className="img-fluid"
            />
          </div>
        </Col>
        <h2 className="h1 mb-3">Solutions for a Moving World</h2>
      </Row>
    </Container>
  </section>
);

const Footer = () => (
  <section className="landing-footer py-4">
    <Container className="text-center landing-footer-container">
      <Row>
        <Col md="9" lg="8" xl="6" className="mx-auto">
          <p className="text-lg">© 2023 TADTELMAX All Rights Reserved</p>
        </Col>
      </Row>
    </Container>
  </section>
);

const Landing = () => {
  const { setTheme } = useTheme();
  const { setPosition, setBehavior } = useSidebar();
  const { setLayout } = useLayout();

  useEffect(() => {
    setTheme(THEME.DEFAULT);
    setPosition(SIDEBAR_POSITION.LEFT);
    setBehavior(SIDEBAR_BEHAVIOR.STICKY);
    setLayout(LAYOUT.FLUID);
  }, []);

  return (
    <React.Fragment>
      <Navigation />
      <Intro />
      <Footer />
    </React.Fragment>
  );
};

export default Landing;
