import { db } from "../../../../contexts/FirebaseAuthContext";
import { collection, getDocs, query, where } from "firebase/firestore";

const fetchDataTransfer = async (role, currentUser) => {
  const disallowedStatuses = ["cdReturned", "Lost"];

  switch (role) {
    case "admin":
      // Fetch all documents for admin excluding disallowed statuses
      const adminSnapshot = await getDocs(
        query(
          collection(db, "samples"),
          where("status", "not-in", disallowedStatuses)
        )
      );
      return adminSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

    case "supervisor":
      const supervisedUsersResult = await getDocs(
        query(
          collection(db, "users"),
          where("supervisor", "==", currentUser)
        )
      );
      const supervisedUserEmails = supervisedUsersResult.docs.map(
        (doc) => doc.data().email
      );

      let supervisorSamples = [];
      for (const email of supervisedUserEmails) {
        const userSamplesSnapshot = await getDocs(
          query(
            collection(db, "samples"),
            where("samplmanag", "==", email),
            where("status", "not-in", disallowedStatuses)
          )
        );
        const userSamples = userSamplesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        supervisorSamples.push(...userSamples);
      }

      const ownSamplesSnapshot = await getDocs(
        query(
          collection(db, "samples"),
          where("samplmanag", "==", currentUser),
          where("status", "not-in", disallowedStatuses)
        )
      );
      const ownSamples = ownSamplesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      supervisorSamples.push(...ownSamples);

      const inTransitToSnapshot = await getDocs(
        query(
          collection(db, "samples"),
          where("inTransitTo", "==", currentUser),
          where("status", "not-in", disallowedStatuses)
        )
      );
      const inTransitToSamples = inTransitToSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      supervisorSamples.push(...inTransitToSamples);

      const uniqueSupervisorSamples = Array.from(new Set(supervisorSamples.map(a => a.id)))
        .map(id => supervisorSamples.find(a => a.id === id));

      return uniqueSupervisorSamples;

    case "user":
      const samplmanagSnapshot = await getDocs(
        query(
          collection(db, "samples"),
          where("samplmanag", "==", currentUser),
          where("status", "not-in", disallowedStatuses)
        )
      );

      const userInTransitToSnapshot = await getDocs(
        query(
          collection(db, "samples"),
          where("inTransitTo", "==", currentUser),
          where("status", "not-in", disallowedStatuses)
        )
      );

      const results = [
        ...samplmanagSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
        ...userInTransitToSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
      ];

      const uniqueResults = Array.from(new Set(results.map(a => a.id)))
        .map(id => results.find(a => a.id === id));

      return uniqueResults;

    default:
      return [];
  }
};

export default fetchDataTransfer;

