import React, { useState } from "react";
import Select from "react-select";
import Modals from "./Modal";
import { Col, Form, Button } from "react-bootstrap";
import DropSelector from "./useDropbox";
function SimTypeSelector(props) {
  const simCard = [
    { value: "Mini-SIM (regular size)", label: "Mini-SIM (regular size)" },
    { value: "Micro-SIM", label: "Micro-SIM" },
    { value: "Nano-SIM", label: "Nano-SIM" },
    { value: "e-SIM", label: "e-SIM" },
    { value: "Nano SIM, e-SIM", label: "Nano SIM, e-SIM" },
  ];

  const [group, setGroup] = useState(null);
  const [show, setShow] = useState(false);

  const simType = (value) => {
    console.log("check", value);
  };
  return (
    <Form.Group
      as={Col}
      md={props.isedit ? "3" : ""}
      controlId="validationFormik04"
    >
      <Form.Label>SIM Card Type</Form.Label>
      <DropSelector
        DBModel="simCard"
        placeholder="Sim Card Types"
        title="Sim Card Types"
        name="simCard"
        id="simCard"
        isedit={props.isedit}
        value={props.value}
        setFieldValue={(newvalue) => {
          props.setFieldValue(newvalue);
        }}
        valueChange={props.valueChange}
      />
    </Form.Group>
  );
}
export default SimTypeSelector;
