import React from "react";
import { Card } from "react-bootstrap";
import AddSampleForm from "./AddSampleForm";

export const AddSample = () => {
  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5">Add New Sample Form</Card.Title>
        <h6 className="card-subtitle text-muted">Please add new sample</h6>
      </Card.Header>
      <Card.Body>
        <AddSampleForm />
      </Card.Body>
    </Card>
  );
};
