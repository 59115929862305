import { useState } from "react";
import { Col, Form } from "react-bootstrap";
import DropSelector from "./useDropbox";

function OSSelector(props) {
  const os = [
    { value: "Android", label: "Android" },
    { value: "iOS", label: "iOS" },
    { value: "Windows Phone", label: "Windows Phone" },
    { value: "Symbian", label: "Symbian" },
    { value: "RIM", label: "RIM" },
    { value: "Bada", label: "Bada" },
    { value: "Firefox", label: "Firefox" },
  ];
  const [show, setShow] = useState(false);
  return (
    <Form.Group
      as={Col}
      md={props.isedit ? "3" : ""}
      controlId="validationFormik04"
    >
      <Form.Label>Operation System</Form.Label>
      <DropSelector
        placeholder="os"
        name="os"
        id="os"
        title="OS Model"
        DBModel="osModel"
        isedit={props.isedit}
        value={props.value}
        valueChange={props.valueChange}
      />
    </Form.Group>
  );
}
export default OSSelector;
