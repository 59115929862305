import { db } from "../../../../contexts/FirebaseAuthContext";
import {
  collection,
  getDocs,
  query,
  where
} from "firebase/firestore";

const disallowedStatuses = [
  "In Transit",
  "Returned",
  "Request Lost",
  "Lost"
];

const fetchDataReturn = async (role, currentUser) => {
  const sortResults = (results) => {
    return results.sort((a, b) => {
      const dateA = new Date(a.inReturnDate || a.returnedDate);
      const dateB = new Date(b.inReturnDate || b.returnedDate);
      return dateB - dateA; // sort in descending order
    });
  };

  try {
    switch (role) {
      case "admin":
        const adminQuery = query(
          collection(db, "samples"),
          where("status", "not-in", disallowedStatuses)
        );
        const adminSnapshot = await getDocs(adminQuery);
        return sortResults(
          adminSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );

      case "supervisor":
        const supervisedUsersQuery = query(
          collection(db, "users"),
          where("supervisor", "==", currentUser)
        );
        const supervisedUsersSnapshot = await getDocs(supervisedUsersQuery);
        const supervisedUserEmails = supervisedUsersSnapshot.docs.map(
          (doc) => doc.data().email
        );

        let supervisorSamples = [];
        for (const email of supervisedUserEmails) {
          const samplesQuery = query(
            collection(db, "samples"),
            where("samplmanag", "==", email),
            where("status", "not-in", disallowedStatuses)
          );
          const samplesSnapshot = await getDocs(samplesQuery);
          const userSamples = samplesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          }));
          supervisorSamples.push(...sortResults(userSamples));
        }

        const ownSamplesQuery = query(
          collection(db, "samples"),
          where("samplmanag", "==", currentUser),
          where("status", "not-in", disallowedStatuses)
        );
        const ownSamplesSnapshot = await getDocs(ownSamplesQuery);
        const ownSamples = ownSamplesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        supervisorSamples.push(...sortResults(ownSamples));

        const inTransitToQuery = query(
          collection(db, "samples"),
          where("inReturnTo", "==", currentUser),
          where("status", "not-in", disallowedStatuses)
        );
        const inTransitToSnapshot = await getDocs(inTransitToQuery);
        const inTransitToSamples = inTransitToSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        supervisorSamples.push(...sortResults(inTransitToSamples));

        const uniqueSupervisorSamples = Array.from(
          new Set(supervisorSamples.map((a) => a.id))
        ).map((id) => supervisorSamples.find((a) => a.id === id));

        return sortResults(uniqueSupervisorSamples);

      case "user":
        const userQueries = [
          query(
            collection(db, "samples"),
            where("samplmanag", "==", currentUser),
            where("status", "not-in", disallowedStatuses)
          ),
          query(
            collection(db, "samples"),
            where("inReturnTo", "==", currentUser),
            where("status", "not-in", disallowedStatuses)
          )
        ];

        const results = [];
        for (const userQuery of userQueries) {
          const userSnapshot = await getDocs(userQuery);
          const userSamples = userSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          }));
          results.push(...sortResults(userSamples));
        }

        const uniqueResults = Array.from(new Set(results.map((a) => a.id))).map(
          (id) => results.find((a) => a.id === id)
        );

        return sortResults(uniqueResults);

      default:
        return [];
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export default fetchDataReturn;

