import { db } from "../../../../contexts/FirebaseAuthContext";
import { collection, getDocs, query, where } from "firebase/firestore";

const disallowedStatuses = ["In Return", "Returned", "Request Lost", "Lost"];

const sortResults = (results) => {
  return results.sort((a, b) => {
    const dateA = new Date(a.inTransitDate || a.inUseDate);
    const dateB = new Date(b.inTransitDate || b.inUseDate);
    return dateB - dateA;
  });
};

const getSamplesByCriteria = async (criteria) => {
  const samplesSnapshot = await getDocs(query(collection(db, "samples"), ...criteria));
  return samplesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

const fetchDataTransfer = async (role, currentUser) => {
  let results = [];

  switch (role) {
    case "admin":
      results = await getSamplesByCriteria([where("status", "not-in", disallowedStatuses)]);
      break;

    case "supervisor":
      const supervisedUsersResult = await getDocs(
        query(collection(db, "users"), where("supervisor", "==", currentUser))
      );
      const supervisedUserEmails = supervisedUsersResult.docs.map(doc => doc.data().email);

      const queries = [
        ...supervisedUserEmails.map(email =>
          [where("samplmanag", "==", email), where("status", "not-in", disallowedStatuses)]
        ),
        [where("samplmanag", "==", currentUser), where("status", "not-in", disallowedStatuses)],
        [where("inTransitTo", "==", currentUser), where("status", "not-in", disallowedStatuses)]
      ];

      const samplesResults = await Promise.all(queries.map(getSamplesByCriteria));
      results = samplesResults.flat();
      break;

    case "user":
      const managedSamples = await getSamplesByCriteria([
        where("samplmanag", "==", currentUser),
        where("status", "not-in", disallowedStatuses)
      ]);

      const inTransitSamples = await getSamplesByCriteria([
        where("inTransitTo", "==", currentUser),
        where("status", "not-in", disallowedStatuses)
      ]);

      results = [...managedSamples, ...inTransitSamples];
      break;

    default:
      break;
  }

  return sortResults(results);
};

export default fetchDataTransfer;

