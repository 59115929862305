import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/compat";
import { useEffect } from "react";

const IDLE_TIMEOUT = 1000 * 60 * 15; // 15 minutes
const DEBOUNCE_DELAY = 500; // 500 milliseconds
const LOG_INTERVAL = 1000 * 60; // 1 minute (you can change this to desired logging frequency)

export default function IdleTimerComponent() {
  const navigate = useNavigate();

  const handleOnIdle = (event) => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        navigate("/");
      })
      .catch((error) => {});
  };

  const handleOnActive = (event) => {};

  const handleOnAction = (event) => {};

  const { getRemainingTime } = useIdleTimer({
    timeout: IDLE_TIMEOUT,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: DEBOUNCE_DELAY,
  });

  // Logging the remaining time every minute (or the interval you choose)
  useEffect(() => {
    const logRemainingTime = setInterval(() => {
      console.log("Remaining time to idle:", getRemainingTime() / 1000, "seconds");
    }, LOG_INTERVAL);

    return () => clearInterval(logRemainingTime); // Cleanup on component unmount
  }, [getRemainingTime]);

  return null;
}
