import React, { useState, useEffect } from "react";
import { db } from "../../../config";
import firebase from "firebase/compat";
import { collection, getDocs, query, where } from "firebase/firestore";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import { duplicates2here } from "../../../contexts/FirebaseAuthContext";
import Geocode from "react-geocode";
import { saveAs } from "file-saver";

import { Card, Row, Col } from "react-bootstrap";

async function ux(lat, lng, address, rowObject) {
  document.getElementById("upxlsx").disabled = true;
  for (let j = 0; j < rowObject.length; j++) {
    console.log("adding sample", j + 1);
    await duplicates2here(lat, lng, address, rowObject[j]);
  }
  alert("upload action completed");
  document.getElementById("upxlsx").disabled = false;
}

const ReportDB = () => {
  const [data, setData] = useState([]);
  const [address, setAddress] = useState("default");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(displayLocationInfo);
  }

  async function displayLocationInfo(position) {
    if (position) {
      setLat(position.coords.latitude);
      setLng(position.coords.longitude);
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
      Geocode.setLanguage("en");
      Geocode.setRegion("es");
      Geocode.setLocationType("ROOFTOP");
      Geocode.enableDebug();

      Geocode.fromLatLng(
        position.coords.latitude,
        position.coords.longitude
      ).then(
        (response) => {
          setAddress(response.results[0].formatted_address);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  useEffect(() => {
    const getData = async () => {
      firebase.auth().onAuthStateChanged(async (user) => {
        const dataUsers = query(
          collection(db, "users"),
          where("uid", "==", user.uid)
        );
        const resultUsers = await getDocs(dataUsers);
        var loggedUser = resultUsers.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        var dataCollectionRef = collection(db, "samples");
        const result = await getDocs(dataCollectionRef);
        var supervisorarray = [];
        var newarray = new Object();
        result.docs.map((doc) => {
          if (loggedUser[0].role === "admin") {
            newarray = doc.data();
            newarray["id"] = doc.id;
            supervisorarray.push(newarray);
          } else if (loggedUser[0].role === "user") {
            if (doc.data()["samplmanag"] === loggedUser[0].email) {
              newarray = doc.data();
              newarray["id"] = doc.id;
              supervisorarray.push(newarray);
            }
          } else if (loggedUser[0].role === "supervisor") {
            if (
              doc.data()["supervisor"] === loggedUser[0].email ||
              doc.data()["samplmanag"] === loggedUser[0].email
            ) {
              newarray = doc.data();
              newarray["id"] = doc.id;
              supervisorarray.push(newarray);
            }
          }
        });
        setData(supervisorarray);
      });
    };
    getData();
  }, []);

  function uploadExcel() {
    let selectedfile = document.getElementById("fileElem").files[0];

    if (selectedfile !== null && selectedfile !== undefined) {
      let p = new Promise((resolve, reject) => {
        let data;
        let workbook;
        let rowObject;

        if (selectedfile !== null) {
          //console.log(selectedfile);

          document.getElementById("upxlsx").disabled = true;
          document.getElementById("fileElem").disabled = true;
          let filereader = new FileReader();
          filereader.readAsBinaryString(selectedfile);
          filereader.onload = (Event) => {
            data = Event.target.result;

            workbook = XLSX.read(data, { type: "binary" });

            var what_sheet = "no sheet";

            workbook.SheetNames.forEach((sheet) => {
              what_sheet = sheet;
              rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
            });

            //console.log("what sheet here:", what_sheet);

            if (what_sheet !== "no sheet") {
              const ws = workbook.Sheets["Sheet1"];

              var headers = [];
              var range = XLSX.utils.decode_range(ws["!ref"]);
              var C,
                R = range.s.r;
              for (C = range.s.c; C <= range.e.c; ++C) {
                var cell = ws[XLSX.utils.encode_cell({ c: C, r: R })];

                var hdr = "UNKNOWN " + C;
                if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
                headers.push(hdr);
              }
              ux(lat, lng, address, rowObject);
            }
            resolve("yo");
          };
        }
      });
      p.then(() => {
        document.getElementById("upxlsx").disabled = false;
        document.getElementById("fileElem").disabled = false;
      }).catch((error) => {
        document.getElementById("upxlsx").disabled = false;
        document.getElementById("fileElem").disabled = false;
      });
    }
  }

  function getjson() {
    let myJsonString = JSON.stringify(data);
    //console.log(myJsonString);
    let blob = new Blob([myJsonString], {
      type: "application/json",
    });
    saveAs(blob, "file.json");
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5">Sample Management System</Card.Title>
        <h6 className="card-subtitle text-muted">GET AND POST</h6>
      </Card.Header>
      <Row
        style={{
          margin: "1em",
        }}
      >
        <Col lg="2" md="2" sm="3" xs="4">
          <input type="file" id="fileElem" accept=".xls,.xlsx,.csv" />
          <button
            style={{
              color: "#fff",
              border: "none",
              backgroundColor: "blue",
              padding: "0.5em 2em",
              borderRadius: "3px",
            }}
            id="upxlsx"
            onClick={uploadExcel}
          >
            UPLOAD EXCEL DATA
          </button>
        </Col>
        <Col lg="2" md="2" sm="3" xs="4">
          <CSVLink
            style={{
              color: "#fff",
              backgroundColor: "green",
              padding: "0.5em 2em",
              borderRadius: "3px",
            }}
            data={data}
          >
            CSV
          </CSVLink>
        </Col>

        <Col lg="2" md="2" sm="3" xs="4">
          <button
            style={{
              color: "#fff",
              border: "none",
              backgroundColor: "orange",
              padding: "0.5em 2em",
              borderRadius: "3px",
            }}
            onClick={getjson}
          >
            JSON
          </button>
        </Col>
      </Row>
      {/*<ColumnFilteringTable columns={[]} data={[]} />*/}
    </Card>
  );
};

export default ReportDB;
