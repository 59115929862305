import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Badge, Card, Col, Container, Row, Table } from "react-bootstrap";
import {
  collection,
  getDocs,
  query,
  where,
  serverTimestamp,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import { auth, db } from "../../../../contexts/FirebaseAuthContext";


function Employees() {
  const [users, setUsers] = useState([]);
  const [inuses, setInuses] = useState({});
  const [selectedEmail, setEmail] = useState(null);
  const [loggedinUser, setCurrentUser] = useState({});
  const getUsers = async () => {
    auth.onAuthStateChanged(async (user) => {
      const dataUsers = query(
        collection(db, "users"),
        where("uid", "==", user.uid)
      );
      const resultUsers = await getDocs(dataUsers);
      var loggedUser = resultUsers.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setCurrentUser(loggedUser[0]);
      if (
        loggedUser[0].role === "admin" ||
        loggedUser[0].role === "supervisor" ||
        loggedUser[0].role === "user"
      ) {
        const usersCollectionRef = query(
          collection(db, "users"),
          where("uid", "==", user.uid)
        );
        const data = await getDocs(usersCollectionRef);
        setUsers(
          data.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            length: getInuses(
              doc.id,
              doc.data().email,
              doc.data().samplmax,
              doc.data().maxsamplstartTime
            ),
          }))
        );
      }
    });
  };
  const inuseCollectionRef = (email) =>
    query(
      collection(db, "samples"),
      where("status", "==", "In Use"),
      where("samplmanag", "==", email)
    );
  const getInuses = async (id, email, samplmax, maxsamplstartTime) => {
    var datainuses = await getDocs(inuseCollectionRef(email));
    document.getElementById("count_" + id).innerHTML = datainuses.docs.length;
    var nowDay = parseInt(new Date().getUTCDate());
    var startDay = parseInt(new Date(maxsamplstartTime));
    var maxDay = 0;
    if (startDay) {
      console.log(nowDay);
      console.log(startDay);
      maxDay = nowDay - startDay;
      console.log(maxDay);
    }
    // var m = new Date(await maxsamplstartTime);
    // if (m.getUTCDate()){}
    // console.log(maxDay, new Date(await maxsamplstartTime).getUTCDate());
    if (datainuses.docs.length >= samplmax) {
      await db
        .collection("users")
        .doc(id)
        .update({ inusesampl: datainuses.docs.length, samplmaxtrig: maxDay });
    } else {
      await db.collection("users").doc(id).update({
        inusesampl: datainuses.docs.length,
        samplmaxtrig: maxDay,
      });
    }
    // console.log(maxDay);
  };
  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Employees Private Information
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table className="mb-0">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }} scope="col">
                Avatar
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                Samples In Use
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                Name
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                e-mail
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                Passport Number
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                role
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                Supervisor
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                Phone Number
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                Address
              </th>
              {/*<th style={{ textAlign: "center" }} scope="col">*/}
              {/*  Creation Date*/}
              {/*</th>*/}
              {/*<th style={{ textAlign: "center" }} scope="col">*/}
              {/*  Last Sign In Date*/}
              {/*</th>*/}
              <th style={{ textAlign: "center" }} scope="col">
                Actions
              </th>
            </tr>
          </thead>
          {users.map((user, index) => {
            return (
              <tbody key={user.uid}>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <img
                      src={user.photoURL}
                      width="48"
                      height="48"
                      className="rounded-circle me-2"
                      alt="Avatar"
                    />
                  </td>
                  <td
                    style={{ textAlign: "center" }}
                    id={`count_${user.uid}`}
                  ></td>
                  <td style={{ textAlign: "center" }}>{user.displayName}</td>
                  <td style={{ textAlign: "center" }}>{user.email}</td>
                  <td style={{ textAlign: "center" }}>{user.passPort}</td>
                  <td style={{ textAlign: "center" }}>{user.role}</td>
                  <td style={{ textAlign: "center" }}>{user.supervisor}</td>
                  <td style={{ textAlign: "center" }}>{user.phoneNumber}</td>
                  <td style={{ textAlign: "center" }}>{user.userLocation}</td>
                  {/*<td style={{ textAlign: "center" }}>{user.creationTime}</td>*/}
                  {/*<td style={{ textAlign: "center" }}>{user.lastSignInTime}</td>*/}
                  <td style={{ textAlign: "center" }}>
                    {(loggedinUser.role === "admin" ||
                      loggedinUser.role === "supervisor" ||
                      loggedinUser.role === "user") && (
                      <Link to={`/pages/editemployeeprivate/${user.uid}`}>
                        <button className="btn btn-edit">Edit</button>
                      </Link>
                    )}
                  </td>
                </tr>
              </tbody>
            );
          })}
        </Table>
      </Card.Body>
    </Card>
  );

  const Employees = () => (
    <React.Fragment>
      <Helmet title="Clients" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Employee Private</h1>

        <Row>
          <Col xl="8">
            <Employees />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Employees;
