import React from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";
import { AddSample } from "./AddSample";

const AddSamplePage = () => (
  <React.Fragment>
    <Helmet title="Add New Sample" />
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">SMS New Sample</h1>
      <AddSample />
    </Container>
  </React.Fragment>
);

export default AddSamplePage;
