import React, { useState, useEffect, useCallback } from "react";
import { Dropdown } from "react-bootstrap";
import { PieChart, Settings, User } from "react-feather";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db, auth } from "../../contexts/FirebaseAuthContext";

const NavbarUser = React.memo(() => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  const handleSignOut = useCallback(async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
      // handle the error here
    }
  }, [navigate]);

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  useEffect(() => {
    if (!currentUser) return;

    const usersCollectionRef = collection(db, "users");
    const userQuery = query(usersCollectionRef, where("uid", "==", currentUser.uid));

    const unsubscribeSnapshot = onSnapshot(userQuery, (snapshot) => {
      if (!snapshot.empty) {
        const currentUserData = snapshot.docs[0].data();
        setUserData(currentUserData);
      }
    });

    return () => {
      unsubscribeSnapshot();
    };
  }, [currentUser]);

  if (!userData) return null;

  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <img
            src={userData.photoURL}
            className="avatar img-fluid rounded-circle me-1"
            alt="avatar"
          />
          <span className="text-dark">
            {userData.displayName}
            {"\n"}
            {userData.role}
          </span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu drop="end">
        {/*<Dropdown.Item>*/}
        {/*    <User size={18} className="align-middle me-2" />*/}
        {/*    Profile*/}
        {/*</Dropdown.Item>*/}
        {/*<Dropdown.Item>*/}
        {/*    <PieChart size={18} className="align-middle me-2" />*/}
        {/*    Analytics*/}
        {/*</Dropdown.Item>*/}
        {/*<Dropdown.Divider />*/}
        {/*<Dropdown.Item>Settings & Privacy</Dropdown.Item>*/}
        {/*<Dropdown.Item>Help</Dropdown.Item>*/}
        <Dropdown.Item onClick={handleSignOut}>Sign out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
});

export default NavbarUser;
