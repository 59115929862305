import { useState, useEffect } from "react";
import { db } from "../../contexts/FirebaseAuthContext";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";

export const useDeviceModel = (DBModel) => {
  const [deviceModel, setDeviceModel] = useState([]);

  useEffect(() => {
    const q = query(collection(db, DBModel), orderBy("label", "asc"));
    const unsub = onSnapshot(q, (querySnapshot) => {
      let todoArray = [];
      querySnapshot.forEach((doc) => {
        todoArray.push({ ...doc.data(), id: doc.id, value: doc.data().label });
      });
      setDeviceModel(todoArray);
    });
    return () => {
      unsub();
    };
  }, [DBModel]);

  return deviceModel;
};
