import React, { useState, useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import firebase from "firebase/compat";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { store } from "./redux/store";
// import "./App.css";
// import "./i18n";
import routes from "./routes";
import IdleTimerComponent from "./components/IdleTimerComponent";
import { ThemeProvider } from "./contexts/ThemeContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { LayoutProvider } from "./contexts/LayoutContext";
import ChartJsDefaults from "./utils/ChartJsDefaults";

//import { AuthProvider } from "./contexts/JWTContext";
import { AuthProvider } from "./contexts/FirebaseAuthContext";
import fireDb, { db, auth } from "./config";
import { onAuthStateChanged } from "firebase/auth";
// import { AuthProvider } from "./contexts/Auth0Context";
// import { AuthProvider } from "./contexts/CognitoContext";

const App = () => {
  const content = useRoutes(routes);
  const location = useLocation();
  const [isAuthenticated, setAuth] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getData();
    return () => {
      setAuth(false);
    };
  }, []);
  const getData = async () => {
    const user = onAuthStateChanged(auth, (user) => {
      if (user != null) {
        setAuth(true);
      } else {
        setAuth(false);
      }
    });
  };
  const handleClick = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        console.log("Sign-out successful.");
      })
      .catch((error) => {
        console.log("An error happened.");
      });
  };

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | TADTELMAX - Uniportal"
        defaultTitle="SMS - Sample Management System"
      />
      <Provider store={store}>
        <ThemeProvider>
          <SidebarProvider>
            <LayoutProvider>
              <ChartJsDefaults />
              <IdleTimerComponent />
              <AuthProvider>{content}</AuthProvider>
            </LayoutProvider>
          </SidebarProvider>
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
