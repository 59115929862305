import React from "react";
import { Button, Container, Form, Row, Col, Card } from "react-bootstrap";
import useUsers from "./useUsers";
import useSupervisorModel from "./useSupervisorModel (1)";
import { Helmet } from "react-helmet-async";

const SupervisorModelComponent = () => {
  const {
    users,
    email,
    setEmail,
    selectedUser,
    setSelectedUser,
    handleAddUser,
  } = useUsers();

  const {
    supervisorModel,
    setSupervisorModel,
    model,
    setModel,
    selectModel,
    setSelectModel,
    deviceDelete,
  } = useSupervisorModel();

  return (
    <Row>
      <Col md={6}>
        <Card>
          <Card.Body>
            <Card.Title>Select the user that became supervisor</Card.Title>
            <Form.Select
              value={selectedUser?.email || ""}
              onChange={(e) => {
                const selected = users.find(
                  (item) => item.email === e.target.value
                );
                setSelectedUser(selected || null);
              }}
            >
              <option value="">Select a Supervisor</option>
              {users.map((item) => (
                <option key={item.id} value={item.email}>
                  {item.email}
                </option>
              ))}
            </Form.Select>
            <br />
            <Button
              variant="primary"
              onClick={(e) => {
                e.preventDefault();
                handleAddUser();
              }}
            >
              Add Supervisor
            </Button>
            <br />
            <table>
              <thead>
                <tr>
                  <th>Users</th>
                </tr>
              </thead>
              <tbody>
                {users.map((item) => (
                  <tr key={item.id}>
                    <td>{item.email}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card.Body>
        </Card>
      </Col>
      <Col md={6}>
        <Card>
          <Card.Body>
            <Card.Title>Select a model</Card.Title>
            <Form.Select
              value={selectModel?.email || ""}
              onChange={(e) => {
                const selected = supervisorModel.find(
                  (item) => item.email === e.target.value
                );
                setSelectModel(selected);
              }}
            >
              <option value="">Select a model</option>
              {supervisorModel.map((item) => (
                <option key={item.id} value={item.email}>
                  {item.email}
                </option>
              ))}
            </Form.Select>
            <br />
            <Button
              variant="danger"
              onClick={(e) => {
                e.preventDefault();
                deviceDelete();
              }}
            >
              Delete Supervisor
            </Button>
            <br />

            <table>
              <thead>
                <tr>
                  <th>Supervisor Model</th>
                </tr>
              </thead>
              <tbody>
                {supervisorModel.map((item) => (
                  <tr key={item.id}>
                    <td>{item.email}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

const SupervisorModelComponentPage = () => (
  <React.Fragment>
    <Helmet title="Edit Employee" />
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Supervisor Setting</h1>
      <SupervisorModelComponent />
    </Container>
  </React.Fragment>
);

export default SupervisorModelComponentPage;
