import React, { useEffect, useState, useMemo } from "react";import { Helmet } from "react-helmet-async";
import { useTable, useFilters, useGlobalFilter, useSortBy } from "react-table";
import { Card, Container, Table, Form, Col } from "react-bootstrap";
import firebase from "firebase/compat";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../../contexts/FirebaseAuthContext";
import fetchHistoryData from "./fetchHistoryData";

const removeDuplicates = (data) => {
  const uniqueIds = new Set();
  return data.filter((row) => {
    if (uniqueIds.has(row.id)) {
      return false;
    }
    uniqueIds.add(row.id);
    return true;
  });
};

const History = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
        const dataUsers = query(
          collection(db, "users"),
          where("uid", "==", user.uid)
        );
        const resultUsers = await getDocs(dataUsers);
        const loggedUser = resultUsers.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        const role = loggedUser[0]?.role;
        const currentUser = loggedUser[0]?.email;

        if (role && currentUser) {
          const fetchedData = await fetchHistoryData(role, currentUser);
          const filteredData = removeDuplicates(fetchedData);
          setData(filteredData);
        }
      });

      return () => {
        unsubscribe();
      };
    };

    getData();
  }, []);

  const formatDate = (date) => {
    if (!date || isNaN(date)) return ""; // Check if date is undefined, null, or invalid

    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    };

    const formattedDate = new Date(date.seconds * 1000).toLocaleString("en-US", options);
    return formattedDate;
  };

  const sortTypes = React.useMemo(() => ({
    registereddate: (row1, row2) => {
      const date1 = new Date(row1.values.registereddate.seconds * 1000);
      const date2 = new Date(row2.values.registereddate.seconds * 1000);
      return date1.getTime() - date2.getTime();
    },
    receivedDate: (row1, row2) => {
      const date1 = new Date(row1.values.receiveddate.seconds * 1000);
      const date2 = new Date(row2.values.receiveddate.seconds * 1000);
      return date1.getTime() - date2.getTime();
    },
  }), []);



  function DateColumnFilter({
                              column: { filterValue = [], setFilter },
                            }) {
    return (
      <div className="d-flex flex-column mt-2">
        <Form.Control
          value={filterValue[0] || ""}
          type="date"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [
              val ? val : undefined,
              old[1],
            ]);
          }}
          placeholder="Min"
          style={{
            width: "160px",
          }}
        />
        <Form.Control
          value={filterValue[1] || ""}
          type="date"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [
              old[0],
              val ? val : undefined,
            ]);
          }}
          placeholder="Max"
          style={{
            width: "160px",
            marginTop: "10px",
          }}
        />
      </div>
    );
  }


  function DateColumnFilter({
                              column: { filterValue = [], setFilter },
                            }) {
    return (
      <div className="d-flex flex-column mt-2">
        <Form.Control
          value={filterValue[0] || ""}
          type="date"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [
              val ? new Date(new Date(val).setUTCHours(0, 0, 0, 0)).toISOString().slice(0, 10) : undefined,
              old[1],
            ]);
          }}
          onClick={(e) => e.stopPropagation()} // Prevents accidental sorting
          placeholder="Min"
          style={{ width: "160px" }}
        />
        <Form.Control
          value={filterValue[1] || ""}
          type="date"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [
              old[0],
              val ? new Date(new Date(val).setUTCHours(23, 59, 59, 999)).toISOString().slice(0, 10) : undefined,
            ]);
          }}
          onClick={(e) => e.stopPropagation()} // Prevents accidental sorting
          placeholder="Max"
          style={{
            width: "160px",
            marginTop: "10px",
          }}
        />
      </div>
    );
  }

  function DefaultColumnFilter({
                                 column: { filterValue, preFilteredRows, setFilter },
                               }) {
    const count = preFilteredRows.length;
    return (
      <Form.Control
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={`Search ${count} records...`}
        className="mt-2"
        onClick={e => e.stopPropagation()}
      />
    );
  }

  function SelectColumnFilter({
                                column: { filterValue, setFilter, preFilteredRows, id },
                              }) {
    const options = React.useMemo(() => {
      const optionsSet = new Set();
      preFilteredRows.forEach(row => {
        optionsSet.add(row.values[id]);
      });
      return [...optionsSet.values()];
    }, [id, preFilteredRows]);

    return (
      <Form.Select
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined);
        }}
        onClick={e => e.stopPropagation()}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </Form.Select>
    );
  }

  // Custom filter method for date column
  const dateFilterMethod = (rows, id, filterValue) => {
    return rows.filter(row => {
      const rowValue = new Date(row.values[id].seconds * 1000);

      let startDate = new Date(filterValue[0]);
      startDate.setHours(0, 0, 0, 0);  // Set start time to the start of the day

      let endDate;
      if (filterValue[1]) {
        endDate = new Date(filterValue[1]);
        endDate.setHours(23, 59, 59, 999);  // Set end time to the end of the day
      } else {
        endDate = new Date(filterValue[0]);
        endDate.setHours(23, 59, 59, 999);  // If no end date is provided, consider the whole day
      }

      return rowValue >= startDate && rowValue <= endDate;
    });
  };


  function ColumnFilteringTable({ columns, data }) {
    const filterTypes = React.useMemo(
      () => ({
        text: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
        includes: (rows, id, filterValue) => {
          return rows.filter(row => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase())
              : true;
          });
        },
        date: dateFilterMethod,
      }),
      []
    );


    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      setGlobalFilter,
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        filterTypes,
        sortTypes // add this line
      },
      useFilters,
      useGlobalFilter,
      useSortBy // add this line
    );


    const { globalFilter } = state; // Get the global filter value from the state

    return (
      <Card>
        <Card.Header>
          <Card.Title>Search</Card.Title>
          <div className="mt-2">
            <Form.Group as={Col} md="2"> {/* Adjust the column size as needed */}
              <Form.Control
                className="form-control"
                value={globalFilter || ""}
                onChange={(e) => setGlobalFilter(e.target.value || undefined)}
                placeholder="Search all columns..."
              />
            </Form.Group>
          </div>
        </Card.Header>
        <Card.Body>
          <Table striped bordered {...getTableProps()}>
            <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
            {column.isSorted
              ? column.isSortedDesc
                ? ' 🔽'
                : ' 🔼'
              : ''}
          </span>
                    {/* Render the columns filter UI */}
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    );
  };

  const tableColumns = useMemo(
    () => [
  {
    Header: "Country",
    accessor: "country",
    Filter: SelectColumnFilter,
    filter: "includes",
  },
  {
    Header: "City",
    accessor: "city",
    Filter: SelectColumnFilter,
    filter: "includes",
  },
  {
    Header: "Customers",
    accessor: "customers",
    Filter: SelectColumnFilter,
    filter: "includes",
  },
  {
    Header: "Brand",
    accessor: "brand",
    Filter: SelectColumnFilter,
    filter: "includes",
  },
  {
    Header: "Model",
    accessor: "model",
    Filter: SelectColumnFilter,
    filter: "includes",
  },
  {
    Header: "Serial Number",
    accessor: "serialNumber",
    Filter: DefaultColumnFilter,
    filter: "includes",
  },
  {
    Header: "Imei 1",
    accessor: "imei1",
    Filter: DefaultColumnFilter,
    filter: "includes",
  },
  {
    Header: "Imei 2",
    accessor: "imei2",
    Filter: DefaultColumnFilter,
    filter: "includes",
  },
  {
    Header: "Action",
    accessor: "action",
    Filter: SelectColumnFilter,
    filter: "includes",
  },
  {
    Header: "Status",
    accessor: "status",
    Filter: SelectColumnFilter,
    filter: "includes",
  },
  ,
  {
    Header: "Sample Manager",
    accessor: "samplmanag",
    Filter: SelectColumnFilter,
    filter: "includes",
  },
  // {
  //   Header: "User Name",
  //   accessor: "samplmanagname",
  //   Filter: SelectColumnFilter,
  //   filter: "includes",
  // },
  {
    Header: "Last Manager",
    accessor: "lastmanag",
    Filter: SelectColumnFilter,
    filter: "includes",
  },
  {
    Header: "Supervisor",
    accessor: "supervisor",
    Filter: SelectColumnFilter,
    filter: "includes",
  },
    {
      Header: "Action Date",
      accessor: "actiondate",
      Cell: ({ value }) => formatDate(value),
      Filter: DateColumnFilter,
      filter: "date",
      sortType: (row1, row2, columnId, desc) => {
        const date1 = new Date(row1.values[columnId].seconds * 1000);
        const date2 = new Date(row2.values[columnId].seconds * 1000);
        return desc ? date2 - date1 : date1 - date2;
      },
    },
    ],
    []
  );

  return (
    <React.Fragment>
      <Helmet title="Sample Management System" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Sample Management System - History Sample</h1>
        <ColumnFilteringTable columns={tableColumns} data={data} />
      </Container>
    </React.Fragment>
  );
};

export default History;