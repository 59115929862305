import React, { useState } from "react";
import { Alert, Card, Container } from "react-bootstrap";

import EditEmployees from "./SMS/Setting/EditEmployees";
import SupervisorModelComponent from "./SMS/Setting/SupervisorModelComponent";
import AdminModelComponent from "./SMS/Setting/AdminModelComponent";

function ProtectedPage() {
  const [show, setShow] = useState(true);

  return (
    <React.Fragment>
      {!!show && (
        <Alert
          className="mb-3"
          variant="primary"
          dismissible
          onClose={() => setShow(false)}
        ></Alert>
      )}
      <Container>
        <Card>
          <EditEmployees />
        </Card>
      </Container>
      <Container>
        <Card>
          <SupervisorModelComponent />
        </Card>
      </Container>
      <Container>
        <Card>
          <AdminModelComponent />
        </Card>
      </Container>
    </React.Fragment>
  );
}

export default ProtectedPage;
