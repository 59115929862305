import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import signUpPageScreenshot from '../../../assets/img/screenshots/Sign Up Page.png';
import verificationEmailScreenshot from '../../../assets/img/screenshots/Verification Email.png';

function Docs() {
  return (
    <React.Fragment>
      <Helmet title="Sample Management System" />
      <Container fluid className="p-4">
        <h3 className="mb-4">Guide to Your Sample Inventory System</h3>

        <h4 className="mt-4">Privacy, search, and services</h4>
        <p>Our system uses your geographic location (latitude and longitude) to provide you with a better service.</p>
        <p>Below we provide step-by-step instructions to guide you or your users to the settings pages. Please follow these instructions to navigate to the location settings in Google Chrome and Microsoft Edge:</p>

        <h4 className="mt-4">Here's how to enable location sharing in your browser:</h4>
        <h5 className="mt-2">Google Chrome</h5>
        <ol className="mb-4">
          <li>Go to your website in Chrome (e.g., https://tdm.tadtelmax.com).</li>
          <li>Click on the lock icon to the left of the URL bar.</li>
          <li>Click on 'Site settings'.</li>
          <li>Next to 'Location', select 'Allow'.</li>
        </ol>
        <h5 className="mt-2">Microsoft Edge</h5>
        <ol className="mb-4">
          <li>Go to your website in Edge (e.g., https://tdm.tadtelmax.com).</li>
          <li>Click on the lock icon to the left of the URL bar.</li>
          <li>Under 'Permissions', next to 'Location', switch from 'Ask (default)' to 'Allow'.</li>
        </ol>

        <h4 className="mt-4">Signing Up</h4>
        <ol className="mb-4">
          <li>Open your web browser and go to https://tdm.tadtelmax.com/auth/sign-up.</li>
          <li>You will need to provide the following information in the corresponding fields:</li>
          <ul>
            <li>First Name</li>
            <li>Last Name</li>
            <li>Passport Number</li>
            <li>Country</li>
            <li>City</li>
            <li>Phone Number</li>
            <li>Address</li>
            <li>Email Address (e.g., samplesupport@tadtelmax.com)</li>
            <li>Password (ensure this is strong and secure)</li>
          </ul>
          <li>After entering these details, read and agree to the terms and conditions, then click the 'Sign Up' button.</li>
          <li>You should receive an email to verify your account. Click on the verification link in this email to activate your account.</li>
        </ol>

        <h4 className="mt-4">Screenshots</h4>

        <div className="mt-4 mb-4">
          <h5>Sign Up Page</h5>
          <img src={signUpPageScreenshot} alt="Sign Up Page Screenshot" className="img-fluid mb-4" />
        </div>

        <div className="mt-4 mb-4">
          <h5>Verification Email</h5>
          <img src={verificationEmailScreenshot} alt="Verification Email Screenshot" className="img-fluid mb-4" />
        </div>
      </Container>
    </React.Fragment>
  );
}

export default Docs;
