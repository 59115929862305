import { db } from "../../../../contexts/FirebaseAuthContext";
import { collection, query, orderBy } from "firebase/firestore";

const fetchHistoryData = async (role, currentUser) => {
  let query;

  switch (role) {
    case "admin":
      // Fetch all documents for admin
      query = db.collection("history");
      break;
    case "supervisor":
      // Fetch documents based on supervisor-specific conditions
      const supervisedUsersSnapshot = await db
        .collection("users")
        .where("supervisor", "==", currentUser)
        .get();

      const supervisedUsersData = supervisedUsersSnapshot.docs.map((doc) =>
        doc.data()
      );

      // Get distinct country values for supervised users
      const supervisedUsersCountries = [
        ...new Set(supervisedUsersData.map((user) => user.country)),
      ];

      // Fetch history for each country and merge the results
      const historyByCountryPromises = supervisedUsersCountries.map(
        async (country) => {
          const usersInCountry = supervisedUsersData
            .filter((user) => user.country === country)
            .map((user) => user.email);

          const query = db
            .collection("history")
            .where("samplmanag", "in", [currentUser, ...usersInCountry]);

          const snapshot = await query.get();
          return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        }
      );

      const historyByCountry = await Promise.all(historyByCountryPromises);
      const flattenedHistory = historyByCountry.flat();
      flattenedHistory.sort((a, b) => b.actiondate - a.actiondate); // Sort in descending order by actiondate
      return flattenedHistory;

    case "user":
      // Fetch documents based on user-specific conditions
      query = db.collection("history").where("samplmanag", "==", currentUser);
      break;
    default:
      return [];
  }

  if (role !== "supervisor") {
    const historySnapshot = await query.get();
    const historyData = historySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    historyData.sort((a, b) => b.actiondate - a.actiondate); // Sort in descending order by actiondate
    return historyData;

  }
};

export default fetchHistoryData;
