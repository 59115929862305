import React from "react";
import { Col, Form } from "react-bootstrap";
import DropSelector from "./useDropbox";

function SlotSelector(props) {
  return (
    <Form.Group
      as={Col}
      md={props.isedit ? "3" : ""}
      controlId="validationFormik04"
    >
      <Form.Label>Number of Slots</Form.Label>
      <DropSelector
        placeholder="Slots Quantity"
        name="slots"
        id="slots"
        title="Slots Model"
        DBModel="slotsModel"
        isedit={props.isedit}
        value={props.value}
        valueChange={props.valueChange}
        onChange={props.onChange} // Pass down the onChange prop
      />
    </Form.Group>
  );
}

export default SlotSelector;
