import React, { useState } from "react";
import Select from "react-select";
import { Col, Form, Row, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useLocation } from "react-router-dom";
import { useDeviceModel } from "../SMSHooks/useDeviceModel";
import { useRole } from "../SMSHooks/useRole";
import { useCrudOperations } from "../SMSHooks/useCrudOperations";

function DropSelector({
  isedit,
  name,
  id,
  placeholder,
  DBModel,
  value,
  valueData,
  title,
  valueChange,
  setFieldValue,
  btn,
}) {
  const [successMsg, setSuccessMsg] = useState("");
  const [show, setShow] = useState(false);
  const location = useLocation();
  let lastPath = location.pathname.split("/").length;
  const page = location.pathname.split("/")[lastPath - 1];

  const deviceModel = useDeviceModel(DBModel);
  const role = useRole();

  const {
    model,
    setModel,
    selectModel,
    setSelectModel,
    addDevice,
    deviceEdit,
    deviceDelete,
  } = useCrudOperations(DBModel, deviceModel);

  const handleClose = () => {
    setShow(false);
    setModel("");
    setSelectModel("");
  };
  const handleShow = () => setShow(true);

  const selectChange = (model) => {
    setModel(model.label);
    setSelectModel(model);
    if (model.label === "single") {
      document.getElementById("imei2").disabled = true;
    } else if (model.label === "dual") {
      document.getElementById("imei2").disabled = false;
    }
    try {
      setFieldValue(model.label);
    } catch (err) {
      console.log("no set field");
    }
  };

  return (
    <>
      {isedit === "true" ? (
        <>
          <Select
            onChange={(model) => {
              selectChange(model);
            }}
            className="react-select-container form-control p-0 border-0"
            classNamePrefix="react-select"
            type="text"
            value={selectModel}
            options={deviceModel}
            placeholder={placeholder}
            name={name}
            id={id}
          />

          {page !== "addsample" &&
          (role === "admin" || role === "supervisor") ? (
            <Button className="enabled my-2" onClick={handleShow}>
              ADD
            </Button>
          ) : (
            ""
          )}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body as={Col} md="12">
              <Row>
                <Form.Group as={Col} md="12" controlId="validationFormik04">
                  <Select
                    onChange={(model) => {
                      selectChange(model);
                    }}
                    className="react-select-container form-control p-0 border-0"
                    classNamePrefix="react-select"
                    type="text"
                    value={selectModel}
                    options={deviceModel}
                    placeholder={placeholder}
                    name={name}
                    id={id}
                  />
                  <Form.Control
                    type="text"
                    placeholder={title}
                    name="model"
                    value={model}
                    onChange={(e) => {
                      setModel(e.target.value);
                    }}
                  />

                  <Button
                    className="enabled"
                    style={{ margin: "2px" }}
                    onClick={addDevice}
                    name="addDevice"
                  >
                    ADD
                  </Button>
                  <Button
                    className="enabled"
                    style={{ margin: "2px" }}
                    onClick={deviceEdit} // No arguments passed
                    name="updateDevice"
                  >
                    UPDATE
                  </Button>
                  <Button
                    className="enabled"
                    style={{ margin: "2px" }}
                    onClick={deviceDelete}
                    name="deleteDevice"
                  >
                    DELETE
                  </Button>
                  <p>{successMsg}</p>
                </Form.Group>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <Select
          onChange={(updateModel, name) => {
            valueChange(updateModel, id);
          }}
          className="react-select-container form-control p-0 border-0"
          classNamePrefix="react-select"
          type="text"
          value={value}
          options={deviceModel}
          placeholder="Edit Device Modal"
          name={name}
          id={id}
        />
      )}
    </>
  );
}

export default DropSelector;
