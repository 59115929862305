import { createContext, useEffect, useReducer, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { firebaseConfig } from "../config";
import firebase from "firebase/compat";
import { onAuthStateChanged } from "firebase/auth";
import { serverTimestamp } from "firebase/firestore";
import { getDownloadURL } from "firebase/storage";

const INITIALIZE = "INITIALIZE";
let registeredUser;
if (!firebase.apps.length) {
  try {
    firebase.initializeApp(firebaseConfig);
    firebase.firestore();
  } catch {
    alert("first fail");
  }
}

const auth = firebase.auth();
const db = firebase.firestore();

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === INITIALIZE) {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [profile, setProfile] = useState(undefined);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const docRef = firebase.firestore().collection("users").doc(user.uid);
        docRef.get().then((doc) => {
          if (doc.exists) {
            setProfile(doc.data());
          }
        });
        dispatch({
          type: INITIALIZE,
          payload: { isAuthenticated: true, user },
        });
      } else {
        dispatch({
          type: INITIALIZE,
          payload: { isAuthenticated: false, user: null },
        });
      }
    });
  }, [dispatch]);
  const auth = { ...state.user };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "firebase",
        user: {
          id: auth.uid,
          email: auth.email,
          avatar: auth.avatar || profile?.avatar,
          displayName: auth.displayName || profile?.displayName,
          role: "user",
        },
        signIn,
        signUp,
        signOut,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const signIn = async (email, password) => {
  try {
    const firebaseUser = await auth.signInWithEmailAndPassword(email, password);

    if (firebaseUser.user.emailVerified) {
      alert(`Welcome ${email}`);
      const creationTime = auth.currentUser.metadata.creationTime;
      const lastSignInTime = auth.currentUser.metadata.lastSignInTime;
      console.log(lastSignInTime);

      await db.collection("users").doc(firebaseUser.user?.uid).update({
        creationTime,
        lastSignInTime,
        createdAt: serverTimestamp(),
      });

      window.location.href = "/dashboard/homefs";
    } else {
      await firebaseUser.user.sendEmailVerification();
      alert("Please go to your email to verify");
    }
  } catch (error) {
    if (error.message.includes("network-request-failed")) {
      window.location.href = "/500";
    }
    if (error.message.includes("user-not-found")) {
      alert("User is not registered. Please register again");
    }
  }
};
const signUp = async (
  firstName,
  lastName,
  email,
  passPort,
  country,
  city,
  phoneNumber,
  location,
  userLat,
  userLng,
  realLocation,
  realLat,
  realLng,
  password
) => {
  try {
    const registeredUser = await auth.createUserWithEmailAndPassword(
      email,
      password
    );
    console.log("registered", registeredUser.user?.uid);

    alert("Verification Email will be sent.");

    await db
      .collection("users")
      .doc(registeredUser.user?.uid)
      .set({
        uid: registeredUser.user?.uid,
        displayName: `${firstName} ${lastName}`,
        email,
        passPort,
        country,
        city,
        trInTransitDays: "3",
        trInTransitDaysRec: "1",
        trInReturnDays: "3",
        trInReturnDaysRec: "1",
        trOSRequestDays: "3",
        trOSRequestDaysRec: "1",
        trOwnerSamples: "10",
        trOwnerSamplesRec: "3",
        samplmax: "10",
        samplmaxr: "1",
        maxsamplstartTime: new Date().toLocaleString(),
        supervisor: "edson.shigaki@tadtelmax.com",
        role: "user",
        photoURL: "",
        location,
        userLat,
        userLng,
        realLocation,
        realLat,
        realLng,
        phoneNumber,
      });

    // Update the user's display name
    const user = auth.currentUser;
    await user.updateProfile({
      displayName: `${firstName} ${lastName}`,
    });

    window.location.href = "/auth/sign-in";

    await db
      .collection("mail")
      .doc(registeredUser.user?.uid)
      .set({
        uid: registeredUser.user?.uid,
        displayName: `${firstName} ${lastName}`,
        email,
      });

    await db.collection("mail").add({
      to: email,
      message: {
        subject: "Hello from Firebase your email registered!",
        text: "This is the plaintext section of the email body.",
        html: "This is the <code>HTML</code> section of the email body.",
      },
    });

    console.log("Queued email for delivery!");
  } catch (error) {
    if (error.message.includes("network-request-failed")) {
      // Handle network request failure
      // window.location.href = "/500";
    }
    if (error.message.includes("email-already-in-use")) {
      // Handle email already in use
      // alert("Email is already registered. Please use others' now");
    }
    console.log(error.code, error.message);
  }
};


export default async function Clients(
  firstName,
  lastName,
  email,
  company,
  location,
  phoneNumber,
  aboutMe
) {
  const uid = firebase.auth().currentUser.uid;
  console.log(uid);

  await firebase
    .firestore()
    .collection("clients")
    .doc()
    .set({
      firstName: document.getElementsByName("firstName")[0].value,
      lastName: document.getElementsByName("lastName")[0].value,
      email: document.getElementsByName("email")[0].value,
      company: document.getElementsByName("company")[0].value,
      location: document.getElementsByName("location")[0].value,
      phoneNumber: document.getElementsByName("phoneNumber")[0].value,
      aboutMe: document.getElementsByName("aboutMe")[0].value,
      uid,
      // lastUpdate: serverTimestamp(),
      keyPerson: "john.witch@xpto.com",
    })
    .then(function () {
      alert("Client Add with Success");
    })
    .catch(function (error) {
      console.error(error.code);
      console.error(error.message);
      alert("Fail when try add new client.");
    });
  // .then(function (docRef) {
  //   console.log("Document written with ID: ", docRef.id);
  // });
}

export async function Employees(
  trInTransitDays,
  trInTransitDaysRec,
  trInReturnDays,
  trInReturnDaysRec,
  trOSRequestDays,
  trOSRequestDaysRec,
  trOwnerSamples,
  trOwnerSamplesRec
) {
  await firebase
    .firestore()
    .collection("users")
    .doc()
    .update({
      trInTransitDays: document.getElementsByName("trInTransitDays")[0].value,
      trInTransitDaysRec: document.getElementsByName(" trInTransitDaysRec")[0]
        .value,
      trInReturnDays: document.getElementsByName("trInReturnDays")[0].value,
      trInReturnDaysRec:
        document.getElementsByName(" trInReturnDaysRec")[0].value,
      trOSRequestDays: document.getElementsByName("trOSRequestDays")[0].value,
      trOSRequestDaysRec:
        document.getElementsByName("trOSRequestDaysRec")[0].value,
      trOwnerSamples: document.getElementsByName("trOwnerSamples")[0].value,
      trOwnerSamplesRec:
        document.getElementsByName("trOwnerSamplesRec")[0].value,
      supervisor: document.getElementsByName("supervisor")[0].value,
      role: document.getElementsByName("role")[0].value,
    })
    .then(function () {
      alert("Employee trigger form update with Success");
    })
    .catch(function (error) {
      console.error(error.code);
      console.error(error.message);
      alert("Fail when try update Employees.");
    });
}

const signOut = async () => {
  await firebase.auth().signOut();
};

var getLocation = function (href) {
  var l = document.createElement("a");
  l.href = href;
  return l;
};

export const user = onAuthStateChanged(auth, (user) => {
  if (user != null) {
    // console.log(user);
    if (user.emailVerified == true) {
      // console.log("logged in!");
      const uid = firebase.auth().currentUser.uid;
      // console.log(uid);
    } else {
      var url = window.location.href;
      var location = getLocation(url);
      var signupUrl = location.pathname != "/auth/sign-up" ? true : false;
      if (true == signupUrl && location.pathnmae != "dashboard") {
        signOut();
      } else {
        console.log("data 2", signupUrl);
      }
    }
  } else {
    var url = window.location.href;
    var location = getLocation(url);
    if (
      location.pathname != "/" &&
      location.pathname != "/auth/Sign-In" &&
      location.pathname != "/auth/Sign-Up"
    ) {
      window.location.href = "/";
    }
    console.log("No user");
  }
});

function logout() {
  return firebase
    .auth()
    .signOut()
    .then(() => {
      console.log("Sign-out successful.");
    })
    .catch((error) => {
      console.log("An error happened.");
    });
}

const resetPassword = async (email) => {
  await firebase.auth().sendPasswordResetEmail(email);
};

// async function addForm(lat, lng, data) {
//   // Check if data is valid
//   if (!data || typeof data !== "object") {
//     console.error("Invalid data:", data);
//     return;
//   }
//
//   try {
//     const uid = firebase.auth().currentUser.uid;
//     const email = firebase.auth().currentUser.email;
//     var supervisor;
//     var country;
//
//     const userSnapshot = await firebase
//       .firestore()
//       .collection("users")
//       .where("uid", "==", uid)
//       .get();
//
//     userSnapshot.forEach(function (doc) {
//       var data = doc.data();
//       supervisor = data.supervisor;
//       country = data.country;
//     });
//
//     const batch = firebase.firestore().batch();
//     const newSampleRef = firebase.firestore().collection("samples").doc();
//
//     const newSampleData = {
//       ...data,
//       country,
//       email,
//       uid,
//       registereddate: serverTimestamp(),
//       registeredby: email,
//       status: "Registered",
//       supervisor,
//       samplmanag: email,
//       lat,
//       lng,
//     };
//
//     batch.set(newSampleRef, newSampleData);
//
//     const imeiRefs = [data.imei1, data.imei2].map((imei) =>
//       firebase.firestore().collection("Imei").doc(imei)
//     );
//     const serialNumberRef = firebase
//       .firestore()
//       .collection("serialNumber")
//       .doc(data.serialNumber);
//
//     imeiRefs.forEach((ref) => batch.set(ref, { uid }));
//     batch.set(serialNumberRef, { uid });
//
//     const historyRef = firebase.firestore().collection("history").doc();
//     const historyData = {
//       email,
//       uid,
//       actiondate: serverTimestamp(),
//       action: "Register",
//       country,
//       registereddate: serverTimestamp(),
//       status: "Registered",
//       supervisor,
//       brand: data.brand,
//       customers: data.customers,
//       samplmanag: email,
//       lastmanag: data.deliveredBy,
//       serialNumber: data.serialNumber,
//       imei1: data?.imei1,
//       imei2: data?.imei2,
//     };
//
//     batch.set(historyRef, historyData);
//     await batch.commit();
//
//     alert(email + " Sample Add with Success");
//     window.location.href = "/private/exportreportfs";
//   } catch (error) {
//     console.error(error.code);
//     console.error(error.message);
//     alert("Falha ao cadastrar, verifique o erro no console.");
//   }
// }
//
// function excelDateToJSDate(excelDate) {
//   var date = new Date(Math.round((excelDate - (25567 + 1)) * 86400 * 1000));
//   var converted_date = date.toISOString().split("T")[0];
//   return converted_date;
// }

export async function addform2here(lat, lng, address, ddata) {
  let yipelist = [
    "country",
    "brand",

    "devTypes",
    "hwTypes",
    "os",
    "tNetworks",
    "slots",
    "simCard",
    "tmodel",
    "comments",
  ];

  for (let k = 0; k < yipelist.length; k++) {
    if (yipelist[k] in ddata) {
    } else {
      ddata[yipelist[k]] = "";
    }
  }

  //console.log(excelDateToJSDate(ddata.receiveDate));

  const uid = firebase.auth().currentUser.uid;
  const email = firebase.auth().currentUser.email;
  var supervisor;
  // const navigate = useNavigate();
  function Redirect() {
    //const navigate = useNavigate();
    //navigate("/dashboard/homefs");
  }
  await firebase
    .firestore()
    .collection("users")
    .where("email", "==", email)
    .get()
    .then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        var data = doc.data();
        supervisor = data.supervisor;
      });
    })
    .then(async function () {
      await firebase
        .firestore()
        .collection("samples")
        .doc()
        .set({
          country: ddata.country,
          email,
          uid,
          registereddate: serverTimestamp(),
          registeredby: email,
          status: "Registered",
          supervisor: supervisor,
          brand: ddata.brand,
          samplmanag: email,
          lastmanag: ddata.deliveredBy,
          devTypes: ddata.devTypes,
          hwtype: ddata.hwTypes,
          os: ddata.os,
          tNetworks: ddata.tNetworks,
          // ${document.getElementsByName("tNetworks")[1].value},
          // ${document.getElementsByName("tNetworks")[2].value},
          // ${document.getElementsByName("tNetworks")[3].value}`,
          slots: ddata.slots,
          simCard: ddata.simCard,
          // tmodel: document.getElementsByName("tmodel")[0].value,
          model: ddata.tmodel,
          serialNumber: ddata.serialNumber.toString(),
          imei1: ddata.imei1.toString(),
          imei2: ddata.imei2.toString(),
          receiveDate: ddata.receiveDate,
          duplicate: ddata.duplicate,
          deliveredBy: ddata.deliveredBy,
          phoneNumber: ddata.phoneNumber,
          location: ddata.location,
          locationR: ddata.locationR,
          lat: lat,
          lng: lng,
          comments: ddata.comments,
        })
        .then(async function () {
          console.log(email + " Sample Add with Success");
          //const imei1 = document.getElementsByName("imei1")[0].value;
          //const imei2 = document.getElementsByName("imei2")[0].value;
          //const serialNumber =
          //document.getElementsByName("serialNumber")[0].value;
          await firebase
            .firestore()
            .collection("Imei")
            .doc(ddata.imei1.toString())
            .set({ uid });
          await firebase
            .firestore()
            .collection("Imei")
            .doc(ddata.imei2.toString())
            .set({ uid });
          await firebase
            .firestore()
            .collection("serialNumber")
            .doc(ddata.serialNumber.toString())
            .set({ uid });
          // await Redirect();
          //window.location.href = "/dashboard/homefs";

          await firebase.firestore().collection("history").doc().set({
            email,
            uid,
            actiondate: serverTimestamp(),
            action: "Register",
            country: ddata.country,

            registereddate: serverTimestamp(),

            status: "Registered",
            supervisor: supervisor,
            brand: ddata.brand,
            samplmanag: email,
            lastmanag: ddata.deliveredBy,

            model: ddata.tmodel,
            serialNumber: ddata.serialNumber,
            imei1: ddata.imei1,
            imei2: ddata.imei2,
          });
        })
        .catch(function (error) {
          //console.error(error.code);
          console.error(error.message);
          console.log("some problem");
          //alert("Falha ao cadastrar, verifique o erro no console.");
        });
      const db = firebase.firestore();
      const increment = firebase.firestore.FieldValue.increment(1);
      const decrement = firebase.firestore.FieldValue.increment(-1);

      const statsRef = db.collection("CounterSamples").doc(uid);
      const storyRef = db.collection("CounterSamples").doc(`${Math.random()}`);

      const batch = db.batch();
      batch.set(storyRef, { title: "Registered" });
      batch.set(statsRef, { Registered: increment }, { merge: true });
      batch.commit();
      // console.log(storyRef);
    });
}

export async function duplicates() {
  const { uid } = firebase.auth().currentUser;
  const getValue = (name) => document.getElementsByName(name)[0].value;
  const imei1 = getValue("imei1");
  const imei2 = getValue("imei2");
  const serialNumber = getValue("serialNumber");
  const slots = getValue("slots");

  console.log(
    `IMEI1: ${imei1}, IMEI2: ${imei2}, SerialNumber: ${serialNumber}`
  );

  const getDoc = async (samples, uid) =>
    firebase.firestore().collection(samples).doc(uid).get();

  const handleUI = (message, success = false) => {
    console.log(message);
    alert(message);

    if (success) {
      const fields = ["customers", "brand", "deliveredBy", "devTypes"];
      const inputs = fields.map(getValue);
      const submitBtn = document.getElementsByName("submitbtn")[0];

      if (submitBtn) {
        const allFieldsFilled = inputs.every((input) => input !== "");

        if (allFieldsFilled) {
          submitBtn.classList.remove("disabled");
        } else {
          alert("Please fulfill all fields");
        }
      } else {
        console.error("Submit button element not found");
      }
    }
  };

  try {
    let imeiDoc, serialNumberDoc, imei2Doc;

    if (imei1) {
      imeiDoc = await getDoc("Imei", imei1);
      if (imeiDoc.exists) handleUI("imei1 already exists");
      else handleUI("imei1 not exists in SMS yet...");
    }

    if (slots !== "single" && imei2) {
      imei2Doc = await getDoc("Imei", imei2);
      if (imei2Doc?.exists || imei1 === imei2) handleUI("imei2 already exists");
      else handleUI("imei2 not exists in SMS yet...");
    }

    if (serialNumber) {
      serialNumberDoc = await getDoc("serialNumber", serialNumber);
      if (serialNumberDoc.exists) handleUI("Serial Number already exists");
      else
        handleUI(
          "New Serial Number in SMS, please if IME1 and Imei2 also new, submit form...",
          true
        );
    }
  } catch (error) {
    console.error(error);
  }
}

export async function duplicates2here(lat, lng, address, ddata) {
  let rflag = true;
  const uid = firebase.auth().currentUser.uid;
  const imei1 = ddata.imei1;
  const imei2 = ddata.imei2;
  const serialNumber = ddata.serialNumber;
  ddata["duplicate"] = "none";
  var flag = 0;

  console.log(ddata);
  //console.log(ddata.imei1);

  if (
    !(
      "imei1" in ddata &&
      "imei2" in ddata &&
      "serialNumber" in ddata &&
      "receiveDate" in ddata &&
      "deliveredBy" in ddata &&
      "phoneNumber" in ddata &&
      "locationR" in ddata &&
      "location" in ddata
    )
  ) {
    console.log("main fields are missing which are required");
  } else {
    let responseflag = await firebase
      .firestore()
      .collection("Imei")
      .doc(ddata.imei1.toString())
      .get({ uid })
      .then(async (doc) => {
        if (doc.exists) {
          //rflag = false;
          console.log("Imei1 already exists");
          //alert("Imei1 already exists");

          ddata["duplicate"] = "imei1";
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .then(
        await firebase
          .firestore()
          .collection("Imei")
          .doc(ddata.imei2.toString())
          .get({ uid })
          .then(async (doc) => {
            if (doc.exists) {
              console.log("Imei2 already exists");
              //rflag = false;

              //alert("Imei2 already exists");
              if (ddata["duplicate"] == "imei1") {
                ddata["duplicate"] = "both";
              } else {
                ddata["duplicate"] = "imei2";
              }
            } else {
              if (ddata.imei1 == ddata.imei2) {
                if (ddata["duplicate"] == "none") {
                  console.log("Imei2 is same as Imei1");
                  //alert("Imei2 is same as Imei1");
                  ddata["duplicate"] = "imei2";
                } else if (
                  ddata["duplicate"] == "imei1" ||
                  ddata["duplicate"] == "both"
                ) {
                  console.log("Imei2 is same as Imei1");
                  //alert("Imei2 is same as Imei1");
                  ddata["duplicate"] = "both";
                }
              } else {
                console.log("Imei2 Add with Success");
                //alert("Imei2 not exists in SMS yet...");
                flag++;
              }
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .then(
            await firebase
              .firestore()
              .collection("serialNumber")
              .doc(ddata.serialNumber.toString())
              .get({ uid })
              .then(async (doc) => {
                if (doc.exists) {
                  rflag = false;
                }

                if (rflag) {
                  await addform2here(lat, lng, address, ddata);
                } else {
                  console.log("check serialNumber must not already exist");
                }
              })
              .catch((error) => {
                console.error(error);
              })
          )
      );
  }
}

export async function rules() {
  firebase
    .firestore()
    .collection("samples")
    .where("status", "==", "Registered")
    .where("email", "==", "cindy@test.com")
    .get()
    .then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        console.log(doc.id, " => ", doc.data());
      });
    });
}

// async function form2() {
//   const uid = firebase.auth().currentUser.uid;
//   console.log(uid);
//   await firebase
//     .firestore()
//     .collection("Devices")
//     .doc()
//     .set({
//       // firstName: document.getElementsByName("firstName")[0].value,
//       // lastName: document.getElementsByName("lastName")[0].value,
//       // username: document.getElementsByName("username")[0].value,
//       // city: document.getElementsByName("city")[0].value,
//       // state: document.getElementsByName("state")[0].value,
//       // zip: document.getElementsByName("zip")[0].value,
//       uid,
//       lastUpdate: serverTimestamp(),
//       brand: document.getElementsByName("brand")[0].value,
//       model: document.getElementsByName("model")[0].value,
//       kinDevice: document.getElementsByName("kinDevice")[0].value,
//       typeDevice: document.getElementsByName("typeDevice")[0].value,
//       location: document.getElementsByName("location")[0].value,
//       receiveDate: document.getElementsByName("receiveDate")[0].value,
//       courierTrackNo: document.getElementsByName("courierTrackNo")[0].value,
//       statusDevice: document.getElementsByName("statusDevice")[0].value,
//       serialNumber: document.getElementsByName("serialNumber")[0].value,
//       simCard: document.getElementsByName("simCard")[0].value,
//       imei1: document.getElementsByName("imei1")[0].value,
//       imei2: document.getElementsByName("imei2")[0].value,
//       hardwareVersion: document.getElementsByName("hardwareVersion")[0].value,
//       softwareVersion: document.getElementsByName("softwareVersion")[0].value,
//       network: document.getElementsByName("network")[0].value,
//       os: document.getElementsByName("os")[0].value,
//       osVersion: document.getElementsByName("osVersion")[0].value,
//       notify: document.getElementsByName("notify")[0].value,
//       deliveredBy: document.getElementsByName("deliveredBy")[0].value,
//       email: document.getElementsByName("email")[0].value,
//       phoneNumber: document.getElementsByName("phoneNumber")[0].value,
//       comments: document.getElementsByName("comments")[0].value,
//     });
// }

export async function editform() {
  await firebase
    .firestore()
    .collection("samples")
    .doc()
    .update({
      brand: document.getElementsByName("brands")[0].value,
      lastmanag: document.getElementsByName("lastmanag")[0].value,
      typeDevice: document.getElementsByName("devTypes")[0].value,
      hwtype: document.getElementsByName("hwTypes")[0].value,
      os: document.getElementsByName("oSystems")[0].value,
      network: document.getElementsByName("tNetworks")[0].value,
      simCard: document.getElementsByName("slots")[0].value,
      simTypes: document.getElementsByName("simTypes")[0].value,
      model: document.getElementsByName("model")[0].value,
      serialNumber: document.getElementsByName("serialNumber")[0].value,
      imei1: document.getElementsByName("imei1")[0].value,
      imei2: document.getElementsByName("imei2")[0].value,
      receiveDate: document.getElementsByName("receiveDate")[0].value,
      phoneNumber: document.getElementsByName("phoneNumber")[0].value,
      comments: document.getElementsByName("comments")[0].value,
    })
    .then(() => {
      console.log("Sign-out successful.");
    })
    .catch((error) => {
      console.log("An error happened.");
    });
}

// function uploadFileClients(e) {
//   const file = e.target.files[0];
//   firebase
//     .storage()
//     .ref("clients/" + file.name)
//     .put(file)
//     .then((snapshot) => {
//       getDownloadURL(snapshot.ref).then((downloadURL) => {
//         const photoURL = downloadURL;
//         console.log(photoURL);
//         console.log(firebase.auth().currentUser.uid);
//         const uid = firebase.auth().currentUser.uid;
//         const email = firebase.auth().currentUser.email;
//         console.log(email);
//         const metadata = firebase.auth().currentUser.metadata;
//         console.log(metadata);
//         firebase.firestore().doc(`/clients/{uid}`).update({
//           photoURL: photoURL,
//         });
//       });
//     });
// }

function uploadFile(id, e) {
  const file = e.target.files[0];
  const userRef = firebase.firestore().doc(`/users/${id}`);

  firebase
    .storage()
    .ref(`users/${id}/${file.name}`)
    .put(file)
    .then((snapshot) => {
      return snapshot.ref.getDownloadURL();
    })
    .then((photoURL) => {
      return userRef.update({ photoURL });
    })
    .catch((error) => {
      console.error("Error uploading file: ", error);
    });
}

export { AuthContext, AuthProvider, logout, uploadFile, db, auth };
