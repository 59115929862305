import React from "react";
const SidebarFooter = () => {
  return (
    <div className="sidebar-cta">
      <div className="sidebar-cta-content">
        <strong className="centered-text"></strong>
        <div className="mb-3 text-sm">TADTELMAX</div>
        <div className="mb-3 text-sm">Home Page</div>
        <div className="d-grid">
          <a
            href="https://tadtelmax.com/"
            className="btn btn-primary"
            target="_blank"
            rel="noreferrer"
          >
            Redirect
          </a>
        </div>
      </div>
    </div>
  );
};

export default SidebarFooter;
