import React, { useState } from "react";
import Select from "react-select";
import { Col, Form, Button } from "react-bootstrap";
import Modals from "./Modal";
import DropSelector from "./useDropbox";

function NetworkSelector(props) {
  const tNetworks = [
    { value: "2G", label: "2G" },
    { value: "3G", label: "3G" },
    { value: "4G", label: "4G" },
    { value: "5G", label: "5G" },
    { value: "6G", label: "6G" },
    { value: "4G, 5G", label: "4G, 5G" },
    { value: "3G, 4G, 5G", label: "3G, 4G, 5G" },
    { value: "2G, 3G, 4G, 5G", label: "2G, 3G, 4G, 5G" },
  ];
  const [show, setShow] = useState(false);
  return (
    <Form.Group
      as={Col}
      md={props.isedit ? "3" : ""}
      controlId="validationFormik04"
    >
      <Form.Label>Technology Network</Form.Label>
      <DropSelector
        placeholder="Network"
        name="tNetworks"
        id="tNetworks"
        title="Networks"
        DBModel="Networks"
        isedit={props.isedit}
        value={props.value}
        valueChange={props.valueChange}
      />
    </Form.Group>
  );
}
export default NetworkSelector;
