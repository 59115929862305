import { useState, useEffect } from "react";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  doc,
  updateDoc,
  setDoc,
  deleteDoc,
  getDoc,
  getDocs,
  where,
  addDoc,
} from "firebase/firestore";
import { db } from "../../../../config";
const useAdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [email, setEmail] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [addUser, setAddUser] = useState(""); // added this line

  // console.table(users);
  // console.log(email);
  // console.log(selectedUser);
  // console.log(addUser);

  useEffect(() => {
    const q = query(collection(db, "users"), orderBy("email", "asc"));
    const unsub = onSnapshot(q, (querySnapshot) => {
      let userArray = [];
      querySnapshot.forEach((doc) => {
        userArray.push({ ...doc.data(), id: doc.id, value: doc.data().email });
      });
      setUsers(userArray);
    });
    return unsub;
  }, []);

  const handleAddUser = async () => {
    if (selectedUser.email?.trim() !== "") {
      try {
        const adminRef = collection(db, "adminModel");
        const adminSnapshot = await getDocs(
          query(adminRef, where("email", "==", selectedUser.email))
        );

        if (adminSnapshot.empty) {
          await addDoc(adminRef, {
            email: selectedUser.email,
          });
          // setSelectedUser("");
          alert("Success to record in AdminModel collection.");
        } else {
          alert("This email already exists in the AdminModel collection.");
        }
      } catch (error) {
        // console.error(error);
      }
    }
  };

  return {
    users,
    setUsers,
    email,
    setEmail,
    selectedUser,
    setSelectedUser,
    // addUser,
    // setAddUser,
    handleAddUser,
  };
};

export default useAdminUsers;
