import React, { useState } from "react";
import Select from "react-select";
import Modals from "./Modal";
import { Col, Form, Button } from "react-bootstrap";
import DropSelector from "./useDropbox";

function DevTypeSelector(props) {
  const devTypes = [
    { value: "Mobile", label: "Mobile" },
    { value: "Tablet", label: "Tablet" },
    { value: "Smart Watch", label: "Smart Watch" },
  ];
  const [show, setShow] = useState(false);
  return (
    <Form.Group
      as={Col}
      md={props.isedit ? "3" : ""}
      controlId="validationFormik04"
    >
      <Form.Label>Type Of Device</Form.Label>
      <DropSelector
        placeholder="Type of Device"
        name="devTypes"
        id="devTypes"
        title="devTypes Model"
        DBModel="devTypes"
        isedit={props.isedit}
        value={props.value}
        valueChange={props.valueChange}
      />
    </Form.Group>
  );
}
export default DevTypeSelector;
