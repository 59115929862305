import { useState } from "react";
import {
  doc,
  updateDoc,
  setDoc,
  deleteDoc,
  collection,
} from "firebase/firestore";
import { db } from "../../contexts/FirebaseAuthContext";

export function useCrudOperations(DBModel, deviceModel, title) {
  const [model, setModel] = useState("");
  const [selectModel, setSelectModel] = useState("");

  const addDevice = async (value) => {
    let exist = deviceModel.some((item) => item.label === model.toLowerCase());
    if (!exist && model !== "") {
      const newDesignRef = doc(collection(db, DBModel));
      await setDoc(newDesignRef, { label: model.toLowerCase(), value: model.toLowerCase() });
      alert(`Added ${title} Successfully`);
      setModel("");
    } else if (exist && model !== "") {
      alert("Already exist");
    } else {
      alert(`${title} Field Required`);
    }
  };


  const deviceEdit = async () => {
    if (selectModel !== "" && model !== "") {
      const todo = deviceModel.find(
        (item) => item.label === selectModel.label.toLowerCase()
      );
      await updateDoc(doc(db, DBModel, todo.id), {
        label: model.toLowerCase(),
        value: model.toLowerCase() // Use model directly instead of "value"
      });
      alert(`Updated ${title} Successfully`);
      setModel("");
      setSelectModel("");
    } else {
      alert("Select Your Model");
    }
  };



  const deviceDelete = async () => {
    if (selectModel !== "" && model !== "") {
      const todo = deviceModel.find(
        (item) => item.label === selectModel.label.toLowerCase()
      );
      await deleteDoc(doc(db, DBModel, todo.id));
      alert(`Deleted ${title} Successfully`);
      setModel("");
      setSelectModel("");
    } else {
      alert("Select Your Model");
    }
  };

  return {
    model,
    setModel,
    selectModel,
    setSelectModel,
    addDevice,
    deviceEdit,
    deviceDelete,
  };
}
