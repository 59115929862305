import React, { useState, useEffect, useRef } from "react";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import { Form, ListGroup } from "react-bootstrap";
import { useLoadScript } from "@react-google-maps/api";

export const getAddressFromLatLng = async (lat, lng) => {
  try {
    const results = await getGeocode({ location: { lat, lng } });
    const address = results[0].formatted_address;
    return address;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const PlaceAutoComplete = (props) => {
  const [hoverActive, setActive] = useState(null);
  const placeElement = useRef(null);

  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ["address"],
    },
  });

  const handleInput = (event) => {
    setValue(event.target.value);
  };

  const handleSelect = async ({ description }) => {
    setValue(description, false);

    const results = await getGeocode({ address: description });
    const { lat, lng } = await getLatLng(results[0]);

    props.onChange({ address: description, lat, lng });

    clearSuggestions();
  };

  useEffect(() => {
    const focusOutput = (e) => {
      if (placeElement.current && !placeElement.current.contains(e.target)) {
        clearSuggestions();
      }
    };

    document.addEventListener("click", focusOutput);
    return () => document.removeEventListener("click", focusOutput);
  }, [placeElement]);

  const renderSuggestions = () => {
    return data.map((suggestion, index) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <ListGroup.Item
          className={
            index === hoverActive
              ? "bg-primary cursor-pointer"
              : "cursor-pointer"
          }
          key={place_id}
          onClick={() => handleSelect(suggestion)}
          onMouseOver={() => setActive(index)}
          onMouseLeave={() => setActive(null)}
        >
          <strong className={index === hoverActive ? "text-white" : ""}>
            {main_text}
          </strong>
          <small className={index === hoverActive ? "text-light" : ""}>
            {secondary_text}
          </small>
        </ListGroup.Item>
      );
    });
  };

  return (
    <div ref={placeElement}>
      <Form.Control
        type="text"
        placeholder="Location"
        name={props.name}
        value={value}
        onChange={handleInput}
        isInvalid={props.isInvalid}
      />
      {status === "OK" && (
        <ListGroup className="position-absolute">
          {renderSuggestions()}
        </ListGroup>
      )}
    </div>
  );
};

const GOOGLE_MAPS_LIBRARIES = ["places"];

export default function WrappedPlaceAutoComplete(props) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: GOOGLE_MAPS_LIBRARIES,
    language: 'en',
  });

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading...</div>;

  return <PlaceAutoComplete {...props} />;
}

