import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import { getDocs } from "firebase/firestore";
import firebase from "firebase/compat";

async function SampCoutbyEmpl() {
  const [samples, setSamples] = useState([]);

  useEffect(() => {
    const samplesCollectionRef = firebase
      .firestore()
      .collection("samples")
      .doc("status");
    const getSamples = async () => {
      const dataSam = await getDocs(samplesCollectionRef);
      setSamples(dataSam.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      // const inTransit = collection(db, "samples")
      //   .doc("status")
      //   .where("Registered" === doc.data().status);
      // console.log(inTransit);
      console.log(dataSam);
    };
    getSamples();
  }, []);
  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Users
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table className="mb-0">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }} scope="col">
                Avatar
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                Name
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                e-mail
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                Passport Number
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                role
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                Supervisor
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                Phone Number
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                Location
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                Status
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                Creation Date
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                Last Sign In Date
              </th>
            </tr>
          </thead>
          {samples.map((sample) => {
            return (
              <tbody key={sample.uid}>
                <tr>
                  <td style={{ textAlign: "center" }}>{sample.uid}</td>
                  <td style={{ textAlign: "center" }}>{sample.userName}</td>
                  <td style={{ textAlign: "center" }}>{sample.samplmanag}</td>
                  <td style={{ textAlign: "center" }}>
                    {sample.samplmanag.length}
                  </td>
                  {/*<td style={{ textAlign: "center" }}>{user.email}</td>*/}
                  {/*<td style={{ textAlign: "center" }}>{user.passPort}</td>*/}
                  {/*<td style={{ textAlign: "center" }}>{user.role}</td>*/}
                  {/*<td style={{ textAlign: "center" }}>{user.supervisor}</td>*/}
                  {/*<td style={{ textAlign: "center" }}>{user.phoneNumber}</td>*/}
                  {/*<td style={{ textAlign: "center" }}>{sample.country}</td>*/}
                  {/*<td style={{ textAlign: "center" }}>{sample.creationTime}</td>*/}
                  {/*<td style={{ textAlign: "center" }}>{user.lastSignInTime}</td>*/}
                </tr>
              </tbody>
            );
          })}
        </Table>
      </Card.Body>
    </Card>
  );

  const Samples = () => (
    <React.Fragment>
      <Helmet title="Clients" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Clients</h1>

        <Row>
          <Col xl="8">
            <Samples />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}
export default SampCoutbyEmpl;
