import React from "react";

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import LandingLayout from "./layouts/Landing";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Landing
import Landing from "./pages/landing/Landing";

// Pages

// Auth
import Page500 from "./pages/auth/Page500";
import Page404 from "./pages/auth/Page404";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";

// Protected routes
import ProtectedPage from "./pages/protected/ProtectedPage";
import ProtectedPage2 from "./pages/protected/ProtectedPage2";
import ProtectedPage4 from "./pages/protected/ProtectedPage4";
import ProtectedPage5 from "./pages/protected/ProtectedPage5";
import ProtectedPage6 from "./pages/protected/ProtectedPage6";
import ProtectedPage7 from "./pages/protected/ProtectedPage7";
import ProtectedPage9 from "./pages/protected/ProtectedPage9";
import ProtectedPage10 from "./pages/protected/ProtectedPage10";
import ProtectedPage12 from "./pages/protected/ProtectedPage12";
import ProtectedPage13 from "./pages/protected/ProtectedPage13";

import ProtectedPage16 from "./pages/protected/ProtectedPage16";
import ProtectedPage17 from "./pages/protected/ProtectedPage17";
import ProtectedPage18 from "./pages/protected/ProtectedPage18";
import ProtectedPage19 from "./pages/protected/ProtectedPage19";
import ProtectedPage20 from "./pages/protected/ProtectedPage20";
import ProtectedPage21 from "./pages/protected/ProtectedPage21";
import ProtectedPage22 from "./pages/protected/ProtectedPage22";

import ProtectedPage24 from "./pages/protected/ProtectedPage24";
import ProtectedPage28 from "./pages/protected/ProtectedPage28";
import ProtectedPage29 from "./pages/protected/ProtectedPage29";
import ProtectedPage30 from "./pages/protected/ProtectedPage30";
import ProtectedPage31 from "./pages/protected/ProtectedPage31";
import ProtectedPage32 from "./pages/protected/ProtectedPage32";
import ProtectedPage33 from "./pages/protected/ProtectedPage33";
import ProtectedPage34 from "./pages/protected/ProtectedPage34";
import ProtectedPage35 from "./pages/protected/ProtectedPage35";

import ProtectedPage37 from "./pages/protected/ProtectedPage37";
import ProtectedPage38 from "./pages/protected/ProtectedPage38";
import ProtectedPage39 from "./pages/protected/ProtectedPage39";
import ProtectedPage40 from "./pages/protected/ProtectedPage40";
import ProtectedPage23 from "./pages/protected/ProtectedPage23";
import ProtectedPage36 from "./pages/protected/ProtectedPage36";
import ProtectedPage15 from "./pages/protected/ProtectedPage15";
import ProtectedPage45 from "./pages/protected/ProtectedPage45";
import Docs from "./pages/protected/SMS/docs";


const routes = [
  {
    path: "/",
    element: <LandingLayout />,
    children: [
      {
        path: "",
        element: <Landing />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
      {
        path: "docs",
        element: <ProtectedPage45 />,
      },
    ],
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "homefs",
        element: <ProtectedPage12 />,
      },
      {
        path: "homefsedit",
        element: <ProtectedPage30 />,
      },
      {
        path: "homefstransfer",
        element: <ProtectedPage31 />,
      },
      {
        path: "homefsreturn",
        element: <ProtectedPage32 />,
      },
      {
        path: "history",
        element: <ProtectedPage37 />,
      },
      {
        path: "homefsos",
        element: <ProtectedPage33 />,
      },
      {
        path: "addfs",
        element: <ProtectedPage13 />,
      },
      {
        path: "addsample",
        element: <ProtectedPage15 />,
      },
      {
        path: "vectormap",
        element: <ProtectedPage34 />,
      },
    ],
  },
  {
    path: "pages",
    element: <DashboardLayout />,
    children: [
      {
        path: "employees",
        element: <ProtectedPage28 />,
      },
      {
        path: "editemployees/:id",
        element: <ProtectedPage29 />,
      },
      {
        path: "employeeprivate",
        element: <ProtectedPage39 />,
      },
      {
        path: "editemployeeprivate/:id",
        element: <ProtectedPage40 />,
      },
      {
        path: "samplecount",
        element: <ProtectedPage24 />,
      },
      {
        path: "settingsdroplist",
        element: <ProtectedPage35 />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: "private",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProtectedPage />,
      },
      {
        path: "update/:id",
        element: <ProtectedPage2 />,
      },
      {
        path: "transfer/:id",
        element: <ProtectedPage4 />,
      },
      {
        path: "transfer/acknowledge/:id",
        element: <ProtectedPage5 />,
      },
      {
        path: "return/:id",
        element: <ProtectedPage6 />,
      },
      {
        path: "return/racknowledge/:id",
        element: <ProtectedPage7 />,
      },
      {
        path: "outofservice/:id",
        element: <ProtectedPage9 />,
      },
      {
        path: "outofservice/acknowledge/:id",
        element: <ProtectedPage10 />,
      },
      // {
      //   path: "report",
      //   element: <ProtectedPage11 />,
      // },
      {
        path: "exportreportfs",
        element: <ProtectedPage23 />,
      },
      {
        path: "page",
        element: <ProtectedPage38 />,
      },
      {
        path: "reportfs",
        element: <ProtectedPage36 />,
      },
      {
        path: "editsample/:id",
        element: <ProtectedPage15 />,
      },
      {
        path: "transferfs/:id",
        element: <ProtectedPage16 />,
      },
      {
        path: "acknowledgefs/:id",
        element: <ProtectedPage17 />,
      },
      {
        path: "returnfs/:id",
        element: <ProtectedPage18 />,
      },
      {
        path: "racknowledgefs/:id",
        element: <ProtectedPage19 />,
      },
      {
        path: "outofservicefs/:id",
        element: <ProtectedPage20 />,
      },
      {
        path: "outofservicefs/osacknowledgefs/:id",
        element: <ProtectedPage21 />,
      },
      {
        path: "viewfs/:id",
        element: <ProtectedPage22 />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
