import React, { useEffect, useState, useMemo } from "react";
import { Col, Card, Row } from "react-bootstrap";
import { Smartphone } from "react-feather";
import { db } from "../../../config";
import { collection, getDocs, query, where } from "firebase/firestore";
import firebase from "firebase/compat";

const SampleCard = ({ title, count, color }) => (
  <Col md="6" xl className="d-flex">
    <Card className="flex-fill">
      <Card.Body className="py-4">
        <div className="d-flex align-items-start">
          <div className="flex-grow-1">
            <h3 className="mb-2">{count}</h3>
            <p className="mb-2">{title}</p>
          </div>
          <div className="d-inline-block ms-3">
            <div className="stat">
              <Smartphone color={color} className="align-middle text-success" />
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  </Col>
);

function StatisticsSMS() {
  const [stats, setStats] = useState({
    stotal: null,
    registered: null,
    intransit: null,
    inuse: null,
    inreturn: null,
    returned: null,
    inos: null,
    os: null,
  });

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user && isMounted) {
          const dataUsers = query(
            collection(db, "users"),
            where("uid", "==", user.uid)
          );
          const resultUsers = await getDocs(dataUsers);
          const loggedUser = resultUsers.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          const currentRole = loggedUser[0].role;
          const userEmail = loggedUser[0].email;

          let sampleQueries = [];

          switch (currentRole) {
            case "admin":
              sampleQueries.push(collection(db, "samples"));
              break;
            case "supervisor":
              const supervisedUsersResult = await getDocs(
                query(
                  collection(db, "users"),
                  where("supervisor", "==", userEmail)
                )
              );
              const supervisedUserEmails = supervisedUsersResult.docs.map(
                (doc) => doc.data().email
              );

              // console.log("Supervised User Emails:", supervisedUserEmails);

              sampleQueries = supervisedUserEmails.map((email) =>
                query(
                  collection(db, "samples"),
                  where("samplmanag", "==", email)
                )
              );

              if (!supervisedUserEmails.includes(userEmail)) {
                sampleQueries.push(
                  query(
                    collection(db, "samples"),
                    where("samplmanag", "==", userEmail)
                  )
                );
              }
              break;
            case "user":
              sampleQueries.push(
                query(
                  collection(db, "samples"),
                  where("samplmanag", "==", userEmail)
                )
              );
              break;
            default:
              break;
          }

          const getCount = async (status) => {
            const totalCountPromises = sampleQueries.map(
              async (sampleQuery) => {
                const result = await getDocs(
                  query(sampleQuery, where("status", "==", status))
                );
                return result.docs.length;
              }
            );
            const countResults = await Promise.all(totalCountPromises);
            return countResults.reduce((acc, val) => acc + val, 0);
          };

          const statusList = [
            "Registered",
            "In Transit",
            "In Use",
            "In Return",
            "Returned",
            "Request Lost",
            "Lost",
          ];

          const counts = await Promise.all(
            statusList.map((status) => getCount(status))
          );

          if (isMounted) {
            setStats({
              stotal: counts.reduce((acc, val) => acc + val, 0),
              registered: counts[0],
              intransit: counts[1],
              inuse: counts[2],
              inreturn: counts[3],
              returned: counts[4],
              inos: counts[5],
              os: counts[6],
            });
          }
        }
      });
    };

    fetchData();
    return () => {
      isMounted = false;
      setStats({
        stotal: null,
        registered: null,
        intransit: null,
        inuse: null,
        inreturn: null,
        returned: null,
        inos: null,
        os: null,
      });
    };
  }, []);

  return (
    <Row>
      <SampleCard
        title="Total Samples in SMS"
        count={stats.stotal}
        color="black"
      />
      <SampleCard
        title="Registered Samples"
        count={stats.registered}
        color="blue"
      />
      <SampleCard
        title="Samples In Transit"
        count={stats.intransit}
        color="purple"
      />
      <SampleCard title="Samples In Use" count={stats.inuse} color="orange" />
      <SampleCard
        title="Samples In Return"
        count={stats.inreturn}
        color="darkblue"
      />
      <SampleCard
        title="Samples Returned"
        count={stats.returned}
        color="green"
      />
      <SampleCard title="Request Lost" count={stats.inos} color="yellow" />
      <SampleCard title="Lost" count={stats.os} color="red" />
    </Row>
  );
}

export default StatisticsSMS;
