import React, { useState } from "react";
import { Alert } from "react-bootstrap";

import EmployeePrivate from "./SMS/Setting/EmployeePrivate";

function ProtectedPage() {
  const [show, setShow] = useState(true);

  return (
    <React.Fragment>
      {/*{!!show && (*/}
      {/*  <Alert*/}
      {/*    className="mb-3"*/}
      {/*    variant="primary"*/}
      {/*    dismissible*/}
      {/*    onClose={() => setShow(false)}*/}
      {/*  ></Alert>*/}
      {/*)}*/}
      <EmployeePrivate />
    </React.Fragment>
  );
}

export default ProtectedPage;
