import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../AddEdit.css";
import { serverTimestamp } from "firebase/firestore";
import { db, auth } from "../../../../contexts/FirebaseAuthContext";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

const initialState = {
  inReturnDate: "",
  inReturnTo: "",
};

const ReturnFS = () => {
  const { register, handleSubmit, reset, control } = useForm();
  const [supervisors, setSupervisors] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    db
      .collection("supervisorModel")
      .get()
      .then((querySnapshot) => {
        let supervisorArray = [];
        querySnapshot.forEach((doc) => {
          var supervisor = doc.data();
          // Assuming the structure of doc.data() is { email: "example@email.com" }
          supervisorArray.push({ label: supervisor.email, value: supervisor.email });
        });
        setSupervisors(supervisorArray);
      });
  }, []);


  useEffect(() => {
    db
      .collection("samples")
      .doc(id)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          reset(snapshot.data());
        } else {
          reset({});
        }
      });

    return () => {
      reset({});
    };
  }, [id, reset]);

  const onFormSubmit = (data) => {
    const uid = auth.currentUser.uid;
    const email = auth.currentUser.email;

    db.collection("users")
      .where("email", "==", data.inReturnTo.value)  // Extract 'value' from the 'inReturnTo' object
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const user = doc.data();

          if (user.userLat === undefined || user.userLng === undefined) {
            alert("Error: Latitude or longitude is undefined for user " + data.inReturnTo.value);
            return;  // Exit the function early
          }

          const updateData = {
            ...data,
            inReturnDate: serverTimestamp(),
            inReturnSuper: user.supervisor,
            inReturnTo: data.inReturnTo.value,  // Extract 'value' from the 'inReturnTo' object
            status: "In Return",
            country: user.country,
            city: user.city,
            userLat: user.userLat,
            userLng: user.userLng,
          };

          db.collection(`samples`)
            .doc(id)
            .update(updateData)
            .then(() => {
              alert("Sample In Return Successfully")

              db.collection("history")
                .doc()
                .set({
                  email,
                  uid,
                  actiondate: serverTimestamp(),
                  action: "Return",
                  ...updateData,
                })
                .then(() => {
                  setTimeout(() => navigate("/dashboard/homefsreturn"), 500);
                });
            })
            .catch((error) => {
              console.error(error.code);
              console.error(error.message);
              alert("Sample Updated Error: " + error);
            });
        });
      });
  };


  return (
    <div style={{ marginTop: "100px" }}>
      <form
        style={{
          margin: "auto",
          padding: "15px",
          maxWidth: "400px",
          alignContent: "center",
        }}
      onSubmit={handleSubmit(onFormSubmit)}>
      <label>Return Sample to:</label>
        <Controller
          control={control}
          name="inReturnTo"
          render={({ field }) => (
            <Select
              {...field}
              options={supervisors}
              onChange={option => field.onChange(option)}
              isClearable
            />
          )}
        />
        <input type="submit" value="Return" />
      </form>
    </div>
  );
};

export default ReturnFS;
