import { db } from "../../../../contexts/FirebaseAuthContext";
import { collection, getDocs, query, where } from "firebase/firestore";

const fetchData = async (role, currentUser) => {
  // console.log("Fetching data for role:", role);
  // console.log("Current user:", currentUser);
  switch (role) {
    case "admin":
      // Fetch all documents for admin
      const adminSnapshot = await db.collection("samples").get();
      return adminSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

    case "supervisor":
      const supervisedUsersResult = await getDocs(
        query(
          collection(db, "users"),
          where("supervisor", "==", currentUser)
        )
      );
      const supervisedUserEmails = supervisedUsersResult.docs.map(
        (doc) => doc.data().email
      );

      let supervisorSamples = [];
      for (const email of supervisedUserEmails) {
        const userSamplesSnapshot = await getDocs(
          query(
            collection(db, "samples"),
            where("samplmanag", "==", email)
          )
        );
        const userSamples = userSamplesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        supervisorSamples.push(...userSamples);
      }

      const ownSamplesSnapshot = await getDocs(
        query(
          collection(db, "samples"),
          where("samplmanag", "==", currentUser)
        )
      );
      const ownSamples = ownSamplesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      supervisorSamples.push(...ownSamples);

      const inTransitToSnapshot = await getDocs(
        query(
          collection(db, "samples"),
          where("inTransitTo", "==", currentUser)
        )
      );
      const inTransitToSamples = inTransitToSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      supervisorSamples.push(...inTransitToSamples);

      // Merge the results of the three queries
      const combinedSupervisorSamples = [
        ...supervisorSamples,
      ];

      // Remove duplicates from the result array (if any)
      const uniqueSupervisorSamples = Array.from(new Set(combinedSupervisorSamples.map(a => a.id)))
        .map(id => combinedSupervisorSamples.find(a => a.id === id));

      return uniqueSupervisorSamples;


    case "user":
      // Fetch documents based on user-specific conditions

      // Fetch documents where 'samplmanag' is equal to currentUser
      const samplmanagSnapshot = await db
        .collection("samples")
        .where("samplmanag", "==", currentUser)
        .get();

      // Fetch documents where 'inTransitTo' is equal to currentUser
      const userInTransitToSnapshot = await db
        .collection("samples")
        .where("inTransitTo", "==", currentUser)
        .get();

      // Merge the results of the two queries
      const results = [
        ...samplmanagSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
        ...userInTransitToSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
      ];

      // Remove duplicates from the result array (if any)
      const uniqueResults = Array.from(new Set(results.map(a => a.id)))
        .map(id => results.find(a => a.id === id));

      return uniqueResults;

    default:
      return [];
  }
};

export default fetchData;

