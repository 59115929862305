import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { auth, db } from "../../../../contexts/FirebaseAuthContext";
import { onAuthStateChanged } from "firebase/auth";
import { serverTimestamp } from "firebase/firestore";
import { useForm } from "react-hook-form";

const TransferAck = () => {
  const { register, handleSubmit, setValue } = useForm();
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchSample = async () => {
      const docRef = db.collection("samples").doc(id);
      const doc = await docRef.get();

      if (doc.exists) {
        setData(doc.data());
      } else {
        setData({});
      }
    };

    onAuthStateChanged(auth, (user) => {
      if (user) {
        setValue("samplmanag", auth.currentUser.email);
      }
    });

    fetchSample();
  }, [id, setValue]);

  const getUserDataByEmail = async (email) => {
    const querySnapshot = await db.collection("users").where("email", "==", email).get();
    const userDoc = querySnapshot.docs[0];
    return userDoc ? userDoc.data() : null;
  };

  const onFormSubmit = async (data) => {
    const { samplmanag } = data;
    const uid = auth.currentUser.uid;
    const email = auth.currentUser.email;

    console.log("onFormSubmit data: ", data); // Log the data received by onFormSubmit

    try {
      const docRef = db.collection("samples").doc(id);
      const doc = await docRef.get();

      if (!doc.exists) {
        throw new Error('No such document!');
      }

      const currentSample = doc.data();

      console.log("currentSample: ", currentSample); // Log the current sample data

      const userData = await getUserDataByEmail(samplmanag);

      if (!userData || !userData.supervisor) {
        throw new Error('No user data or supervisor found for this email.');
      }

      const updatedData = {
        samplmanag: samplmanag,
        samplmanagname: userData.displayName,
        inUseDate: serverTimestamp(),
        lastmanag: currentSample.samplmanag,
        inTransitTo: "",
        status: "In Use",
        supervisor: userData.supervisor,
        city: userData.city,
        country: userData.country,
        userLat: userData.userLat,
        userLng: userData.userLng,
        userLocation: userData.userLocation,
      };

      console.log("updatedData: ", updatedData); // Log the data to be updated

      await db.collection("samples").doc(id).update(updatedData);

      const historyData = {
        email,
        uid,
        actiondate: serverTimestamp(),
        action: "Transfer Acknowledge",
        ...data,
        customers: currentSample.customers,
        brand: currentSample.brand,
        model: currentSample.model,
        serialNumber: currentSample.serialNumber,
        imei1: currentSample.imei1,
        imei2: currentSample.imei2,
        ...updatedData,
      };


      console.log("historyData: ", historyData); // Log the data to be written to history

      await db.collection("history").doc().set(historyData);

      alert("Sample Transferred Successfully");
      setTimeout(() => navigate("/dashboard/homefstransfer"), 500);
    } catch (error) {
      console.error('An error occurred: ', error);
      alert('An error occurred: ' + error.message);
    }
  };



  return (
    <div style={{ marginTop: "100px" }}>
      <form
        style={{
          margin: "auto",
          padding: "15px",
          maxWidth: "400px",
          alignContent: "center",
        }}
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <label>Sample Manager:</label>
        <input
          {...register("samplmanag")}
          type="text"
          id="samplmanag"
          name="samplmanag"
          placeholder="Sample Manager..."
          readOnly
        />
        <input type="submit" value={data.id ? "Receive Sample" : "Save"} />
      </form>
    </div>
  );
};

export default TransferAck;



