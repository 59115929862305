import React, { useState } from "react";
import Select from "react-select";
import Modals from "./Modal";
import { Col, Form, Button } from "react-bootstrap";
import DropSelector from "./useDropbox";
function Hardware(props) {
  return (
    <Form.Group
      as={Col}
      md={props.isedit ? "3" : ""}
      controlId="validationFormik04"
    >
      <Form.Label>Hardware Version</Form.Label>
      <DropSelector
        placeholder="Hardware Version"
        name="hardware"
        id="hardware"
        title="Hardware Version"
        DBModel="hardwareVersion"
        isedit={props.isedit}
        value={props.value}
        valueChange={props.valueChange}
      />
    </Form.Group>
  );
}
export default Hardware;
